import { formatCurrencyZeroRound } from "../../../helpers/helpers";

const doughNutChartOption = {
  tooltip: {
    trigger: "item",
    position: "top",
    formatter: (params) => {
      const { value, name } = params;
      return `${name} : ${formatCurrencyZeroRound(value)}`;
    },
  },
  series: [
    {
      name: "Access From",
      type: "pie",
      radius: ["40%", "60%"],
      avoidLabelOverlap: true,
      label: {
        show: true,
        formatter: (params) => {
          const { name, value } = params;
          return `${name} \n\n ${formatCurrencyZeroRound(value)}`;
        },
        fontSize: 12,
      },
      labelLine: {
        show: true,
        length: 15,
        smooth: 0.2,
      },
      data: [],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export { doughNutChartOption };
