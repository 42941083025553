/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import {
  HiOutlineUsers,
  HiOutlineTag,
  HiOutlineUserGroup,
  HiOutlineBellAlert,
  HiOutlineUserPlus,
  HiOutlineLightBulb,
  HiOutlineHandThumbUp,
} from "react-icons/hi2";
import { RiDashboardLine, RiExchangeDollarFill } from "react-icons/ri";
import {
  BsTags,
  BsSpeedometer2,
  BsFillCheckCircleFill,
  BsInfoCircleFill,
} from "react-icons/bs";
import { FaSearchDollar } from "react-icons/fa";
import { TbAppWindow } from "react-icons/tb";
import { MdWarning, MdOutlineManageAccounts } from "react-icons/md";
import { ImTree } from "react-icons/im";
import { VscError } from "react-icons/vsc";
import { SiDatabricks } from "react-icons/si";
import { GiSpeedometer } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import {
  AiOutlineCloudServer,
  AiOutlineAppstore,
  AiOutlineAudit,
} from "react-icons/ai";
import { TbReportAnalytics } from "react-icons/tb";
import _ from "lodash";
// import '../assets/css/layout/layout.css';
import { TbTags, TbTableOptions, TbTrendingUp } from "react-icons/tb";
import { RxLayers, RxDisc } from "react-icons/rx";
import { IoBarChartOutline } from "react-icons/io5";
import { IoMdAnalytics } from "react-icons/io";
import TATHeader from "./TATHeader";
import TATSideBar from "./TATSideBar";
import FinopsHealth from "./FinopsHealth";
import CustomReports from "./CustomReports";
import CustomReports2 from "./CustomReports2";
import TagAuditTable from "./TagGovComponents/tagAuditTable";
import AlertsList from "./AlertsComponents/AlertsList";
import AdminCenter from "./AdminCenterComponents/AdminCenter";
import Forecast from "./ForecastComponents/forecast";
import PreviewUnavailableBYO from "./PreviewUnavailableBYO";
import PreviewUnavailablePaas from "./previewUnavailablePaas";
import PreviewUnavailableRoi from "./previewUnavailableRoi";
import AnomalyDetection from "./AnomalyDetection/AnomalyDetection";
const usage = [
  { label: "CXO Review", minHeight: "h-[130px]" },
  { label: "FinOps Central", minHeight: "h-[325px]" },
  { label: "Suggestion Engine", minHeight: "h-[130px]" },
  { label: "IAM", minHeight: "h-[65px]" },
];

const renderNavIcon = (key, isAdditional) => {
  switch (key) {
    case "Dashboard":
      return (
        <RiDashboardLine className="h-10 text-2xl nav_icon transition-colors duration-300" />
      );
    case "FinOps Health":
      return (
        <TbTableOptions className="h-5 text-2xl nav_icon transition-colors duration-300" />
      );
    case "cost-analysis":
      return (
        <AiOutlineCloudServer className="h-5 text-2xl nav_icon transition-colors duration-300 cursor-pointer" />
      );
    case "Voya Solutions":
      return (
        <TbReportAnalytics className="h-15 text-2xl nav_icon transition-colors duration-300" />
      );
    case "by Resource Group":
      return (
        <ImTree className="h-8 w-9 pl-4 text-xl nav_icon transition-colors duration-300" />
      );
    case "by Meter Category":
      return (
        <BsSpeedometer2 className="h-8 w-10 pl-4  text-xl nav_icon transition-colors duration-300" />
      );
    case "Meter Growth":
      return (
        <GiSpeedometer className="h-8 w-10 pl-4 text-xl  transition-colors duration-300" />
      );
    case "Tag Governance":
      return (
        <BsTags className="h-5 text-2xl nav_icon transition-colors duration-300 cursor-pointer" />
      );

    case "Monthly Tag Maturity":
      return (
        <HiOutlineTag className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Individual Tags":
      return (
        <TbTags className="h-8 w-12 pl-4  text-2xl nav_icon transition-colors duration-300 " />
      );
    case "Users":
      return (
        <HiOutlineUserPlus className="h-8 w-10 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Admin Center":
      return (
        <HiOutlineUsers className="h-5 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Custom Reports":
      return (
        <RxLayers className="h-5 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Anomaly Insights":
      return (
        <RxDisc className="h-8 w-10 pl-4 text-xl  transition-colors duration-300" />
      );
    case "Alerts":
      return (
        <HiOutlineBellAlert className="h-5 text-2xl nav_icon transition-colors duration-300 cursor-pointer" />
      );
    case "Build Your Own":
      return (
        <TbAppWindow className="h-5 text-xl  transition-colors duration-300" />
      );
    case "build-your-own":
      return (
        <AiOutlineAppstore className="h-5 text-2xl nav_icon transition-colors duration-300 cursor-pointer" />
      );
    case "admin-center":
      return (
        <HiOutlineUsers className="h-5 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Groups":
      return (
        <HiOutlineUserGroup className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Grouped Applications":
      return (
        <TbAppWindow className="h-8 w-10 pl-4 text-xl  transition-colors duration-300" />
      );
    case "Module Access":
      return (
        <MdOutlineManageAccounts className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case "PaaS Analytics":
      return (
        <IoMdAnalytics className="h-5 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Azure Databricks":
      return (
        <SiDatabricks className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Forecast Model":
      return (
        <IoBarChartOutline className="h-5 text-2xl nav_icon transition-colors duration-300" />
      );
    case "suggestion-engine":
      return (
        <HiOutlineLightBulb className="h-5 text-2xl nav_icon transition-colors duration-300 " />
      );
    case "Anomaly Detection":
      return (
        <FaSearchDollar className="h-5 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Recommendations":
      return (
        <HiOutlineHandThumbUp className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Tag Overview":
      return (
        <AiOutlineAudit className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case "Trend Analysis":
      return (
        <TbTrendingUp className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case "ROI Review":
      return (
        <RiExchangeDollarFill className="h-5 text-2xl nav_icon transition-colors duration-300" />
      );
    default:
      return (
        <RiDashboardLine
          className={
            isAdditional
              ? "h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300"
              : "h-10 text-2xl nav_icon transition-colors duration-300"
          }
        />
      );
  }
};

function TATLayout({ children }) {
  const [isMobileView, setIsMobileView] = useState(false);
  const [panelIndex, setPanelIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const permission = [
    {
      category: "finops-health",
      link: "finops-health",
      label: "FinOps Health",
      permissions: null,
    },
    {
      category: "roi",
      link: "roi",
      label: "ROI Review",
      permissions: null,
    },
    {
      category: "cost-analysis",
      link: "cost-analysis/reports",
      label: "Custom Reports",
      permissions: {
        create: 1,
        read: 1,
        update: 1,
        delete: 1,
        "share-reports": 1,
        "shedule-reports": 1,
      },
    },

    {
      category: "Tag Governance",
      link: "Tag Governance/tag-key-auditor",
      label: "Tag Key Auditor",
      permissions: null,
    },
    {
      category: "Tag Governance",
      link: "Tag Governance/monthly-tag-maturity",
      label: "Monthly Tag Maturity",
      permissions: null,
    },
    {
      category: "alerts",
      link: "alerts",
      label: "Alerts",
      permissions: {
        create: 1,
        read: 1,
        update: 1,
        delete: 1,
        "alert-test": 1,
      },
    },
    {
      category: "build-your-own",
      link: "build-your-own",
      label: "Build Your Own",
      permissions: {
        create: 1,
        read: 1,
        update: 1,
        delete: 1,
        "download-app": 1,
      },
    },
    {
      category: "PaaS Analytics",
      link: "PaaS Analytics/azure-databricks",
      label: "Azure Databricks",
      permissions: null,
    },
    {
      category: "PaaS Analytics",
      link: "PaaS Analytics/aro",
      label: "ARO",
      permissions: null,
    },
    {
      category: "forecast-model",
      link: "forecast-model",
      label: "Forecast Model",
      permissions: null,
    },
    {
      category: "anomaly-detection",
      link: "anomaly-detection",
      label: "Anomaly Detection",
      permissions: null,
    },
    {
      category: "admin-center",
      link: "admin-center",
      label: "Admin Center",
      permissions: {
        create: 1,
        read: 1,
        update: 1,
        delete: 1,
        edit: 1,
      },
      moduleAccess: {
        read: 1,
        update: 1,
      },
      groups: {
        create: 1,
        read: 1,
        edit: 1,
        delete: 1,
      },
    },
  ];
  const handleHamburgerClick = () => {
    setIsMobileView(!isMobileView);
  };

  const getNavigator = () => {
    let navigator = [];
    if (permission && Array.isArray(permission)) {
      const groupData = _.groupBy(permission, "category");
      for (const category in groupData) {
        if (Object.hasOwnProperty.call(groupData, category)) {
          const groupedNav = groupData[category];
          if (groupedNav.length > 0) {
            const isExpand = groupedNav.length > 1;
            const nav = {
              id: Math.random(),
            };
            if (isExpand) {
              nav.label = category;
              nav.expandMode = true;
              nav.title = category;
              nav.icon = renderNavIcon(category);
              nav.additionalRoute = [];
              for (const current_nav of groupedNav) {
                if (
                  !current_nav.permissions ||
                  (current_nav.permissions &&
                    current_nav.permissions &&
                    Boolean(current_nav.permissions.read))
                ) {
                  nav.additionalRoute.push({
                    label: current_nav.label,
                    icon: renderNavIcon(current_nav.label, true),
                    id: 1,
                  });
                }
              }
            } else {
              const current_nav = groupedNav[0];
              if (
                !current_nav.permissions ||
                (current_nav.permissions &&
                  current_nav.permissions &&
                  Boolean(current_nav.permissions.read))
              ) {
                nav.label = current_nav.label;
                nav.icon = renderNavIcon(current_nav.label);
              }
            }
            navigator.push(nav);
          }
        }
      }
    }
    return navigator;
  };

  const getSelectedPanel = (selectedPath) => {
    if (selectedPath) setPanel(selectedPath);

    const selectedPanel = selectedPath;
    const isCXO =
      selectedPanel === "FinOps Health" || selectedPanel === "ROI Review";
    const isCentral =
      selectedPanel === "Custom Reports" ||
      selectedPanel === "Tag Key Auditor" ||
      selectedPanel === "Voya Solutions" ||
      selectedPanel === "Monthly Tag Maturity" ||
      selectedPanel === "Alerts" ||
      selectedPanel === "Build Your Own" ||
      selectedPanel === "Azure Databricks";
    const isSuggEngine =
      selectedPanel === "Forecast Model" ||
      selectedPanel === "Anomaly Detection";
    const isIAM = selectedPanel === "Admin Center";
    if (isCXO) {
      setPanelIndex(0);
    } else if (isCentral) {
      setPanelIndex(1);
    } else if (isSuggEngine) {
      setPanelIndex(2);
    } else if (isIAM) {
      setPanelIndex(3);
    }
  };

  const handleHoveredPanel = (label) => {
    const isCxoHovered = label === "FinOps Health" || label === "ROI Review";
    const isCentralHovered =
      label === "Custom Reports" ||
      label === "Voya Solutions" ||
      label === "tag governance" ||
      label === "Tag Key Auditor" ||
      label === "Monthly Tag Maturity" ||
      label === "Alerts" ||
      label === "Build Your Own" ||
      label === "PaaS Analytics" ||
      label === "Azure Databricks";
    const isSuggEngineHovered =
      label === "Forecast Model" || label === "Anomaly Detection";
    const isIamHovered = label === "Admin Center";
    if (isCxoHovered) {
      setHoveredIndex(0);
    } else if (isCentralHovered) {
      setHoveredIndex(1);
    } else if (isSuggEngineHovered) {
      setHoveredIndex(2);
    } else if (isIamHovered) {
      setHoveredIndex(3);
    } else {
      setHoveredIndex(null);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 765);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigator_data = getNavigator();

  const [panel, setPanel] = useState("FinOps Health");

  return (
    <main className="layout_container">
      <TATHeader handleHamburgerClick={handleHamburgerClick} />
      <section className="flex relative h-[100%] ">
        <div className="flex flex-row absolute h-[100%]">
          <div className="w-[20px] h-full text-center">
            {usage &&
              usage.map((item, usageIndex) => {
                const isPanelSelected = usageIndex === panelIndex;
                const isDivider = usageIndex !== usage.length - 1;
                const isPanelHovered = usageIndex === hoveredIndex;
                const isActive = isPanelSelected || isPanelHovered;
                return (
                  <div
                    key={`${item.label} - ${usageIndex}`}
                    className={`${item.minHeight} ${
                      isActive
                        ? isPanelSelected
                          ? "bg-[#297cc5]"
                          : "bg-[#C3DCF3]"
                        : "bg-[#f2f2f2]"
                    } flex items-center justify-center ${
                      isDivider && "border-gray-400 border-b-2 border-dashed"
                    }`}
                  >
                    <label
                      className={`[writing-mode:vertical-rl] rotate-180 ${
                        isPanelSelected ? "text-white" : "text-[#4A5462]"
                      } text-xs tracking-[1px] font-semibold ${
                        isPanelHovered ? "text-[#4A5462]" : ""
                      }`}
                    >
                      {item.label}
                    </label>
                  </div>
                );
              })}
          </div>
          <TATSideBar
            sideNavs={navigator_data}
            isMobileView={isMobileView}
            getSelectedPanel={getSelectedPanel}
            handleHoveredPanel={handleHoveredPanel}
            activePanel={panel}
          />
        </div>

        <section className="w-full layout_content">
          {panel === "FinOps Health" && (
            <section className="p-4 overflow-auto h-calc-layout">
              <FinopsHealth />
            </section>
          )}
          {panel === "Custom Reports" && (
            <section className="p-4 overflow-auto h-calc-layout">
              <CustomReports2 />
            </section>
          )}
          {panel === "Tag Governance" && (
            <section className="p-4 overflow-auto h-calc-layout">
              <TagAuditTable />
            </section>
          )}
          {panel === "Alerts" && (
            <section className="p-4 overflow-auto h-calc-layout">
              <AlertsList />
            </section>
          )}
          {panel === "Admin Center" && (
            <section className="p-4 overflow-auto h-calc-layout">
              <AdminCenter />
            </section>
          )}{" "}
          {panel === "Forecast Model" && (
            <section className="p-4 overflow-auto h-calc-layout">
              <Forecast />
            </section>
          )}
          {panel === "Build Your Own" && (
            <section className="p-4 overflow-auto h-calc-layout">
              <PreviewUnavailableBYO />
            </section>
          )}
          {panel === "PaaS Analytics" && (
            <section className="p-4 overflow-auto h-calc-layout">
              <PreviewUnavailablePaas />
            </section>
          )}
          {panel === "ROI Review" && (
            <section className="p-4 overflow-auto h-calc-layout">
              <PreviewUnavailableRoi />
            </section>
          )}
          {panel === "Anomaly Detection" && (
            <section className="p-4 overflow-auto h-calc-layout">
              <AnomalyDetection />
            </section>
          )}
        </section>
      </section>
    </main>
  );
}

export default TATLayout;
