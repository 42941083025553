/* eslint no-console:"off" */
/* eslint no-alert:"off" */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineSearch, HiOutlineMenu } from "react-icons/hi";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { IoMdClose } from "react-icons/io";
import { OverlayPanel } from "primereact/overlaypanel";
import "../../Components/Take_A_Tour/Common/cubeTable.css";
import { ProgressSpinner } from "primereact/progressspinner";
import { HiOutlineBell } from "react-icons/hi2";
import { TbActivityHeartbeat } from "react-icons/tb";
import { CgOrganisation } from "react-icons/cg";
import logoLens from "../../assets/icons/logoLens-Tour.svg";
import AWSLogo from "../../assets/icons/AWSlogo.svg";
import AzureLogo from "../../assets/icons/AzureLogo.svg";
import { Tooltip } from "primereact/tooltip";

function TATHeader({ handleHamburgerClick }) {
  const timeoutRef = useRef(null);
  const alertOverLayRef = useRef(null);
  const healthOverLayRef = useRef(null);
  const logoutOverLayRef = useRef(null);
  const dbSwitchOverRef = useRef(null);
  const avatarReference = useRef(null);
  const iconReference = useRef(null);
  const bellIconReference = useRef(null);
  const dbReference = useRef(null);
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const secondInputRef = useRef(null);
  const [showNoResults, setShowNoResults] = useState(false);
  const [showFunctionalSearch, setShowFunctionalSearch] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [alertsData, setAlertsData] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showRecentlyViewed, setShowRecentlyViewed] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState();
  const [searchHistory, setSearchHistory] = useState([]);
  const [cacheLoading, setcacheLoading] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);

  const closeNotification = (alert) => {
    const updatedAlerts = alertsData.filter((item) => item.id !== alert.id);

    setAlertsData(updatedAlerts);
  };

  const handleDivClick = () => {
    if (searchTerm?.length === 0) {
      setShowRecentlyViewed(true);
      setIsExpanded(true);
      setSuggestions([]);
    }
  };
  const handleHamburgegAction = () => {
    setIsMenuOpen(!isMenuOpen);
    handleHamburgerClick();
  };

  const handleMobileClick = () => {
    setShowSearchInput(!showSearchInput);
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    setShowRecentlyViewed(false);
    if (inputValue.trim() === "") {
      setSuggestions([]);
    }
  };

  const handleSelect = (selectedValue) => {
    let selectedItem;
    if (searchHistory && searchTerm?.length === 0) {
      const filteredArray = [
        ...(searchHistory?.recently_viewed?.filter(
          (item) => item?.label === selectedValue
        ) || []),
        ...(searchHistory?.recent_searches?.filter(
          (item) => item?.label === selectedValue
        ) || []),
      ];
      selectedItem = filteredArray.find(
        (item) => item?.label === selectedValue
      );
      navigate(`/${selectedItem?.link}`);
    }

    if (selectedItem) {
      setSearchTerm("");
      setShowFunctionalSearch(false);
      setShowRecentlyViewed(false);
    }
  };
  const handleOutsideClick = (event) => {
    const isClickedInsideInput =
      secondInputRef.current && secondInputRef.current.contains(event.target);
    const isClickedInsideRecentlySearched =
      secondInputRef.current &&
      secondInputRef.current.nextSibling &&
      secondInputRef.current.nextSibling.contains(event.target);

    if (isClickedInsideInput || isClickedInsideRecentlySearched) {
      return;
    }

    setShowFunctionalSearch(false);
    setSearchTerm("");
    setSuggestions([]);
    setShowRecentlyViewed(false);
    setIsExpanded(false);
  };

  useEffect(() => {
    if (showFunctionalSearch && secondInputRef.current) {
      secondInputRef.current.focus();
    }
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showFunctionalSearch]);

  const handleLogout = () => {};

  const handleDbSwitch = (item) => {};

  const fetchUserDetail = () => {};
  const toggleHealthPopup = (e) => {
    healthOverLayRef.current.toggle(e);
  };

  const toggleAlertPopup = (e) => {
    alertOverLayRef.current.toggle(e);
  };

  const handleFetchHealth = () => {};

  const handleDbFetch = () => {};

  const searchMobile = window.innerWidth <= 767;
  useEffect(() => {
    setShowSearchInput(searchMobile);
  }, [searchMobile]);

  useEffect(() => {
    let timeoutId;
    if (
      searchTerm &&
      (!suggestions || (suggestions && suggestions.length === 0))
    ) {
      timeoutId = setTimeout(() => {
        setShowNoResults(true);
      }, 400);
    } else {
      setShowNoResults(false);
    }

    return () => clearTimeout(timeoutId);
  }, [searchTerm, suggestions]);

  useEffect(() => {
    if (searchTerm?.length === 0 && isExpanded) {
      setShowRecentlyViewed(true);
    }
  }, [searchTerm]);

  useEffect(() => {
    const handleOutsideOverLayClick = (e) => {
      const popoverElement = logoutOverLayRef.current.getElement();
      if (
        popoverElement &&
        !popoverElement.contains(e.target) &&
        !avatarReference.current.contains(e.target)
      ) {
        logoutOverLayRef.current.hide();
      }
    };

    document.addEventListener("mousedown", handleOutsideOverLayClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideOverLayClick);
    };
  }, [logoutOverLayRef, avatarReference]);

  useEffect(() => {
    const handleHealthOutsideOverLayClick = (e) => {
      const popoverElement = healthOverLayRef.current.getElement();
      if (
        popoverElement &&
        !popoverElement.contains(e.target) &&
        !iconReference.current.contains(e.target)
      ) {
        healthOverLayRef.current.hide();
      }
    };

    const notificationOutsideClick = (e) => {
      const popoverElement = alertOverLayRef.current.getElement();
      if (
        popoverElement &&
        !popoverElement.contains(e.target) &&
        !bellIconReference.current.contains(e.target)
      ) {
        alertOverLayRef.current.hide();
      }
    };
    const dbSwitchOutsideClick = (e) => {
      const popoverElement = dbSwitchOverRef.current?.getElement();
      if (
        popoverElement &&
        !popoverElement.contains(e.target) &&
        !dbReference.current.contains(e.target)
      ) {
        dbSwitchOverRef.current.hide();
      }
    };

    document.addEventListener("mousedown", handleHealthOutsideOverLayClick);
    document.addEventListener("mousedown", notificationOutsideClick);
    document.addEventListener("mousedown", dbSwitchOutsideClick);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleHealthOutsideOverLayClick
      );
      document.removeEventListener("mousedown", notificationOutsideClick);
      document.removeEventListener("mousedown", dbSwitchOutsideClick);
    };
  }, [
    healthOverLayRef,
    iconReference,
    alertOverLayRef,
    bellIconReference,
    dbSwitchOverRef,
  ]);

  const onClearCache = async () => {};

  let profileLabel = "JD";

  const divClassName = isExpanded
    ? "relative flex !w-[500px]   flex-wrap pr-2 bg-white rounded-md transition-all"
    : "relative flex mt-2 sm:mt-0 !w-[300px] !h-[35px] sm:!w-50 flex-wrap  bg-white rounded-md transition-all unavailable";

  return (
    <header className="flex shadow z-10 bg-[#F9F9Fb] sticky top-0 py-0 flex-col md:flex-row">
      <div className="!w-full flex justify-between items-center pl-2 py-1 pr-2  ">
        <div
          className="hamburger-menu cursor-pointer md:hidden "
          aria-hidden="true"
          onClick={handleHamburgegAction}
        >
          {isMenuOpen ? (
            <IoMdClose className="h-6 w-6 transition-all duration-300 transform text-gray-500" />
          ) : (
            <HiOutlineMenu className="h-6 w-6 transition-all duration-300 text-gray-500" />
          )}
        </div>
        <div className="w-2/5 flex item-center md:w-3/12">
          <img src={logoLens} alt="logo" className="logo w-40" />
        </div>
        <div className="navbar-content flex ml-auto">
          <div className="functional-search flex  ">
            <div
              aria-hidden="true"
              className="flex items-center justify-center h-full px-2 cursor-pointer md:hidden"
            >
              <HiOutlineSearch className="h-6 w-6 text-gray-500" />
            </div>
            <div className="functional-search hidden  md:flex items-center ">
              <div className={divClassName} aria-hidden ref={secondInputRef}>
                <span className="z-8 h-full  leading-snug font-normal text-center text-slate-300 absolute bg-transparent rounded text-base w-10 right-2 flex justify-center items-center py-3">
                  {false ? (
                    <ProgressSpinner
                      strokeWidth={4}
                      style={{ width: "20px", height: "20px" }}
                      color="#3b82f6"
                    />
                  ) : (
                    <HiOutlineSearch className="h-4 w-4 text-gray-500 " />
                  )}
                </span>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleInputChange}
                  disabled
                  placeholder="Search"
                  className={` px-3 w-full flex justify-between items-center border rounded-md py-0 text-gray-900 outline-none unavailable bg-white`}
                />
              </div>
              <div className="w-[4rem] flex items-center justify-center mx-2 unavailable">
                <img src={AzureLogo} alt="logo" />
              </div>

              {/* <FunctionalSearch
                searchHistory={searchHistory}
                searchTerm={searchTerm}
                suggestions={suggestions}
                isLoading={isLoading || loading}
                showRecentlyViewed={showRecentlyViewed}
                showNoResults={showNoResults}
                handleSelect={handleSelect}
              /> */}
            </div>
            <div
              aria-hidden="true"
              ref={iconReference}
              className="flex items-center p-2 hover:bg-gray-200 unavailable text-gray-400 hover:text-secondary transition-colors duration-300 cursor-pointer rounded-md"
            >
              <TbActivityHeartbeat className="h-7 w-7" />
            </div>
            <OverlayPanel ref={healthOverLayRef} onShow={handleFetchHealth}>
              <div className="w-[330px]">
                {/* <HealthCheck
                  healthCheckData={healthCheckData}
                  isError={healthCheckError}
                  isLoading={healthLoader}
                /> */}
              </div>
            </OverlayPanel>
          </div>
          <div className="other-contents flex">
            <div
              aria-hidden="true"
              ref={bellIconReference}
              className="flex items-center pl-2 hover:bg-gray-200 text-gray-400 hover:text-secondary transition-colors duration-300 cursor-pointer rounded-md"
            >
              <div className=" pr-2 relative  md:flex unavailable">
                <HiOutlineBell className="h-4 w-4 md:h-6 md:w-6" />
              </div>
            </div>
            <OverlayPanel ref={alertOverLayRef}>
              <div className="w-[360px]"></div>
            </OverlayPanel>

            <div
              className="pl-2 cursor-pointer flex items-center hidden"
              aria-hidden="true"
              onClick={(e) => dbSwitchOverRef.current.toggle(e)}
            >
              <CgOrganisation
                className="h-6 w-6 md:h-8 md:w-8 text-gray-400"
                onClick={handleDbFetch}
              />
            </div>
            <OverlayPanel ref={logoutOverLayRef}>
              <div className="w-[234px]">
                <Tooltip
                  target=".unavailable"
                  position="top"
                  content="Preview Unavailable - request demo"
                />
                <div className="p-4 border-b">
                  <p className="font-medium text-gray-500">Profile</p>
                  <div className="w-full flex items-center my-2">
                    <div className="w-1/4">
                      <Avatar
                        label={"JD"}
                        style={{ backgroundColor: "#297cc5", color: "#ffffff" }}
                      />
                    </div>
                    <div className="w-3/4">
                      <p className="text-sm"> John Doe</p>
                      <p className="text-xs w-full text-gray-400 truncate ..."></p>
                    </div>
                  </div>
                </div>
                <div className="pt-4 border-b">
                  <p className="py-3 px-4 hover:bg-gray-200 cursor-pointer unavailable">
                    Personal Settings
                  </p>
                  <p
                    className="py-3 px-4 hover:bg-gray-200 cursor-pointer unavailable"
                    aria-hidden="true"
                    disabled={cacheLoading}
                    onClick={onClearCache}
                  >
                    Clear Cache
                  </p>
                  <p
                    className="py-3 px-4  hover:bg-gray-200 cursor-pointer unavailable"
                    aria-hidden="true"
                    onClick={handleLogout}
                  >
                    Log out
                  </p>
                </div>
              </div>
            </OverlayPanel>
            <div
              className="pl-2 cursor-pointer flex items-center"
              aria-hidden="true"
              ref={avatarReference}
              onClick={(e) => logoutOverLayRef.current.toggle(e)}
            >
              <div
                className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-md"
                style={{
                  border: "1px solid #0F6CBD",
                  color: "white",
                  backgroundColor: "#297cc5",
                  fontWeight: "bold",
                }}
              >
                <span className="font-medium text-white-600  text-base ">
                  {profileLabel}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!showSearchInput && searchMobile && (
        <div className="  flex justify-center items-center md:hidden ">
          <div className="functional-search  md:flex ">
            <div
              className="relative flex mt-2 sm:mt-0 !w-[300px] sm:!w-[500px] flex-wrap bg-white rounded-md"
              aria-hidden
              onClick={handleDivClick}
              ref={secondInputRef}
            >
              <span className="z-8 h-full  leading-snug font-normal text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center flex w-10 right-2  py-3">
                {false ? (
                  <ProgressSpinner
                    strokeWidth={4}
                    style={{ width: "20px", height: "20px" }}
                    color="#3b82f6"
                  />
                ) : (
                  <HiOutlineSearch className="h-4.5 w-5 text-gray-500 " />
                )}
              </span>
              <input
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Search"
                className={` px-3 w-full  justify-between border rounded-md py-2 text-gray-900 outline-none`}
              />
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default TATHeader;
