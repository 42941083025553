import ReactEcharts from "echarts-for-react";
import { formatCommas, formatNumberIntoK } from "../../../helpers/helpers";

function EMonthChart({
  monthData,
  xAxisName,
  yAxisName,
  type,
  costArr,
  resourceArr,
}) {
  const cloudPartner = "azure";
  const awsMonthArr = monthData?.map((item) =>
    item?.start_time ? parseInt(item.start_time.split("-")[2], 10) : null
  );
  const monthArr =
    monthData &&
    monthData?.map((item) => {
      const date = new Date(item.Date).getDate();
      return date;
    });

  const options = {
    xAxis: {
      type: "category",
      name: xAxisName,
      nameLocation: "middle",
      nameGap: 40,
      boundaryGap: false,
      axisLabel: {
        color: "black",
      },
      data: cloudPartner === "azure" ? monthArr : awsMonthArr,
      triggerEvent: true,
    },
    yAxis: {
      type: "value",
      name: yAxisName,
      nameLocation: "middle",
      nameRotate: 90,
      nameGap: 60,
      axisLabel: {
        color: "black",
        formatter: (value) => {
          return type === "resource_trend"
            ? formatNumberIntoK(value)
            : `$${formatNumberIntoK(value)}`;
        },
      },
    },
    tooltip: {
      show: true,
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          show: true,
        },
      },
      formatter: (params) => {
        return type === "resource_trend"
          ? `${formatCommas(params[0].value)}`
          : `$${formatCommas(params[0].value)}`;
      },
      textStyle: {
        color: "black",
        whiteSpace: "pre-line",
      },
      backgroundColor: "#ebf1f9",
      borderColor: "#366CBD",
    },
    series: [
      {
        data: type === "resource_trend" ? resourceArr : costArr,
        type: "line",
        label: {
          show: true,
          position: "top",
          padding: [0, 2, 0, 28],
          formatter: (params) => {
            return type === "resource_trend"
              ? `${formatNumberIntoK(params.value)}`
              : `$${formatNumberIntoK(params.value)}`;
          },
        },
      },
    ],
  };

  return (
    <div>
      <ReactEcharts
        option={options}
        style={{ width: "100%", height: "310px" }}
      />
    </div>
  );
}

export default EMonthChart;
