import { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { doughNutChartOption } from "../Jsonfiles/chartData";

function EDoughNutChart() {
  const chartData = [
    {
      "Business Unit": "Business Unit-5",
      Cost: "132344",
    },
    {
      "Business Unit": "Business Unit-2",
      Cost: "77500",
    },
    {
      "Business Unit": "Business Unit-1",
      Cost: "77879",
    },
    {
      "Business Unit": "Business Unit-3",
      Cost: "57677",
    },
    {
      "Business Unit": "Business Unit-4",
      Cost: "4601",
    },
  ];
  const [option, setOptions] = useState({ ...doughNutChartOption });
  const updateOption = () => {
    const data = chartData.slice(0, 5).map((doc) => {
      const keys = Object.keys(doc);
      return {
        name: doc[keys[0]],
        value: Number(doc[keys[1]]).toFixed(2),
      };
    });
    const deepClone = JSON.parse(JSON.stringify(option));
    deepClone.series[0].data = data;
    setOptions(deepClone);
  };

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      updateOption();
    }
  }, []);
  return (
    <div
      style={{ height: "100%", width: "100%"}}
      className="parent-container"
    >
      <ReactEcharts option={option} style={{ height: "100%", width: "100%" }} />
    </div>
  );
}

export default EDoughNutChart;
