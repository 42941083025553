import React, { useState, useEffect } from 'react';
import { FaArrowUp } from "react-icons/fa";

function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    // Show the button when user scrolls down 20px from the top of the document
    useEffect(() => {
        function handleScroll() {
            if (window.pageYOffset > 20) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Scroll to top when the button is clicked
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <button
            id="scrollToTopBtn"
            className={isVisible ? 'show rounded-lg' : 'hide'}
            onClick={scrollToTop}
            title="Go to top"
        >
            <FaArrowUp/>
        </button>
    );
}

export default ScrollToTopButton;
