import { useEffect, useRef, useState } from "react";
import { GoTriangleDown } from "react-icons/go";
import { SelectButton } from "primereact/selectbutton";
import moment from "moment";
import CubeBreadCrumb from "../Common/cubeBreadCrumb";
import {
  getAdditionalMonth,
  getDateRanges,
  getDateRange,
  getRangeDateSet,
  modifyArray,
} from "../../../helpers/helpers";
import forecastCountListJson from "../Jsonfiles/forecastCountList.json";
import targetPeriodDataJson from "../Jsonfiles/targetFileData.json";
import ChartCard from "../ChartCard";
import EBasicLineChart from "../FinopsHealthComponents/EBasicLineChart";
import EMonthChart from "./EMonthChart";
import { Tooltip } from "primereact/tooltip";
import healthRawData from "../Jsonfiles/healthRawData.json";


const graphVal = {
  resource: [],
  cost: [],
  ytd: [],
};

const period = {
  targetedMonth: null,
  targetedYear: null,
};

const initialRangeState = {
  type: "",
  value: [],
};
const monthChartState = {
  selectedMonth: moment().subtract(1, "months").format("MM"),
  selectedYear: moment().subtract(1, "months").year(),
};

const initialAzureState = {
  "Business Unit": {
    option: [],
    value: [],
    tab: "Business Unit",
  },
  SubscriptionName: {
    option: [],
    value: [],
    tab: "Subscription",
  },
  MeterCategory: {
    option: [],
    value: [],
    tab: "Meter Category",
  },
  MeterSubCategory: {
    option: [],
    value: [],
    tab: "Meter Sub - Category",
  },
  ResourceLocation: {
    option: [],
    value: [],
    tab: "Location",
  },
  ResourceGroup: {
    option: [],
    value: [],
    tab: "Resource Group",
  },
};
const initialAwsState = {
  bill_payer_account_id: {
    option: [],
    value: [],
    tab: "Account",
  },
  line_item_product_code: {
    option: [],
    value: [],
    tab: "Product",
  },
  product_servicecode: {
    option: [],
    value: [],
    tab: "Service",
  },
  product_location: {
    option: [],
    value: [],
    tab: "Region",
  },
  product_instance_type: {
    option: [],
    value: [],
    tab: "Instance Type",
  },
  Business_unit: {
    option: [],
    value: [],
    tab: "Business Unit",
  },
};

const simulatorState = {
  flag: false,
};

const routeModules = [{ name: "Forecast" }, { name: "Trend Analysis" }];

const calculateYTD = (data, simulated = false) => {
  const processedData = [];
  let currentYear = null;
  let previousYTD = 0;
  for (const entry of data) {
    const processedEntry = { ...entry };
    if (entry.year !== currentYear) {
      currentYear = entry.year;
      previousYTD = parseFloat(entry.forecast_cost);
    } else {
      previousYTD += parseFloat(
        simulated
          ? entry.simulation_cost || entry.forecast_cost
          : entry.forecast_cost
      );
    }
    processedEntry.ytd = previousYTD;
    processedData.push(processedEntry);
  }

  return processedData;
};

function Forecast() {
  const currentDate = moment();
  const cloudPartner = "azure";
  const [graphData, setGraphData] = useState(graphVal);
  const [filterOption, setFilterOption] = useState({});
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [reportRange, setReportRange] = useState(initialRangeState);
  const [targetPeriod, setTargetPeriod] = useState(period);
  const [dateKeys, setDateKeys] = useState([]);
  const [showSimulator, setShowSimulator] = useState(simulatorState);
  const [showMonthChart, setShowMonthChart] = useState(monthChartState);
  const [filterApplied, setFilterApplied] = useState(false);
  const forecastUntil = getDateRange(2)
  const costTrends = getDateRange(-1)
  const routeView = "Forecast";
  const [view, setView] = useState({ name: routeView });
  const items = [{ label: "Suggestion Engine" }, { label: view?.name }];
  const [columnData, setColumnData] = useState(
    cloudPartner === "azure" ? initialAzureState : initialAwsState
  );
  const [selectedTab, setSelectedTab] = useState(
    cloudPartner === "azure" ? "Business Unit" : "line_item_product_code"
  );
  const forecastCountList = forecastCountListJson;
  const processedData =
    forecastCountList && calculateYTD(forecastCountList, filterApplied);
  const targetPeriodData =
    processedData &&
    processedData?.filter((entry) => {
      const entryYear = parseInt(entry.year, 10);
      const entryMonth = parseInt(entry.month, 10);

      return (
        (entryYear === targetPeriod.targetedYear - 1 &&
          entryMonth > targetPeriod.targetedMonth) ||
        (entryYear === targetPeriod.targetedYear &&
          entryMonth <= targetPeriod.targetedMonth)
      );
    });
  // const costArr = monthData && monthData?.map((item) => parseFloat(item.cost));
  const graphValForResource =
    targetPeriodData &&
    targetPeriodData?.map((item) => parseFloat(item.forecast_count));
  const graphValForCost =
    targetPeriodData &&
    targetPeriodData?.map((item) => parseFloat(item.forecast_cost));
  const simulatedCost =
    targetPeriodData &&
    targetPeriodData.map((entry) => {
      return entry.actual
        ? parseFloat(entry.forecast_cost)
        : parseFloat(entry.simulation_cost);
    });
  const graphValForYTD =
    targetPeriodData && targetPeriodData?.map((item) => parseFloat(item.ytd));
  const amortState = false;
  const groupByOverlayRef = useRef();

  const handleType = (column_name) => {
    setSelectedTab(column_name);
  };

  const updateColumnData = () => {
    // const column_name = selectedTab;
    // if (column_name && columnData[column_name]?.option.length === 0) {
    //   getFilterColumnValue({ column_name })
    //     .unwrap()
    //     .then((resp) => {
    //       setColumnData((prev) => {
    //         const prevState = { ...prev };
    //         prevState[column_name].option = resp.map(
    //           (item) => item[column_name]
    //         );
    //         return prevState;
    //       });
    //     });
    // }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setColumnData((prev) => {
      const newState = { ...prev };
      const index = newState[selectedTab].value.indexOf(value);
      if (checked && index === -1) {
        newState[selectedTab].value.push(value);
      } else if (!checked && index !== -1) {
        newState[selectedTab].value.splice(index, 1);
      }
      return newState;
    });
  };

  const formatedData = {
    json_payload: {
      filter_conditions: Object.keys(columnData).reduce(
        (filterConditions, tabKey) => {
          const tabData = columnData[tabKey];
          const tabFilterConditions = tabData.value.map(
            (columnValue, index) => ({
              column_name: tabKey,
              column_value: columnValue,
              column_condition: "is",
              filter_operator: index === 0 ? "AND" : "OR",
            })
          );

          return [...filterConditions, ...tabFilterConditions];
        },
        []
      ),
    },
  };

  const handleAppliedFilters = () => {
    if (
      filterOption &&
      filterOption?.json_payload?.filter_conditions?.length > 0
    ) {
      const filterCounts = {};
      filterOption.json_payload.filter_conditions.forEach((condition) => {
        const { column_name } = condition;
        filterCounts[column_name] = (filterCounts[column_name] || 0) + 1;
      });

      const filters = Object.keys(filterCounts).map((appliedFilter) => {
        return { appliedFilter, filterCount: filterCounts[appliedFilter] };
      });

      setAppliedFilters(filters);
    }
  };

  const onFilterApply = () => {
    const params = {
      ...formatedData,
    };
    setFilterOption(params);
    groupByOverlayRef.current.hide();
  };

  const openSimulatorModal = () => {
    setShowSimulator({
      flag: true,
    });
  };

  const hideSimulatorModal = () => {
    setShowSimulator(simulatorState);
  };

  const onResetData = (data) => {
    const resetData = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        resetData[key] = { ...data[key], value: [] };
      }
    }
    return resetData;
  };

  const handleGraphData = () => {
    setGraphData((prev) => ({
      ...prev,
      resource: graphValForResource,
      cost: graphValForCost,
      ytd: graphValForYTD,
    }));
  };

  const handleReset = () => {
    const resetData = onResetData(columnData);
    setColumnData(resetData);
  };

  const handleAppliedFilterReset = () => {
    handleReset();
    setFilterOption({});
    setAppliedFilters([]);
  };

  const initialRangeUpdate = () => {
    const startDate = new Date(getAdditionalMonth(2));
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(endDate.getDate() - 1);
    setReportRange({
      type: "month",
      value: [startDate, endDate],
    });
    setTargetPeriod({
      targetedMonth: moment(endDate).month() + 1,
      targetedYear: moment(endDate).year(),
    });
    if (startDate && endDate) {
      const { lastMonths, futureMonths } = getDateRanges(0, 6);
      const rangeSet = getRangeDateSet(lastMonths, futureMonths);
      setDateKeys(rangeSet);
    }
  };

  const handleToggleChange = () => {};

  const handleRunQuery = (filters) => {
    const updatedFilters = filters.map((item) => ({
      ...item,
      data: item.data.replace(/[^\d.]/g, ""),
    }));
    hideSimulatorModal();
    setFilterApplied(true);
  };

  const handleXAxisLabelClick = (month, type) => {
    if (type === "cost_forecast" && month && typeof month === "string") {
      const monthStr = parseInt(moment().month(month).format("M"), 10);
      const monthVal = moment().month(month).format("MM");
      const parts = month.split("'");
      const yearVal = `20${parts[1]}`;
      const params = {
        month: monthVal,
        year: yearVal,
        amortization: amortState,
      };
      if (monthVal) {
        // getSingleMonthCost(params)
        //   .unwrap()
        //   .then(() => {
        //     setShowMonthChart({
        //       selectedMonth: typeof month === "string" ? monthStr : "",
        //       selectedYear: yearVal,
        //     });
        //   });
      }
    }
  };

  const handleViewChange = (e) => {
    // if (e?.value?.name === "Forecast") {
    //   dispatch(handleRouteViewChange("Forecast"));
    //   setView(e.value);
    // } else if (e?.value?.name === "Trend Analysis") {
    //   dispatch(handleRouteViewChange("Trend Analysis"));
    //   setView(e.value);
    // } else {
    //   dispatch(handleRouteViewChange("Forecast"));
    //   setView({ name: "Forecast" });
    // }
  };

  const fetchInitialMonthChart = () => {
    // let params = {
    //   month: showMonthChart.selectedMonth,
    //   year: previousMonthYear,
    //   amortization: amortState,
    // };
    // if (
    //   filterOption &&
    //   filterOption?.json_payload?.filter_conditions?.length > 0
    // ) {
    //   params = { ...params, ...filterOption };
    // }
    // getSingleMonthCost(params);
  };

  const onHide = () => {
    setShowMonthChart(monthChartState);
  };

  const updateForecastChartRange = (key) => {
    const fromDate = moment(key, "MMMM YYYY")
      .startOf("month")
      .format("YYYY-MM-DD");
    const toDate = moment(key, "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    setReportRange({
      type: "month",
      value: [fromDate, toDate],
    });
    setTargetPeriod({
      targetedMonth: moment(toDate).month() + 1,
      targetedYear: moment(toDate).year(),
    });
  };

  const handleFetchInitialData = () => {
    let params = {
      date: moment(reportRange.value[1]).format("YYYY-MM-DD"),
      amortization: amortState,
    };
    if (
      filterOption &&
      filterOption?.json_payload?.filter_conditions?.length > 0
    ) {
      params = { ...params, ...filterOption };
    }
    setFilterApplied(false);
  };

  const tableHeaderProps = {
    handleDateChange: updateForecastChartRange,
    dateKeys,
  };

  useEffect(() => {
    if (reportRange.value.length > 0) {
      handleFetchInitialData();
    }
  }, [reportRange, filterOption]);

  useEffect(() => {
    fetchInitialMonthChart();
  }, []);

  useEffect(() => {
    fetchInitialMonthChart();
  }, [amortState, filterOption]);

  useEffect(() => {
    handleAppliedFilters();
  }, [filterOption]);

  useEffect(() => {
    updateColumnData();
  }, [selectedTab]);

  useEffect(() => {
    if (forecastCountList?.length > 0) {
      handleGraphData();
    }
  }, [forecastCountList]);

  useEffect(() => {
    if (routeView) {
      setView({ name: routeView });
    }
  }, [routeView]);

  useEffect(() => {
    initialRangeUpdate();
  }, []);

  return (
    <>
      {routeView === "Forecast" && (
        <main>
          <CubeBreadCrumb
            items={items}
            showToggle
            onToggleChange={handleToggleChange}
          />
          <header className="w-full flex flex-row items-center gap-2 pt-3">
            <div className="w-1/2 flex flex-wrap gap-4">
              <div className="flex items-center">
                <p className="text-sm px-1">Forecast until :</p>
                <div className="text-sm px-2 flex items-center justify-center rounded-md h-[35px] bg-[#ffffff]  border border-[#dde0e4]">{forecastUntil.detailedObj.monthShort} {forecastUntil.detailedObj.year}</div>
              </div>
              <div className="flex items-center">
                <p className="text-xs mr-3">Select Group</p>
                <Tooltip
                    target=".groupby"
                    content="Preview unavailable - request demo"
                    position='bottom'
                  />
                <div
                  className="w-[7rem] rounded-md groupby border border-white-200 bg-white px-4 py-2 h-[35px] text-xs font-medium text-white-700 flex justify-between"
                  aria-hidden
                >
                  <div className="flex items-center">
                    <span className="text-gray-600">Group by</span>
                  </div>
                  <GoTriangleDown className="text-secondary text-lg" />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="border border-r-0 border-gray-400 h-7" />
                {appliedFilters.length > 0 ? (
                  appliedFilters.map((doc) => {
                    return (
                      <div className="flex flex-row items-center justify-between">
                        <div
                          className={`bg-[#EFEFFD] ${
                            cloudPartner === "azure" ? "w-[118px]" : "w-[145px]"
                          } h-[30px] flex justify-center items-center rounded-lg ml-2`}
                        >
                          <span className=" text-gray-500 text-[11px]">{`${doc.appliedFilter} (${doc.filterCount})`}</span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex items-center">
                    <span className="text-gray-400 mx-3 text-sm">
                      No filter applied
                    </span>
                  </div>
                )}
                {appliedFilters.length > 0 && (
                  <div
                    aria-hidden
                    className="bg-[#297cc5] w-[85px] h-[30px] flex justify-center items-center rounded-lg ml-4"
                    onClick={handleAppliedFilterReset}
                  >
                    <span className=" text-white text-xs cursor-pointer">
                      Reset filter
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/2 flex items-center justify-between">
              <div className="flex items-center w-[250px]">
              <Tooltip
                    target=".costsimulator"
                    content="Preview unavailable - request demo"
                    position='bottom'
                  />
                <div
                  aria-hidden
                  className={`items-center justify-center costsimulator h-[32px] w-[120px]  customColor w-1/8 cursor-pointer p-2 flex rounded-full btn_primary`}
                  onClick={openSimulatorModal}
                >
                  <p className="text-[12px] text-white font-bold">Cost simulator</p>
                </div>
                {filterApplied && (
                  <div
                    aria-hidden
                    className="text-secondary underline mx-2 cursor-pointer p-2"
                    onClick={handleFetchInitialData}
                  >
                    <p className="text-sm">Clear Filter</p>
                  </div>
                )}
              </div>
              <Tooltip
                    target=".forecast"
                    content="Preview unavailable - request demo"
                    position='bottom'
                  />
              <div
                className={`items-center py-3 md:py-0 lg:py-0 xl:py-0 px-2 forecast ${
                  cloudPartner === "azure" ? "flex" : "hidden"
                }`}
              >
                <SelectButton
                  value={view}
                  options={routeModules}
                  onChange={(e) => handleViewChange(e)}
                  optionLabel="name"
                  placeholder="Select a View"
                  className="w-fit !font-bold"
                  dropdownIcon={
                    <GoTriangleDown className="text-secondary text-lg" />
                  }
                />
              </div>
            </div>
          </header>
          <section className="py-4">
            <article className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <ChartCard title="Resource Forecast">
                <EBasicLineChart
                  graphVal={[
                    62540, 66015, 76070, 73717, 88493, 83210, 84334, 92623,
                    92825, 96351.62708498712, 100798.60335333219,
                    103699.14077709947,
                  ]}
                  type="resource_forecast"
                  rawData={modifyArray(targetPeriodDataJson)}
                  isLegendType="horizontal"
                  isForecasted
                />
              </ChartCard>
              <ChartCard title="Cost Forecast">
              <EBasicLineChart
              type="cost_forecast"
              isForecasted
              isLabelClick
              graphVal={[
                337605, 332874, 332270, 357316, 326819, 352567, 355669, 372102,
                350000, 349487, 347779, 340157, 336951,
              ]}
              graphYTD={[
                550028.7445, 590808.4674, 619799.6485, 651690.8205, 670479.0316,
                665143.719, 689586.1717, 684135.3234, 679386.5322, 708236.452,
                727771.6253, 722102.4536, 349487.0184, 697266.1335, 687935.9104,
                677108.2165,
              ]}
              isLegendType="horizontal"
              rawData={modifyArray(healthRawData)}
              handleXAxisLabelClick={handleXAxisLabelClick}
            />
              </ChartCard>
            </article>
            <div className="my-2 w-full h-[340px] rounded-lg">
              <ChartCard
                title={`${costTrends.detailedObj.monthFull} ${costTrends.detailedObj.year} Cost Trends`}
                onHide={onHide}
              >
                <div>
                  <EMonthChart
                    monthData={[
                      {
                        Date: "2024-02-01 00:00:00.000",
                        cost: "99821.9490200000",
                        resource_count: "19358",
                      },
                      {
                        Date: "2024-02-02 00:00:00.000",
                        cost: "179949.6409300000",
                        resource_count: "19149",
                      },
                      {
                        Date: "2024-02-03 00:00:00.000",
                        cost: "95463.2036100000",
                        resource_count: "18451",
                      },
                      {
                        Date: "2024-02-04 00:00:00.000",
                        cost: "93826.1687000000",
                        resource_count: "17920",
                      },
                      {
                        Date: "2024-02-05 00:00:00.000",
                        cost: "96043.4166200000",
                        resource_count: "18738",
                      },
                      {
                        Date: "2024-02-06 00:00:00.000",
                        cost: "118065.9860000000",
                        resource_count: "19541",
                      },
                      {
                        Date: "2024-02-07 00:00:00.000",
                        cost: "116113.6705900000",
                        resource_count: "19926",
                      },
                      {
                        Date: "2024-02-08 00:00:00.000",
                        cost: "97397.8080700000",
                        resource_count: "19869",
                      },
                      {
                        Date: "2024-02-09 00:00:00.000",
                        cost: "112466.8376400000",
                        resource_count: "19623",
                      },
                      {
                        Date: "2024-02-10 00:00:00.000",
                        cost: "137875.7761100000",
                        resource_count: "19114",
                      },
                      {
                        Date: "2024-02-11 00:00:00.000",
                        cost: "110118.0742500000",
                        resource_count: "18001",
                      },
                      {
                        Date: "2024-02-12 00:00:00.000",
                        cost: "93406.5002700000",
                        resource_count: "19480",
                      },
                      {
                        Date: "2024-02-13 00:00:00.000",
                        cost: "122098.7326700000",
                        resource_count: "19830",
                      },
                      {
                        Date: "2024-02-14 00:00:00.000",
                        cost: "91135.4601000000",
                        resource_count: "19784",
                      },
                      {
                        Date: "2024-02-15 00:00:00.000",
                        cost: "90571.6852800000",
                        resource_count: "19662",
                      },
                    ]}
                    costArr={[
                      99821.94902, 179949.64093, 95463.20361, 93826.1687,
                      96043.41662, 118065.986, 116113.67059, 97397.80807,
                      112466.83764, 137875.77611, 110118.07425, 93406.50027,
                      122098.73267, 91135.4601, 90571.68528,
                    ]}
                    xAxisName={`${costTrends.detailedObj.monthFull} ${costTrends.detailedObj.year}`}
                    yAxisName="Cost"
                  />
                </div>
              </ChartCard>
            </div>
          </section>
        </main>
      )}
    </>
  );
}

export default Forecast;
