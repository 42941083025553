import { useEffect, useState } from 'react';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import { monthShort } from '../../../helpers/helpers';

function DateSelector({ option, onChange }) {
  const [year, setYear] = useState(null);
  const isActionEnable = option && Object.keys(option).length > 1;

  const updateYear = (selectedYear) => {
    setYear(selectedYear);
  };

  const handleAction = (type) => {
    const keys = Object.keys(option);
    let currentIndex = keys.findIndex((doc) => doc === year);
    if (currentIndex !== -1) {
      if (type === 'prev') {
        if (currentIndex - 1 < 0) {
          currentIndex = keys.length - 1;
        } else {
          currentIndex -= 1;
        }
      }
      if (type === 'next') {
        if (currentIndex + 1 > keys.length - 1) {
          currentIndex = 0;
        } else {
          currentIndex += 1;
        }
      }
    }
    if (currentIndex === -1) {
      currentIndex = 0;
    }
    const value = keys[currentIndex];
    setYear(value);
  };

  useEffect(() => {
    if (option && Object.keys(option).length > 0 && !year) {
      const selectedYear = Object.keys(option)[0];
      updateYear(selectedYear);
    }
  }, [option, year]);
  return (
    <div className="p-4 w-[200px] text-xs">
      {option && Object.keys(option).length > 0 && (
        <div>
          <header className="flex justify-between items-center w-full">
            <span
              className={isActionEnable ? 'cursor-pointer' : ' pointer-events-none'}
              aria-hidden
              onClick={() => handleAction('prev')}>
              <GrLinkPrevious className={!isActionEnable && 'disableIcon'} />
            </span>
            <span className="font-bold">{year}</span>
            <span
              className={isActionEnable ? 'cursor-pointer' : ' pointer-events-none'}
              aria-hidden
              onClick={() => handleAction('next')}>
              <GrLinkNext className={!isActionEnable && 'disableIcon'} />
            </span>
          </header>
          <section className="py-3 grid grid-cols-3 gap-2">
            {year &&
              option[year] &&
              option[year].length > 0 &&
              option[year].map((date) => (
                <p
                  key={`tag-${date}`}
                  className="px-4 py-2 bg-[#eaf4fe] rounded-md cursor-pointer"
                  aria-hidden
                  onClick={() => {
                    if (onChange && typeof onChange === 'function') onChange(date);
                  }}>
                  {monthShort[new Date(date).getMonth()]}
                </p>
              ))}
          </section>
        </div>
      )}
    </div>
  );
}

export default DateSelector;
