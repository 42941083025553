import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { TiTick } from "react-icons/ti";
import { IoClose } from "react-icons/io5";
import CustomReport from "../assets/icons/custom_report.png";
import TagKey from "../assets/icons/tag_key.png";
import SuggestionEngine from "../assets/icons/suggestion_engine.png";
import Anomaly from "../assets/icons/anomaly.png";
import MultiCloud from "../assets/icons/multi_cloud.png";
import BG from "../assets/icons/bg_shade.png";
import Spotlight1 from "../assets/icons/spotlight-1.png";
import "../css/MobileSolution.css";
const Pricing = () => {
    return (
        <section id="features">
            <div>
                <div className="container mx-auto">
                    <header className="flex flex-col justify-center items-center mobile__view__feature__header">
                        <div className="flex justify-center items-center mt-16">
                            <img src={Spotlight1} className="w-14 h-14 mr-3" alt="spotlight" />
                            <p className="text-[#14304A] lg:text-[35px] md:text-[35px] xl:text-[35px] text-[25px]  py-5  leading-10 font-medium">
                                Spotlight on CloudLens Capabilities
                                <span className="dot">.</span>
                            </p>
                        </div>
                        <div className="mb-10 flex flex-wrap w-full justify-center items-center">
                            <span className="text-[17px] mx-auto text-[#7791A5] font-normal w-[900px] leading-[30px] text-center">
                                Enhance your cloud cost optimization journey with more than just savings.We serve as
                                your <br /> comprehensive FinOps and Cloud Cost Optimization partner.
                            </span>
                        </div>
                    </header>
                    <div className="columns-2 flex flex-row mobile__view__feature__content">
                        <div className="w-1/2 m-10">
                            <img className="pricing__img__shade" src={BG} alt="landscape" />
                            <img className="pricing__img" src={CustomReport} alt="landscape" />
                        </div>
                        <div className="w-1/2 my-10">
                            <div className="p-10 h-100" style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                <h3 className="text-[25px] mb-5 text-primary">Custom Reports​</h3>

                                <ul className="list-disc text-[15px] font-normal">
                                    <li className="text-secondary">
                                        Create highly personalised reports tailored to individual business needs.
                                    </li>
                                    <li className="text-secondary">
                                        Provide users with powerful and versatile data filtering options, enabling
                                        precise control over information refinement.
                                    </li>
                                    <li className="text-secondary">
                                        Facilitate in-depth data analysis, allowing users to derive meaningful insights
                                        aligned with their specific requirements within the FinOps platform.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className=" bg-secondary_light ">
                    <div className="container mx-auto ">
                        <div className=" columns-2 flex flex-row mobile__view__feature__content ">
                            <div className="w-1/2 my-10">
                                <div className="p-10 h-100" style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                    <h3 className="text-[25px] mb-5 text-primary">Tag Key Governance</h3>

                                    <ul className="list-disc text-[15px]">
                                        <li className="text-secondary">
                                            Systematically tracks and displays the array of tag keys currently in use.
                                        </li>
                                        <li className="text-secondary">
                                            Offers a clear view of resource utilisation percentages for each tag, aiding
                                            in effective resource management.
                                        </li>
                                        <li className="text-secondary">
                                            Enables efficient organization and optimization of resources based on
                                            tagging strategies.
                                        </li>
                                        <li className="text-secondary">
                                            Provides a comprehensive overview of tag usage, fostering better control and
                                            understanding of resource allocation.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="w-1/2 m-10">
                                <img className="pricing__img__shade" src={BG} alt="landscape" />
                                <img className="pricing__img" src={TagKey} alt="landscape" />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="container mx-auto">
                    <div className="columns-2 my-10 flex flex-row mobile__view__feature__content">
                        <div className="w-1/2 m-10">
                            <div className=" flex flex-row">
                                <img className="pricing__img__shade" src={BG} alt="landscape" />
                                <img className="pricing__img__init" src={SuggestionEngine} alt="landscape" />
                                <img className="pricing__img__one" src={Anomaly} alt="landscape" />
                            </div>
                        </div>
                        <div className="w-1/2 my-10">
                            <div className="p-10 h-100" style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                <h3 className="text-[25px] mb-5 text-primary">Suggestion Engine</h3>

                                <ul className="list-disc text-[15px]">
                                    <li className="text-secondary">
                                        Examines your expenditures from the previous month across various resources. It
                                        identifies any unusual or sudden increases in spending, providing valuable
                                        insights to help you manage your budget effectively.
                                    </li>
                                    <li className="text-secondary">
                                        Utilizes the spending data from the past month to create a forecast for the
                                        entire year. This proactive feature empowers you to plan ahead, aligning your
                                        resources with a well-informed projection for the coming months.
                                    </li>
                                    <li className="text-secondary">
                                        It gives the flexibility to customize and filter the analysis based on your
                                        specific needs. This adaptability ensures that the engine aligns precisely with
                                        your unique business requirements, providing insights that matter most to you.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="bg-secondary_light">
                    <div className="container mx-auto">
                        <div className="columns-2  flex flex-row items-center mobile__view__feature__content ">
                            <div className="w-1/2 my-10">
                                <div className="p-10 h-100" style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                    <h3 className="text-[25px] mb-5 text-primary">Multi-Cloud Dashboarding​</h3>

                                    <ul className=" list-disc text-[15px]">
                                        <li className="text-secondary">
                                            CloudLens streamlines the process of aggregating data from diverse
                                            providers, offering a comprehensive platform for cohesive optimization
                                            strategies.
                                        </li>
                                        <li className="text-secondary">
                                            By seamlessly integrating information from various sources, it facilitates a
                                            holistic approach to planning and implementing optimization measures.
                                        </li>
                                        <li className="text-secondary">
                                            This unified tool not only simplifies data management but also enhances the
                                            efficiency of decision-making processes, empowering businesses to make
                                            well-informed and strategic choices for their cloud-based operations.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="w-1/2 m-10">
                                <img className="pricing__img__shade" src={BG} alt="landscape" />
                                <img className="pricing__img" src={MultiCloud} alt="landscape" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="pricing">
                <div className="web__view__solution">
                    <div className="container mx-auto">
                        <header className="flex flex-col mt-10 justify-center items-center">
                            <p className="text-[#14304A] text-[35px] leading-10  mt-16 font-medium">
                                Service Plans<span className="dot">.</span>
                            </p>
                            <p className="text-secondary xl:px-52 lg:px-52 md:px-52 px-5 my-5">
                                Based on your environment size and usage, we offer varied service plans to leverage.
                            </p>
                        </header>
                        <div className="flex justify-start xl:justify-between items-start py-[50px] px-[30px] lg:gap-5 xl:gap-3">
                            <div className="w-[370px]">
                                <p className="text-primary flex flex-wrap xl:text-[30px] lg:text-[25px] leading-[] font-medium mb-10">
                                    Choose the right service plan for your environment.
                                </p>
                                <Accordion multiple className="w-[300px] accordian">
                                    <AccordionTab header="Extended maintenance and custom enhancement">
                                        <p className="m-0">
                                            Ensure seamless financial operations with our Extended Maintenance Plans,
                                            providing timely updates and security patches. Elevate your system's
                                            capabilities with Custom Enhancement Solutions tailored to your unique
                                            business needs.
                                        </p>
                                    </AccordionTab>
                                </Accordion>
                            </div>
                            <div className="overflow-x-auto lg:w-[1000px] xl:w-[1200px]">
                                <table className="min-w-full border text-primary border-gray-200">
                                    <tbody>
                                        <tr>
                                            <td className="py-5 border-b-2 border-r-2 text-center"></td>
                                            <td className="py-5 border-b-2 border-r-2 text-center">
                                                <span className="btn-grad-blue">Forever Free</span>
                                            </td>
                                            <td className="py-5 border-b-2 border-r-2 text-center">
                                                <span className="btn-grad-red ">&nbsp;Enterprise&nbsp;</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="lg:w-[230px] xl:w-[300px] p-2 border-b-2 border-r-2 border-gray-200 border-dashed">
                                                Annual Cloud Budget
                                            </td>
                                            <td className="py-2 border-b-2 border-r-2 border-gray-200 border-dashed text-center">
                                                $1M
                                            </td>
                                            <td className=" py-2 border-b-2 border-r-2 border-gray-200 border-dashed text-center">
                                                {`>$1M`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="lg:w-[230px] xl:w-[300px] p-2 border-b border-r-2 border-gray-200 border-dashed">
                                                Hosting
                                            </td>
                                            <td className=" py-2 border-b border-r-2 border-gray-200 border-dashed text-center">
                                                Customer's OnPrem or Cloud
                                            </td>
                                            <td className=" py-2 border-b border-r-2 border-gray-200 border-dashed text-center">
                                                Customer's OnPrem or Cloud
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="lg:w-[230px] xl:w-[300px] p-2 border-b-2 border-r-2 border-gray-200 border-dashed">
                                                Initial Implementation
                                            </td>
                                            <td className="border-b-2 border-r-2 border-gray-200 border-dashed text-center">
                                                <div className="flex justify-center items-center">
                                                    <TiTick className="w-10 h-10 text-green-500" />
                                                </div>
                                            </td>
                                            <td className="border-b-2 border-r-2 border-gray-200 border-dashed text-center">
                                                <div className="flex justify-center items-center">
                                                    <TiTick className="w-10 h-10 text-green-500" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="lg:w-[230px] xl:w-[300px] p-2 border-b-2 border-r-2 border-gray-200 border-dashed ">
                                                <div className="w-[150px] flex justify-center items-center">
                                                    Extended Support (Annual Contract)
                                                </div>
                                            </td>
                                            <td className=" border-b-2 border-r-2 border-gray-200 border-dashed text-center">
                                                <div className="flex justify-center items-center">
                                                    <IoClose className="w-10 h-10 text-red-500" />
                                                </div>
                                            </td>
                                            <td className="border-b-2 border-r-2 border-gray-200 border-dashed text-center">
                                                <div className="flex justify-center items-center">
                                                    <TiTick className="w-10 h-10 text-green-500" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="lg:w-[230px] xl:w-[300px] p-2 border-b-2 border-r-2 border-gray-200 border-dashed">
                                                Database
                                            </td>
                                            <td className=" py-2 border-b-2 border-r-2 border-gray-200 border-dashed text-center">
                                                SQL Server / Snowflake
                                            </td>
                                            <td className=" py-2 border-b-2 border-r-2 border-gray-200 border-dashed text-center">
                                                SQL Server / Snowflake
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="lg:w-[230px] xl:w-[300px] p-3 py-4 border-b-2 border-r-2 border-gray-200 border-dashed">
                                                Use CloudLens for
                                            </td>
                                            <td className="py-3 border-b-2 border-r-2 border-gray-200 border-dashed text-center">
                                                <div className="flex justify-evenly items-center">
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Spend Forecast</span>
                                                    </div>
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">User-defined reporting</span>
                                                    </div>
                                                    <div className="bg-gray-300  lg:w-[60px] xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Budget Alerts</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-evenly items-center mt-2">
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Tag Maturity</span>
                                                    </div>
                                                    <div className="bg-gray-300  lg:w-[60px] xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">TCO report</span>
                                                    </div>
                                                    <div className="bg-gray-300  lg:w-[60px] xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Anomaly Detection</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-evenly items-center mt-2">
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Actuals & Amortized Spend</span>
                                                    </div>
                                                    <div className="bg-gray-300  lg:w-[60px] xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Azure / AWS / OCI</span>
                                                    </div>
                                                    <div className="bg-gray-300  lg:w-[60px] xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">PaaS & SaaS Analytics</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="py-3 border-b-2 border-r-2 border-gray-200 border-dashed text-center">
                                                <div className="flex justify-evenly items-center">
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Spend Forecast</span>
                                                    </div>
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">User-defined reporting</span>
                                                    </div>
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Budget Alerts</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-evenly items-center mt-2">
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Tag Maturity</span>
                                                    </div>
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">TCO report</span>
                                                    </div>
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Anomaly Detection</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-evenly items-center mt-2">
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Actuals & Amortized Spend</span>
                                                    </div>
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Azure / AWS / OCI</span>
                                                    </div>
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">PaaS & SaaS Analytics</span>
                                                    </div>
                                                </div>
                                                <div className="flex justify-evenly items-center mt-2">
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Azure tagging cleanup</span>
                                                    </div>
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Customized Analytics</span>
                                                    </div>
                                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                                        <span className="text-[9px]">Comparison modelling(ad-hoc)</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mobile__view__solution">
                    <div className="container mx-auto">
                        <header className="flex flex-col mt-10 justify-center items-center">
                            <p className="text-[#14304A] text-[35px] leading-10 font-medium">
                                Service Plans<span className="dot">.</span>
                            </p>
                            <p className="text-secondary xl:px-52 lg:px-52 md:px-52 px-5 my-5">
                                Based on your environment size and usage, we offer varied service plans to leverage.
                            </p>
                        </header>
                        <div className="flex flex-col p-5">
                            <div className="">
                                <p className="text-primary flex flex-wrap xl:text-[30px] lg:text-[25px] leading-[] font-medium mb-10">
                                    Choose the right service plan for your environment.
                                </p>
                                <Accordion multiple className="my-5 accordian">
                                    <AccordionTab header="Extended maintenance and custom enhancement">
                                        <p className="p-3">
                                            Ensure seamless financial operations with our Extended Maintenance Plans,
                                            providing timely updates and security patches. Elevate your system's
                                            capabilities with Custom Enhancement Solutions tailored to your unique
                                            business needs.
                                        </p>
                                    </AccordionTab>
                                </Accordion>
                            </div>

                            <div className="border border-dashed border-gray-500 leading-8 rounded p-3">
                                <div className="flex justify-center my-3">
                                    <div className="">
                                        <span className="btn-grad-blue">Forever Free</span>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <div className="w-1/2">Annual Cloud Budget</div>:
                                    <div className="w-1/2 text-sm md:max-lg:text-base font-light"> &nbsp;$1M</div>
                                </div>

                                <div className="flex items-center">
                                    <div className="w-1/2">Hosting</div>:
                                    <div className="w-1/2 text-sm md:max-lg:text-base font-light">
                                        {" "}
                                        &nbsp;Customer's OnPrem or Cloud
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <div className="w-1/2">Initial Implementation</div>:
                                    <div className="w-1/2 text-sm md:max-lg:text-base font-light"> &nbsp;Included</div>
                                </div>

                                <div className="flex items-center">
                                    <div className="w-1/2 leading-5">
                                        Extended Support <span className="text-xs">(Annual Contract)</span>
                                    </div>
                                    :<div className="w-1/2 text-sm md:max-lg:text-base font-light"> &nbsp;Excluded</div>
                                </div>

                                <div className="flex items-center">
                                    <div className="w-1/2">Database</div>:
                                    <div className="w-1/2 text-sm md:max-lg:text-base font-light">
                                        &nbsp;SQL Server / Snowflake
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <div className="w-1/2">Use CloudLens for</div>:<div className="w-1/2"></div>
                                </div>
                                <div className="grid grid-cols-3 gap-3">
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Spend Forecast</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded leading-3 text-center">
                                        <span className="text-[9px] md:text-[16px]">User-defined reporting</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Budget Alerts</span>
                                    </div>

                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Tag Maturity</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">TCO report</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Anomaly Detection</span>
                                    </div>

                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px] text-center leading-3">
                                            Actuals & Amortized Spend
                                        </span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Azure / AWS / OCI</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded leading-3 text-center">
                                        <span className="text-[9px] md:text-[16px]">PaaS & SaaS Analytics</span>
                                    </div>
                                </div>
                            </div>

                            <div className="border border-dashed border-gray-500  leading-8 rounded p-3 mt-5">
                                <div className="flex justify-center my-3">
                                    <div className="">
                                        <span className="btn-grad-red ">&nbsp;Enterprise&nbsp;</span>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <div className="w-1/2">Annual Cloud Budget </div>:
                                    <div className="w-1/2 text-sm md:max-lg:text-base font-light">&nbsp;{`>$1M`}</div>
                                </div>

                                <div className="flex items-center">
                                    <div className="w-1/2">Hosting </div>:
                                    <div className="w-1/2 text-sm md:max-lg:text-base font-light">
                                        &nbsp;Customer's OnPrem or Cloud
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <div className="w-1/2">Initial Implementation </div>:
                                    <div className="w-1/2 text-sm md:max-lg:text-base font-light">&nbsp;Included</div>
                                </div>

                                <div className="flex items-center">
                                    <div className="w-1/2 leading-5">
                                        Extended Support <span className="text-xs">(Annual Contract)</span>
                                    </div>
                                    :<div className="w-1/2 text-sm md:max-lg:text-base font-light">&nbsp;Included</div>
                                </div>

                                <div className="flex items-center">
                                    <div className="w-1/2">Database </div>:
                                    <div className="w-1/2 text-sm md:max-lg:text-base font-light">
                                        &nbsp;SQL Server / Snowflake
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <div className="w-1/2">Use CloudLens for</div>:<div className="w-1/2"></div>
                                </div>
                                <div className="grid grid-cols-3 gap-3">
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Spend Forecast</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded leading-3 text-center">
                                        <span className="text-[9px] md:text-[16px]">User-defined reporting</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Budget Alerts</span>
                                    </div>

                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Tag Maturity</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">TCO report</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Anomaly Detection</span>
                                    </div>

                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px] text-center leading-3">
                                            Actuals & Amortized Spend
                                        </span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Azure / AWS / OCI</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded leading-3 text-center">
                                        <span className="text-[9px] md:text-[16px]">PaaS & SaaS Analytics</span>
                                    </div>

                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded leading-3 text-center">
                                        <span className="text-[9px] md:text-[16px]">Azure tagging cleanup</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px]">Customized Analytics</span>
                                    </div>
                                    <div className="bg-gray-300 xl:w-[80px] flex justify-center items-center h-[40px] p-1 rounded">
                                        <span className="text-[9px] md:text-[16px] text-center leading-3">
                                            Comparison modelling
                                            <br />
                                            (ad-hoc)
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Pricing;
