import { Button } from "primereact/button";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ForecastingBlog from "../assets/icons/ForecastingBlog.jpg";
import RightsizingBlog from "../assets/icons/RightsizingBlog.jpg";
import RightsizingOptimize from "../assets/icons/right_sizing.svg";
import Scalability from "../assets/icons/Scalability.jpg";
import Tooling from "../assets/icons/Tooling.jpg";
import CloudOperation from "../assets/icons/Cloud Opeartions.jpeg";
import CloudResourceTagging from "../assets/icons/Cloud Resource Tagging.jpeg";
import CloudCost from "../assets/icons/CloudCost.svg";
import AI from "../assets/icons/AI.svg";

const mappedCard = [
    {
        url: Tooling,
        imageStyle: "object-contain h-[35vh]",

        content: "Why is tooling crucial for FinOps in 2024?",
        subContent: "Cloud cost optimization hurdles demand crucial FinOps tooling for businesses in 2024.",
        label: "FinOps",
        date: "19",
        year: "Mar 2024",
        BlogUrl: "https://medium.com/@bi_51486/why-is-tooling-crucial-for-finops-in-2024-d8845deaff9c",
    },
    {
        url: ForecastingBlog,
        imageStyle: "object-contain h-[35vh]",

        content: "Forecasting Cloud Costs: A Crucial Step in Your FinOps Strategy",
        subContent:
            "Effective FinOps strategy in 2024 hinges on accurate forecasting of cloud costs, highlighting the indispensable role of robust tools for financial planning and optimization.",
        label: "FinOps",
        date: "24",
        year: "Mar 2024",
        BlogUrl:
            "https://medium.com/@bi_51486/forecasting-cloud-costs-a-crucial-step-in-your-finops-strategy-75917297f171?source=friends_link&sk=d4c570b238266a1e362f9efb3a6e882b",
    },
    {
        url: RightsizingBlog,
        imageStyle: "object-contain h-[35vh]",
        content: "Resource Tagging: A Never-ending Effort in Cloud Operations",
        subContent:
            "Resource tagging streamlines organization and tracking in cloud environments, enabling efficient cost allocation and security management.",
        label: "FinOps",
        date: "31",
        year: "Mar 2024",
        BlogUrl: "https://medium.com/@bi_51486/resource-tagging-a-never-ending-effort-in-cloud-operations-6d691d2aa5dc",
    },
    {
        url: Scalability,
        imageStyle: "object-contain h-[35vh]",
        content: "Scalability and Flexibility: How CloudOps Enables Business Growth ",
        subContent:
            "CloudOps enables business growth by enabling rapid scalability and innovation through the cloud's flexibility, optimizing costs and competitiveness.",
        label: "CloudOps",
        date: "06",
        year: "Apr 2024",
        BlogUrl:
            "https://medium.com/@bi_51486/scalability-and-flexibility-how-cloudops-enables-business-growth-4e4178d4c445",
    },
    {
        url: CloudOperation,
        imageStyle: "object-contain h-[35vh]",
        content: "The Evolution of Cloud Operations: Trends and Innovations ",
        subContent:
            "The evolution of cloud operations pivots around AI and automation, fostering more efficient and self-managing cloud environments.",
        label: "CloudOps",
        date: "21",
        year: "Apr 2024",
        BlogUrl:
            "https://medium.com/@bi_51486/the-evolution-of-cloud-operations-trends-and-innovations-43eb15921673",
    },
    {
        url: CloudResourceTagging,
        imageStyle: "object-contain h-[35vh]",
        content: "Aligning Cloud Spend to the Business: The Fastest Way to Contextualize your Cloud ",
        subContent:
            "CloudLens 'Build-Your-Own' module streamlines cloud management by enabling direct resource-to-application mapping, with optional tagging integration.",
        label: "Finops",
        date: "03",
        year: "May 2024",
        BlogUrl:
            "https://medium.com/@bi_51486/aligning-cloud-spends-to-the-business-the-fastest-way-to-contextualize-your-cloud-136d81de011d",
    },
    {
        url: RightsizingOptimize,
        imageStyle: "object-contain h-[35vh]",
        content: "Rightsizing in the Cloud: A Deep Dive into Optimizing Resource Capacity",
        subContent:
            "Optimize cloud resources for cost efficiency and peak performance through effective rightsizing techniques.",
        label: "Finops",
        date: "14",
        year: "June 2024",
        BlogUrl:
            "https://medium.com/@bi_51486/rightsizing-in-the-cloud-a-deep-dive-into-optimizing-resource-capacity-f713e1f50059",
    },
    {
        url: CloudCost,
        imageStyle: "object-contain h-[35vh]",
        content: "Cloud Cost Monitoring and Reporting: Key Metrics for FinOps Success",
        subContent:
            "Effective cloud cost management involves tracking key metrics for financial accountability and resource optimization, essential for FinOps success.",
        label: "Finops",
        date: "22",
        year: "July 2024",
        BlogUrl:
            "https://medium.com/@bi_51486/cloud-cost-monitoring-and-reporting-key-metrics-for-finops-success-bcd071aa4deb",
    },
    {
        url: AI,
        imageStyle: "object-contain h-[35vh]",
        content: "AI for Cloud FinOps: Real-Time Expense Management Solutions​",
        subContent:
            "Harness AI for Cloud FinOps: automate cost tracking, optimize spending, and make smarter financial decisions.",
        label: "Finops",
        date: "03",
        year: "Sep 2024",
        BlogUrl:
            "https://medium.com/@bi_51486/ai-for-cloud-finops-real-time-expense-management-solutions-47d05df0eda3",
    }
];
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};
const Blog = () => {
    return (
        <div id="blog" className=" w-full container mx-auto flex flex-col items-center">
            <header className="flex flex-col justify-center items-center md:max-lg:w-3/4">
                <div className="flex justify-center items-center mt-16">
                    <p className="text-[#14304A] lg:text-[35px] md:text-[35px] xl:text-[35px] text-[25px] py-5 text-center leading-10 font-medium">
                        Unlock Efficiency: FinOps Essentials
                        <span className="dot">.</span>
                    </p>
                </div>
                <div className="mb-10 flex flex-wrap w-full justify-center items-center">
                    <span className="text-[17px] mx-5 text-[#7791A5] font-normal lg:w-[900px] md:w-[900px] w-auto leading-[30px] text-center">
                        Welcome to our FinOps blog series! Discover key strategies to optimize cloud spending and drive
                        operational efficiency. Stay tuned for expert insights and practical tips to revolutionize your
                        cloud strategy!
                    </span>
                </div>
            </header>
            <Carousel responsive={responsive} className="!w-full !h-[800px]">
                {[...mappedCard].reverse().map((item, index) => {
                    return (
                        <div key={index} className="border rounded-md shadow-md flex  justify-center mx-5 flex-col">
                            <img className={item.imageStyle} src={item.url} alt="" />
                            <div className="!bg-white p-5 rouned-b-md  h-[400px] ">
                                <div className="h-[34vh] absolute top-[28px] flex-col flex justify-between">
                                    <div className="bg-blue-950 bg-opacity-70 rounded-md text-white justify-center items-center flex w-20 h-20 text-center">
                                        <div>
                                            <p className="text-white text-[18px]">{item.date}</p>
                                            <p className="text-white text-[11px]">{item.year}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            label={item.label}
                                            className="!bg-[#47B2E4] !text-white !text-sm rounded-full !w-[120px] !h-[30px] pointer-events-none"
                                        />
                                    </div>
                                </div>
                                <h3 className="mt-[30px] leading-[35px] mb-[15px] font-normal text-[20px] !h-[105px]">
                                    {item.content}
                                </h3>
                                <hr />
                                <p className="text-[#587790] font-normal text-[17px] my-4 leading-[30px] !h-[120px]">
                                    {item.subContent}
                                </p>

                                <div className="flex justify-end">
                                    <a
                                        href={item.BlogUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={`rounded-lg text-sm ${
                                            item.BlogUrl ? "btn-grad-blog" : "btn-grad-blog-disable pointer-events-none"
                                        }`}
                                    >
                                        Read Article
                                    </a>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default Blog;
