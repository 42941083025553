import React from "react";
import logoLens from "../assets/icons/logoLens.svg";
import {
  FaFacebookF,
  FaMedium,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa6";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import "../css/MobileFooter.css"
const shareCategory = [
  // { icon: <FaFacebookF className="w-5 h-5 text-white" />, color: "#3B5998" },
  // { icon: <FaTwitter className="w-5 h-5 text-white" />, color: "#458ACA" },
  // {
  //   icon: <BiLogoInstagramAlt className="w-5 h-5 text-white" />,
  //   color: "#464646",
  // },
  {
    icon: <FaYoutube className="w-5 h-5 text-white" />,
    color: "#CD2827",
    link: "https://www.youtube.com/@CloudLensFinOps",
  },
  {
    icon: <FaLinkedinIn className="w-5 h-5 text-white" />,
    color: "#3B77B5",
    link: "https://www.linkedin.com/company/cubesimple/",
  },
  {
    icon: <FaMedium className="w-5 h-5 text-white" />,
    color: "#000000",
    link: "https://medium.com/@bi_51486",
  },
];

const FooterColumn = ({ data, shareType }) => {
  return (
    <div className="flex justify-center">
      {data.map((team) => (
        <div key={team.header} className="flex flex-col">
          <h4 className="mb-5 text-white text-[17px] leading-[30px] font-semibold">
            {team.header}
          </h4>
          {team.subText && (
            <p className="text-[#C2CBD8] h-[40px] leading-[40px] text-[15px] opacity-[0.7]">
              {team.subText}
            </p>
          )}
          {team.subTextArray &&
            team.subTextArray.map((subText, index) => (
              <Link
                to={subText.link}
                key={index}
                onClick={() => window.scrollTo(0, 0)}
                className="text-[#C2CBD8] h-[40px] leading-[40px] text-[15px] opacity-[0.7]"
              >
                {subText.name}
              </Link>
            ))}
          {shareType?.length > 0 && (
            <div className="flex">
              {shareType.map((type, index) => (
                <a
                  href={type.link}
                  target="blank"
                  key={index}
                  style={{ backgroundColor: type.color }}
                  className={`w-[50px] h-[40px] flex items-center justify-center ${
                    index === 0 ? "rounded-l" : ""
                  } ${index === shareType.length - 1 ? "rounded-r" : ""}`}
                >
                  {type.icon}
                </a>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const Footer = () => {
  const teamContext = [
    {
      header: "Solutions",
      subText: "Why Change?",
      subTextArray: [
        { name: "Anamoly Detection", link: "/#" },
        { name: "Budgeting and Forecasting", link: "/#" },
        { name: "Demo", link: "/#" },
        { name: "Tour", link: "/#" },
        { name: "Shared Cost", link: "/#" },
      ],
    },
  ];
  const helpContext = [
    {
      header: "Help and support",
      subText: "Help centre",
      subTextArray: [
        { name: "Feedbacks", link: "/#" },
        { name: "Request new features", link: "/#" },
        { name: "Contact us", link: "/#contact" },
      ],
    },
  ];
  const learnContext = [
    {
      header: "Resources",
      subTextArray: [
        { name: "Blog", link: "/#blog" },
        { name: "FAQ", link: "/#" },
        { name: "Pricing", link: "/#pricing" },
      ],
    },
  ];
  const followContext = [
    {
      header: "Follow us",
    },
  ];

  return (
    <section id="footer">
      <div className="bg-primary_theme contact__content__bal">
        <div className="container mx-auto">
          <div className="py-[80px] px-[30px]">
            <div className="flex justify-between items-start gap-0 mobile__footer__head">
              <FooterColumn data={teamContext} />
              <FooterColumn data={helpContext} />
              <FooterColumn data={learnContext} />
              <FooterColumn data={followContext} shareType={shareCategory} />
            </div>
          </div>
        </div>
        <div className="border-t-[0.5px] b-5 border-[#C2CBD8] border-solid">
          <div className="container mx-auto">
            <div className="py-[15px] px-[30px] mobile__footer__copyright">
              <div className="flex justify-between items-center">
                <div className="">
                  <h6 className="text-[#C2CBD8] text-[14px] leading-[0.5px]">
                    Copyright © 2024.
                  </h6>
                </div>
                <div className="divide-x divide-white text-[14px] text-white mobile__footer__logo">
                  <span>
                    <Link to="/terms_and_conditions" onClick={() => window.scrollTo(0, 0)}>Terms of Use</Link>
                    &nbsp;&nbsp;
                  </span>
                  <span>&nbsp;&nbsp;<Link to="/privacy_policy" onClick={() => window.scrollTo(0, 0)}>Privacy Policy</Link>&nbsp;&nbsp;</span>
                  <span>&nbsp;&nbsp;<Link to="/security_policy" onClick={() => window.scrollTo(0, 0)}>Security</Link></span>
                </div>
                <div className="flex flex-shrink-0 items-center mobile__footer__logo">
                  <img className="h-7 w-auto" src={logoLens} alt="CloudLens" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
