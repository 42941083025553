import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { isValidEmail } from "../helpers/helpers";
import PhoneInput from "react-phone-number-input";
import "../Components/Take_A_Tour/Common/phoneNumber.css";
// import 'primeicons/primeicons.css';
function ContactModalForm({ visible, setVisible }) {
  const [form, setForm] = useState({});
  const toast = useRef(null);

  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  // Generate a random string for the CAPTCHA
  const generateCaptcha = () => {
    const captchaChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += captchaChars.charAt(
        Math.floor(Math.random() * captchaChars.length)
      );
    }
    setCaptchaText(captcha);
  };

  const openModal = () => {
    if (!isValidEmail(form.email)) {
      showError("Please enter valid mail!");
      return;
    }

    if(form.phone.length>13){
      showError("Please enter valid phone number!");
      return;
    }

    if (
      !form.name ||
      !form.organization ||
      !form.email ||
      !form.phone ||
      !form.message
    ) {
      showWarn();
      return;
    }
    setIsModalOpen(true);
    generateCaptcha(); // Generate CAPTCHA when modal opens
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUserInput(""); // Clear user input when modal closes
  };

  const verifyCaptcha = () => {
    if (userInput === captchaText) {
      setIsCaptchaVerified(true);
      // alert('CAPTCHA Verified!');
      handleSubmit();
      closeModal();
    } else {
      setIsCaptchaVerified(false);
      alert("Incorrect CAPTCHA. Please try again.");
      generateCaptcha(); // Regenerate CAPTCHA on failure
    }
  };


  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Message Content",
      life: 3000,
    });
  };

  const showError = (msg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const showWarn = () => {
    toast.current.show({
      severity: "warn",
      summary: "Warn",
      detail: "Please fill all required fields",
    });
  };

  const handleChange = (label, e) => {
    console.log(label, "-----", e);
    setForm({
      ...form,
      [label]: label === "phone" ? e : e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (!isValidEmail(form.email)) {
      showError("Please enter valid mail!");
      return;
    }

    if (form.phone?.length > 13) {
      showError("Please enter valid phone number!");
      return;
    }

    if (
      !form.name ||
      !form.organization ||
      !form.email ||
      !form.phone ||
      !form.message
    ) {
      showWarn();
      return;
    }
    setVisible(false)
    try {
      const response = await fetch("https://122.165.79.72:5000/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });

      if (!response.ok) {
        showError("Something went wrong!");
        throw new Error("Failed to submit form");
      } else {
        showSuccess();
        setForm({});
        console.log(response, "successfull send");
      }
    } catch (error) {
      showError();

      // Handle errors here
      console.error("Error submitting form:", error.message);
      // Optionally, you can show an error message to the user
    }
  };

  return (
    <div>
      <Toast ref={toast} />

      <Dialog
        header="Contact Form"
        visible={visible}
        style={{ width: "400px" }}
        onHide={() => setVisible(false)}
        resizable={false}
        draggable={false}
        dismissableMask
      >
        <div className="p-fluid">
          <div className="p-field my-1">
            <label htmlFor="name">Name *</label>
            <InputText
              id="name"
              value={form.name || ""}
              onChange={(e) => handleChange("name", e)}
            />
          </div>
          <div className="p-field my-1">
            <label htmlFor="organization">Organization *</label>
            <InputText
              id="organization"
              value={form.organization || ""}
              onChange={(e) => handleChange("organization", e)}
            />
          </div>
          <div className="p-field my-1">
            <label htmlFor="phone">Phone *</label>
            {/* <InputText
              id="number"
              value={form.phone || ""}
              onChange={(e) => handleChange("phone", e)}
            /> */}
            <PhoneInput
              international
              defaultCountry="US"
              value={form.phone || ""}
              onChange={(e) => handleChange("phone", e)}
            />
          </div>
          <div className="p-field my-1">
            <label htmlFor="email">Email *</label>
            <InputText
              id="email"
              value={form.email || ""}
              onChange={(e) => handleChange("email", e)}
            />
          </div>
          <div className="my-1">
            <label htmlFor="message">Message *</label>
            <InputTextarea
              className="textarea__content"
              id="message"
              value={form.message || ""}
              onChange={(e) => handleChange("message", e)}
              rows={3}
              cols={30}
            />
          </div>
        </div>
        <div className="flex flex-row gap-3 justify-end mt-5">
          <Button
            className="btn-grad-blue !m-0"
            label="Submit"
            onClick={openModal}
          />
          <Button
            label="Cancel"
            onClick={() => setVisible(false)}
            className="btn-grad-red !m-0"
          />
        </div>
      </Dialog>

      {isModalOpen && (
        <Dialog
          header="Please enter the characters shown below:"
          visible={isModalOpen}
          style={{ width: "500px" }}
          onHide={closeModal}
          resizable={false}
          draggable={false}
          dismissableMask
        >
          <div className="text-center">
            <div className="p-field my-1 flex justify-center items-center">
              {captchaText && (
                <div className="flex justify-center items-center bg-[#003971] h-[40px] w-[100px] text-white rounded-sm">
                  {" "}
                  {captchaText}
                </div>
              )}
              &nbsp;
              <InputText
                type="text"
                className="border"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Enter CAPTCHA"
              />
            </div>
            <button className="btn-grad-blue" onClick={verifyCaptcha}>
              Verify
            </button>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default ContactModalForm;
