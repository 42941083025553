import { useState } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { GrUserAdmin } from "react-icons/gr";
import { GiPaintBrush, GiMagnifyingGlass } from "react-icons/gi";
import { FaEye } from "react-icons/fa";
import CubeBreadCrumb from "../Common/cubeBreadCrumb";
import { Tooltip } from "primereact/tooltip";

const items = [{ label: "Roles and Access" }];
const initialUserState = {
  flag: false,
  data: null,
  type: null,
};
const initialDeleteState = {
  flag: false,
  data: null,
  header:
    "Are you sure want to delete this data? All of this selected data will be deleted. This action cannot be undone",
  subHeader: "",
  name: "",
};

function RolesAndPermissions() {
  const [userStatus, setUserStatus] = useState(initialUserState);
  const [deleteStatus, setDeleteStatus] = useState(initialDeleteState);
  const permission = {
    read: 1,
    update: 1,
  };
  const rolesList = [
    {
      id: 1,
      role_name: "Admin",
      role_slug: "admin",
      role_image: null,
      role_description: "Control all users and modules with global access",
      created_by: "2",
      created_at: "2024-01-31T20:10:18.990000Z",
      updated_at: "2024-01-31T20:10:23.913000Z",
    },
    {
      id: 4,
      role_name: "Creator",
      role_slug: "creator",
      role_image: null,
      role_description:
        "Control all modules with complete create/edit/delete actions",
      created_by: "2",
      created_at: "2024-01-31T20:10:18.990000Z",
      updated_at: "2024-01-31T20:10:23.913000Z",
    },
    {
      id: 2,
      role_name: "Explorer",
      role_slug: "explorer",
      role_image: null,
      role_description:
        "Contribute to specific modules in the creation and edition of data",
      created_by: "2",
      created_at: "2024-01-31T20:10:18.990000Z",
      updated_at: "2024-01-31T20:10:23.913000Z",
    },
    {
      id: 3,
      role_name: "Reader",
      role_slug: "reader",
      role_image: null,
      role_description: "View all reports created by other users",
      created_by: "2",
      created_at: "2024-01-31T20:10:18.990000Z",
      updated_at: "2024-01-31T20:10:23.913000Z",
    },
  ];

  const openUserModal = (data) => {
    const formData =
      data && Object.keys(data).length
        ? { ...data, lastName: data.last_name }
        : data;
    setUserStatus({
      flag: true,
      data: formData,
    });
  };

  const handleCloseDialog = () => {
    setDeleteStatus(initialDeleteState);
  };
  const handleHide = () => {
    setUserStatus(initialUserState);
  };

  const handleEdit = (editdata) => {
    setUserStatus({
      flag: true,
      data: editdata,
      type: "edit",
    });
  };

  const handleView = (viewdata) => {
    setUserStatus({
      flag: true,
      data: viewdata,
      type: "view",
    });
  };

  const footer = (e) => {
    return (
      <span className="pt-2">
        <Tooltip
          target=".roles"
          content="Preview unavailable - request demo"
          position="bottom"
        />
        <Button
          label="View"
          icon="pi pi-eye"
          className="btn_primary text-white h-[35px] pl-2 pr-4 !font-bold roles"
          onClick={() => handleView(e)}
        />
        <Button
          label="Edit"
          severity="secondary"
          className="btn_grey  text-white h-[35px] h-10 pl-2 pr-4 !font-bold roles"
          icon="pi pi-user-edit"
          style={{ marginLeft: "0.5em" }}
          onClick={() => handleEdit(e)}
        />
      </span>
    );
  };

  const header = (e) => {
    return (
      <span className="flex gap-2 items-center pt-3 pl-3 pb-0 pr-3">
        {e.role_name === "Admin" && (
          <span className=" h-4 w-4">
            <GrUserAdmin />
          </span>
        )}
        {e.role_name === "Creator" && (
          <span className=" h-4 w-4">
            <GiPaintBrush />
          </span>
        )}
        {e.role_name === "Explorer" && (
          <span className=" h-4 w-4">
            <GiMagnifyingGlass />
          </span>
        )}
        {e.role_name === "Reader" && (
          <span className=" h-4 w-4">
            <FaEye />
          </span>
        )}

        <h1 className="font-extrabold text-xl">{e.role_name}</h1>
      </span>
    );
  };
  return (
    <main>
      <nav>
        <CubeBreadCrumb items={items} />
      </nav>

      <header className="w-full flex-wrap md:flex md:justify-between items-center mt-0">
        <Button
          label="Add User Roles"
          icon="pi pi-plus"
          iconPos="right"
          className="btn_primary !text-xs !hidden"
          onClick={() => openUserModal(null)}
        />
      </header>
      <section className="mt-3">
        <div className="flex justify-between gap-3 columns-4">
          {rolesList.map((e) => (
            <Card
              key={`card-${e.role_name}`}
              header={header(e)}
              className="rolesCard w-1/4"
              footer={footer(e)}
            >
              <p className="min-h-[72px]">{e.role_description}</p>
            </Card>
          ))}
        </div>
      </section>
    </main>
  );
}

export default RolesAndPermissions;
