import { Dialog } from "primereact/dialog";
import FinopsHealth from "./FinopsHealth";
import TATHeader from "./TATHeader";
import TATLayout from "./TATLayout";




function Take_A_Tour({ visible, setVisible }) {
  return (
    <Dialog
      header="Take a Tour"
      visible={visible}
      style={{ width: "75%", height: "100%" }}
      onHide={() => setVisible(false)}
      draggable={false}
      resizable={false}
      className="bg-red"
      blockScroll
    >
      <TATLayout key={FinopsHealth} />
    </Dialog>
  );
}

export default Take_A_Tour;
