/* eslint-disable */
import React, { useState } from "react";
import { HiChevronDoubleLeft } from "react-icons/hi2";
import { Tooltip } from "primereact/tooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";

const labelFormatter = (str) => {
  return str.replace(/-/g, " ");
};

function TATSideBar({
  getSelectedPanel,
  sideNavs,
  isMobileView,
  handleHoveredPanel,
  activePanel,
}) {
  const [expandState, setExpandState] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleExpand = (key) => {
    setExpandState((prev) => (prev === key ? null : key));
  };

  const handleClose = () => {
    setExpandState(null);
  };

  const handleAction = (e, path, label, shouldNavigate = true) => {
    e.stopPropagation();
    if (shouldNavigate) {
      navigate(path);
      handleExpand(null);
    }
  };

  return (
    <aside className={` ${isMobileView ? "!hidden" : ""}`}>
      <section className="w-[70px] min-h-full  bg-[#F9F9Fb] flex flex-col items-center z-[5] shadow-lg border-r">
        {sideNavs.map((doc, docIndex) => {
          const { expandMode, label: navLabel, icon, path } = doc;
          const label =
            navLabel && typeof navLabel === "string"
              ? labelFormatter(navLabel)
              : "";
          const isDivider =
            label === "ROI Review" ||
            label === "PaaS Analytics" ||
            label === "Anomaly Detection";
          const selectedNavClassName =
            (pathname === path && !expandState) ||
            expandState === docIndex ||
            (expandMode &&
              doc.additionalRoute
                .map((route) =>
                  route.childRoute
                    ? [route.path, ...route.childRoute]
                    : route.path
                )
                .flat(1)
                .includes(pathname))
              ? "text-gray-600"
              : "  text-gray-600 ";
          const uniqueClass = `${label}-${docIndex}`;

          if (expandMode) {
            return (
              <div
                className={`w-full hover:bg-sky-100 h-[65px] hover:cursor-pointer ${
                  isDivider && "border-gray-400 border-b-2 border-dashed"
                }`}
                key={docIndex}
                onMouseEnter={() => handleHoveredPanel(label)}
                onMouseLeave={() => handleHoveredPanel(null)}
              >
                <Tooltip
                  target=".comingsoon"
                  position="top"
                  content="Coming soon"
                />
                <nav
                  className={`${
                    label === activePanel && "bg-gray-300 font-normal "
                  }  flex items-center flex-col justify-center p-2 text-[#000000]  ${
                    label === "Tag Governance"
                      ? ""
                      : label === "PaaS Analytics"
                      ? ""
                      : "comingsoon"
                  }`}
                  key={`nav-${docIndex + 1}`}
                  // onClick={() => handleExpand(docIndex)}
                  onClick={() => getSelectedPanel(doc.label)}
                >
                  {icon}
                  <label className="text-[10px] text-center hover:cursor-pointer capitalize">
                    {label}
                  </label>
                </nav>
              </div>
            );
          } else {
            return (
              <div
                className={`w-full hover:bg-sky-100 h-[65px] hover:cursor-pointer ${
                  isDivider && "border-gray-400 border-b-2 border-dashed"
                }`}
                // onClick={(e) => handleAction(e, doc.path, doc.label, false)}
                onClick={
                  doc.label === "FinOps Health" ||
                  doc.label === "Alerts" ||
                  doc.label === "Admin Center" ||
                  doc.label === "Custom Reports" ||
                  doc.label === "ROI Review" ||
                  doc.label === "Build Your Own" ||
                  doc.label === "Forecast Model" ||
                  doc.label === "Anomaly Detection"
                    ? () => getSelectedPanel(doc.label)
                    : () => getSelectedPanel("")
                }
                key={docIndex}
                onMouseEnter={() => handleHoveredPanel(label)}
                onMouseLeave={() => handleHoveredPanel(null)}
              >
                <Tooltip
                  target=".unavailable"
                  position="top"
                  content="Preview unavailable - request demo"
                />
                <Link
                  to={path}
                  key={docIndex}
                  onClick={handleClose}
                  className={uniqueClass}
                >
                  <nav
                    className={` ${
                      label === activePanel && "bg-gray-300 font-normal "
                    } h-[65px]  flex items-center flex-col justify-center text-[#000000] p-2 ${
                      label === "FinOps Health" ||
                      label === "Custom Reports" ||
                      label === "Alerts" ||
                      label === "Admin Center" ||
                      label === "Forecast Model" ||
                      label === "Anomaly Detection"
                        ? ""
                        : label === "Build Your Own" || label === "ROI Review"
                        ? ""
                        : "comingsoon"
                    }`}
                    key={`nav-${docIndex + 1}`}
                  >
                    {icon}

                    <label className="text-[10px] text-center hover:cursor-pointer capitalize">
                      {label}
                    </label>
                  </nav>
                </Link>
              </div>
            );
          }
        })}
      </section>
      {/* <section
        className={`layout_container_aside_section_two ${expandState ? 'expand' : 'collapse'}`}
        onClick={handleClose}>
        <section className="expandSection shadow transition-all ease-in duration-100  w-52 h-full bg-gray-50">
          <div className="text-center pt-4 pb-[0.5rem] px-1">
            <h2 className="m-0 text-lg text-secondary font-medium pl-5 capitalize w-[90%] text-start">
              {expandState && sideNavs[expandState] && sideNavs[expandState].title
                ? labelFormatter(sideNavs[expandState].title)
                : null}
            </h2>
            <div
              className="absolute right-[0px] top-[4px]  w-12 p-4 hover:cursor-pointer"
              onClick={handleClose}>
              <HiChevronDoubleLeft className="text-secondary text-xl font-bold" />
            </div>
          </div>
          {expandState &&
            sideNavs[expandState] &&
            sideNavs[expandState].additionalRoute &&
            sideNavs[expandState].additionalRoute.map((doc, docIndex) => {
              const selectedNavClassName =
                (doc.childRoute && doc.childRoute.includes(pathname)) || pathname === doc.path
                  ? 'flex items-center px-2 py-2 text-secondary hover:text-gray-600 text-sm bg-gray-200 cursor-pointer border-r-4 border-orange-400'
                  : 'flex items-center px-2 py-2 text-gray-600 hover:text-secondary text-sm hover:bg-gray-200 cursor-pointer hover:border-r-4 hover:border-orange-400';
              return (
                <div
                  className={selectedNavClassName}
                  key={docIndex}
                  onClick={(e) => handleAction(e, doc.path, doc.label)}>
                  <Tooltip target=".nav_label_tool" className="custom_tooltip" />
                  {doc.icon}
                  <p
                    className="pl-3 truncate nav_label_tool"
                    data-pr-tooltip={doc.label}
                    data-pr-position="right">
                    {doc.label}
                  </p>
                </div>
              );
            })}
        </section>
      </section> */}
    </aside>
  );
}

export default TATSideBar;
