/* eslint no-nested-ternary: "off" */
import { useState, useEffect } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { Button } from "primereact/button";
import { AiOutlineCloseCircle } from "react-icons/ai";
import _ from "lodash";
import CubeBreadCrumb from "../Common/cubeBreadCrumb";
import CubeTable from "../TagGovComponents/cubeTable";
import { filtrateColumn } from "../../../helpers/helpers";
import { Tooltip } from "primereact/tooltip";

const items = [{ label: "Groups" }];
const initialUserData = {
  flag: false,
  data: null,
};
const initialDeleteState = {
  flag: false,
  header:
    "Are you sure want to delete this data? All of this selected data will be deleted. This action cannot be undone.",
  rowData: {},
};
const columns = [
  { field: "group_name", label: "Name", type: "info" },
  // { field: 'description', label: 'Short Description' },
  { field: "admin", label: "Owner" },
  { field: "users_count", label: "Members" },
  { field: "created_at", label: "Created" },
];
function UserGroups() {
  const permissions = {
    create: 1,
    read: 1,
    update: 1,
    delete: 1,
    edit: 1,
  };
  const [show, setShow] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(initialDeleteState);
  const [userData, setUserData] = useState(initialUserData);
  const [searchParam, setSearchParam] = useState("");
  const [updatedRowData, setUpdatedRowData] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [tableColumn, setTableColumn] = useState(columns);
  //   const {
  //     data: getAllUserGrp = [],
  //     refetch,
  //     isLoading: isGroupsLoading,
  //     isError: isGroupsError,
  //     isFetching: isGroupsFetching,
  //   } = useGetUserGroupQuery();
  //   const [getUserList, { data: groupListInfo, isFetching, isError }] =
  //     useLazyGetUserGroupListQuery();
  //   const [deleteGroup] = useDeleteGroupMutation();
  //   const localUserData = getLocalStroageData(localConstant.USER);
  //   const userDetails =
  //     localUserData &&
  //     typeof localUserData === "string" &&
  //     Object.keys(JSON.parse(localUserData)).length > 0 &&
  //     JSON.parse(localUserData);
  const openUserModal = (data) => {
    setShow(true);
    setUserData({
      flag: true,
      data,
      type: "create",
    });
  };

  const handleCloseDiaglog = () => {
    setDeleteStatus(initialDeleteState);
  };

  const deleteSelectedGrp = (selectedId) => {
    // deleteGroup(selectedId)
    //   .unwrap()
    //   .then((resp) => {
    //     refetch();
    //     handleCloseDiaglog();
    //     dispatch(
    //       showToast({
    //         mode: "success",
    //         heading: "Deleted!",
    //         message:
    //           resp && resp.message
    //             ? resp.message
    //             : "User group deleted successfully",
    //       })
    //     );
    //   });
  };

  const handleReset = () => {
    setShow(false);
    setUserData(initialUserData);
  };

  const handleGrpInfo = (rowInfo) => {
    setShowInfoModal(true);
    // getUserList(rowInfo.id);
  };

  const onHide = () => {
    setShowInfoModal(!showInfoModal);
    setSortedColumn(null);
  };

  const openDialogBox = (rowData) => {
    setDeleteStatus((prev) => {
      return {
        ...prev,
        flag: true,
        rowData: rowData.id,
      };
    });
  };

  const getLoaderMsg = () => {
    return (
      <tr className="w-full">
        <td
          className="px-4 py-2 text-center text-purple-500 text-sm !rounded-br-md"
          colSpan="2"
        >
          <div className="h-[240px] w-full items-center flex justify-center ">
            Loading...
          </div>
        </td>
      </tr>
    );
  };

  const getErrorMsg = () => {
    return (
      <tr className="w-full">
        <td
          className="px-4 py-2 text-center text-purple-500 text-sm !rounded-br-md"
          colSpan="2"
        >
          <div className="h-[240px] w-full items-center flex justify-center ">
            Something went wrong
          </div>
        </td>
      </tr>
    );
  };

  const getStatus = () => {
    let status = "";
    // if (!isError && isFetching) {
    //   status = getLoaderMsg();
    // } else {
    //   status = getErrorMsg();
    // }
    return status;
  };

  const handleEdit = (editdata) => {
    setShow(true);
    setUserData({
      flag: true,
      data: editdata,
      type: "edit",
    });
  };

  const onSearchChange = (e) => {
    setSearchParam(e.target.value);
  };

  const setRestriction = (adminId) => {
    // return adminId !== userDetails?.id;
  };

  //   const updateRowData = () => {
  //     const updatedRow = getAllUserGrp.data.map((doc) => ({
  //       ...doc,
  //       isInfoMode: true,
  //       isRestriction: false,
  //     }));
  //     setUpdatedRowData(updatedRow);
  //   };

  //   useEffect(() => {
  //     // if (
  //     //   getAllUserGrp &&
  //     //   getAllUserGrp.data &&
  //     //   Array.isArray(getAllUserGrp.data)
  //     // ) {
  //     //   updateRowData();
  //     // }
  //   }, []);

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortDirection("asc");
    }
  };

  //   const sortedUsers = _.orderBy(
  //     groupListInfo?.data?.users,
  //     [sortedColumn],
  //     [sortDirection]
  //   );

  const renderHeader = () => {
    return (
      <div className="flex justify-between w-full">
        <span>Group Members</span>
        <div role="button" className="cursor-pointer">
          <AiOutlineCloseCircle
            className="w-[25px] h-[25px] text-gray-400 hover:text-secondary"
            onClick={onHide}
          />
        </div>
      </div>
    );
  };

  const updateTableColumn = () => {
    const actionColumn = { field: "admin", label: "Actions", type: "" };
    actionColumn.type =
      Boolean(permissions.edit) && Boolean(permissions.delete)
        ? "edit_action"
        : Boolean(permissions.edit)
        ? "edit_only"
        : Boolean(permissions.delete)
        ? "delete_only"
        : null;
    setTableColumn((prev) => {
      return filtrateColumn(prev, actionColumn);
    });
  };

  useEffect(() => {
    if (
      permissions &&
      (Boolean(permissions.edit) || Boolean(permissions.delete))
    ) {
      updateTableColumn();
    }
  }, [permissions]);

  return (
    <main>
      <nav>
        <CubeBreadCrumb items={items} />
      </nav>

      <nav></nav>
      <header className="w-full flex-wrap md:flex md:justify-between items-center mt-3">
        <div className="relative flex w-42 md:w-80">
          <input
            type="text"
            placeholder="Search"
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:outline-none focus:ring-secondary focus:border-secondary block w-full pl-2 px-2 py-1.5"
            onChange={onSearchChange}
          />
          <div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className="h-5 w-5 text-gray-500 justify-end" />
          </div>
        </div>
        <Tooltip
          target=".createGroup"
          content="Preview unavailable - request demo"
          position="bottom"
        />
        <Button
          label="Create Group"
          icon="pi pi-plus"
          iconPos="left"
          className="btn_primary !px-4 !py-1 !h-[35px] !text-xs !font-bold text-white createGroup"
          onClick={() => openUserModal(null)}
        />
      </header>
      <section className="flex w-full h-full flex-wrap ">
        <div className="py-2 w-full">
          <CubeTable
            rowData={[
              {
                id: 7,
                group_name: "Group_1",
                group_slug: "group-test",
                group_icon: "#b93617",
                created_by: "18",
                created_at: "2/12/23",
                updated_at: "2/12/23",
                deleted_at: null,
                description: "ET",
                admin: "JD",
                users_count: 2,
                isInfoMode: true,
                isRestriction: false,
              },
              {
                id: 7,
                group_name: "Group_2",
                group_slug: "group-test",
                group_icon: "#b93617",
                created_by: "18",
                created_at: "9/12/23",
                updated_at: "9/12/23",
                deleted_at: null,
                description: "ET",
                admin: "EP",
                users_count: 2,
                isInfoMode: true,
                isRestriction: false,
              }
            ]}
            disableHeader
            disableDownload
            columnData={tableColumn}
            customSearchInput={searchParam}
            handleDelete={openDialogBox}
            handleEdit={handleEdit}
            rowSize={5}
            isInfoMode
            flag
            handleInfo={handleGrpInfo}
            adminCenter
          />
        </div>
      </section>
    </main>
  );
}

export default UserGroups;
