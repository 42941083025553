/* eslint-disable */
import Cards from "./TrackerCard";
// import CardLoader from "../../components/common/CardLoader";
// import localConstant from "../../constant/localConstant";
// import { getLocalStroageData } from "../../helpers/LocalHelper";

const cloudPartner = "azure";

function AppViewCard({ isFetching }) {
  const data = {
    // totalSubscription: 22,
    // newSubscription: 22,
    totalMeterCategory: 50,
    newMeterCategory: 15,
    resourceGroupTotal: '150',
    totalResource: 4941,
    newResource: '12,000',
    totalCost: '350,000',
    diffCost: 985128,
  };
  const cardItemsMap = {
    aws: [
      {
        icon: "fa-comments",
        amount: data?.totalCost,
        label: "Total Cost",
        subAmt: data?.diffCost,
      },
      {
        icon: "fa-users",
        amount: data?.newAccountsCount,
        label: "Accounts",
        subAmt: data?.newAccountsCount,
      },
      {
        icon: "fa-chart-line",
        amount: data?.newProductCount,
        label: "Products",
        subAmt: data?.newProductCount,
      },
      {
        icon: "fa-tasks",
        amount: data?.newResource,
        label: "Resources",
        subAmt: data?.newResource,
      },
    ],
    oracle: [
      {
        icon: "fa-comments",
        amount: data?.totalCost,
        label: "Total Cost",
        subAmt: data?.diffCost,
      },
      {
        icon: "fa-users",
        amount: data?.newSubscription,
        label: "Subscriptions",
        subAmt: data?.newSubscription,
      },
      {
        icon: "fa-chart-line",
        amount: data?.newMeterCategory,
        label: "Products",
        subAmt: data?.newMeterCategory,
      },
      {
        icon: "fa-tasks",
        amount: data?.newResource,
        label: "Resources",
        subAmt: data?.newResource,
      },
    ],
    default: [
      {
        icon: "fa-comments",
        amount: data?.totalCost,
        label: "Total Cost",
        subAmt: data?.diffCost,
      },
      // {
      //   icon: "fa-users",
      //   amount: data?.newSubscription,
      //   label: "Subscriptions",
      //   subAmt: data?.newSubscription,
      // },
      {
        icon: "fa-chart-line",
        amount: data?.newMeterCategory,
        label: "Meter Categories",
        subAmt: data?.newMeterCategory,
      },
      {
        icon: "fa-chart-line",
        amount: data?.resourceGroupTotal,
        label: "Resource Groups",
        subAmt: data?.resourceGroupTotal,
      },
      {
        icon: "fa-tasks",
        amount: data?.newResource,
        label: "Resources",
        subAmt: data?.newResource,
      },
    ],
  };
  const cardItems = cardItemsMap[cloudPartner] || cardItemsMap.default;
  return (
    <div
      className={`grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-${
        cloudPartner === "aws" || cloudPartner === "oracle" ? "4" : "4"
      }  gap-2 rounded xl:bg-white xl:gap-0 lg:bg-white lg:gap-0 mb-3 `}
    >
      {data && !isFetching
        ? cardItems.map((item, itemIndex) => {
            const uniqueId = `card-${itemIndex}`;
            return (
              <div className="py-2" key={uniqueId}>
                <Cards item={item} index={itemIndex} cardItems={cardItems} />
              </div>
            );
          })
        : cardItems.map((item, itemIndex) => {
            const uniqueId = `card-${itemIndex}`;
            return (
              <div className="py-2" key={uniqueId}>
                {/* <CardLoader /> */}
              </div>
            );
          })}
    </div>
  );
}

export default AppViewCard;
