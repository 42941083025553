import { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";

function TreeBreadCrumb({ data, onClick, maxVal }) {
  if (!data || !Array.isArray(data)) {
    return null;
  }
//   const [hoverState, setHoverState] = useState("");
//   const [hoverIndex, setHoverindex] = useState(0);
  const clampedMaxVal = Math.max(maxVal, 0);

  const handleMouseEnter = (index) => {
    // setHoverindex(index);
    // setHoverState("text-decoration-line: line-through");
  };

  const handleMouseLeave = () => {
    // setHoverState("");
  };

  return (
    <div className="flex w-fit items-center sm:text-[100px] md:flex-wrap">
      {data.map((doc, docIndex) => {
        const isFirst = docIndex === 0;
        const uniquekey = `label-${docIndex}`;
        return (
          <div key={uniquekey} className="flex items-center">
            {!isFirst && (
              <span
                aria-hidden
                className="mx-1 p-1 cursor-pointer w-[20px] h-[20px] rounded-full hover:bg-gray-200 flex justify-center items-center"
                onClick={() =>
                  typeof onClick === "function" && onClick(docIndex)
                }
                onMouseEnter={() => handleMouseEnter(docIndex)}
                onMouseLeave={handleMouseLeave}
              >
                <MdArrowForwardIos
                  className={`${
                    (clampedMaxVal !== null && docIndex <= clampedMaxVal) ||
                    (docIndex === 0 && clampedMaxVal === 0)
                      ? "text-secondary"
                      : "text-gray-400"
                  }`}
                />
              </span>
            )}
            <div className="flex items-center py-2 whitespace-nowrap">
              <div
                className={`${
                  (clampedMaxVal !== null && docIndex <= clampedMaxVal) ||
                  (docIndex === 0 && clampedMaxVal === 0)
                    ? `${"text-secondary font-semibold text-[12px] "}` +
                      `${docIndex > 0 && docIndex }`
                    : `${"text-gray-400 text-[12px] "}`
                }`}
              >
                {doc.label}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TreeBreadCrumb;
