/* eslint-disable react/jsx-props-no-spreading */
function Select({ value, onChange, options, ...rest }) {
    return (
      <select
        value={value}
        onChange={onChange}
        {...rest}
        className="w-full h-10 rounded-md border border-white-200 bg-white-100 py-2 !pr-9 truncate pl-2 mr-4 text-xs font-medium text-gray-600 focus:outline-none focus:border-secondary appearance-none primary-select">
        {options &&
          options.length &&
          options.map((opt, optIndex) => {
            const uniqueIndex = `${opt.value}-${optIndex}`;
            return (
              <option key={uniqueIndex} value={opt.value}>
                {opt.label}
              </option>
            );
          })}
      </select>
    );
  }
  
  export default Select;
  