/* eslint no-param-reassign:0 */
/* eslint-disable */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import moment from "moment";
import CubeTable from "./cubeTable";
import cumulativeDataTagGov from "../Jsonfiles/cumulativeDataTagGov.json";
import CubeBreadCrumb from "../Common/cubeBreadCrumb";
import { Button } from "primereact/button";
import { GoTriangleDown } from "react-icons/go";
import { Tooltip } from "primereact/tooltip";
import { getDateRange } from "../../../helpers/helpers";
import { Dropdown } from "primereact/dropdown";

const defaultColumn = [
  {
    label: "Name",
    field: "Key",
    width: "200px",
    frozen: true,
    filterType: "string",
    groupLabel: "Total Resources",
    groupType: "header",
    headerType: "search",
    headerClassName: "search_header frozenTableHeader pointer",
  },
];
const items = [{ label: "Tag Governance" }, { label: "Tag Key Auditor" }];

const countCol = {
  field: "",
  label: "",
  type: "count",
  sortable: true,
  width: "200px",
  filterType: "number",
  groupLabel: "",
  groupType: "number",
};

const firstMonth = getDateRange(-1).detailedObj;
const secondMonth = getDateRange(-2).detailedObj;
const thirdMonth = getDateRange(-3).detailedObj;
const lastMonth = getDateRange(-4).detailedObj;

const TagAuditTable = forwardRef(
  (
    {
      selectedNodeKeys = [
        {
          label: `${lastMonth.monthShort} ${lastMonth.year}`,
          field: "2023-october",
        },
        {
          label: `${thirdMonth.monthShort} ${thirdMonth.year}`,
          field: "2023-november",
        },
        {
          label: `${secondMonth.monthShort} ${secondMonth.year}`,
          field: "2023-december",
        },
        {
          label: `${firstMonth.monthShort} ${firstMonth.year}`,
          field: "2024-january",
        },
      ],
      data = cumulativeDataTagGov,
      isFetching,
      isLoading,
      isError,
      handleRequiredChange,
      handleSave,
      changed,
    },
    ref
  ) => {
    const [columnData, setColumnData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const updateTableColumn = () => {
      if (selectedNodeKeys && selectedNodeKeys.length) {
        const costFieldKeys = selectedNodeKeys;
        const countColumns = costFieldKeys.map((doc) => {
          const countColumn = { ...countCol };
          countColumn.field = doc.field;
          countColumn.label = doc.label;
          countColumn.groupLabel =
            data && data.total && data.total[doc.field]
              ? data.total[doc.field]
              : 0;
          return countColumn;
        });
        setTableData(data?.rows);
        setColumnData(defaultColumn.concat(countColumns));
      }
    };

    const handleSelectAll = (isChecked) => {
      setTableData((prev) => prev.map((doc) => ({ ...doc, isChecked })));
    };

    const handleSingleSelect = (isChecked, row) => {
      const rowId = row.row_id;
      setTableData((prev) => {
        const prevState = [...prev];
        const index = prevState.findIndex((doc) => doc.row_id === rowId);
        if (index !== -1) {
          prevState[index] = { ...prevState[index], isChecked };
        }
        return prevState;
      });
    };

    useEffect(() => {
      updateTableColumn();
    }, [selectedNodeKeys, data]);

    useImperativeHandle(ref, () => ({
      rows: tableData,
      columns: columnData,
    }));

    return (
      <section className="" style={{ fontFamily: "roboto, sans-serif" }}>
        <CubeBreadCrumb items={items} />
        <div className="py-2">
          <span className="w-full h-[1px] bg-slate-300 "></span>
        </div>
        <div className="flex items-center justify-between pb-3">
          <div className="flex items-center justify-center gap-3">
            <div className="text-xs">Select Filter</div>
            <Tooltip
              target=".amort"
              content="Preview unavailable - request demo"
              position="bottom"
            />
            <Dropdown
              tooltip="preview unavailable - request demo"
              // value={selectedView && selectedView.id}
              // onChange={handleViewChange}
              options={[]}
              // optionLabel="view_name"
              // optionValue="id"
              // optionLabel="view_name"
              // optionValue="id"
              data-pr-position="left"
              placeholder={"Select a filter"} // id="viewList"
              // id="viewList"
              dropdownIcon={
                <GoTriangleDown className="text-primary_light text-lg" />
              }
              className="!w-[300px] !h-[35px] md:!w-[200px] rounded xl: custom_select"
              // itemTemplate={viewListOptionTemp}
              // filter
              emptyMessage={null}
            />
            <Button
              icon="pi pi-filter"
              iconPos="left"
              className="icon_btn_primary  !text-xs text-white !font-light !h-9"
              tooltip="Preview unavailable - request demo"
            />
          </div>
          <div>
            <Dropdown
              tooltip="preview unavailable - request demo"
              // value={selectedView && selectedView.id}
              // onChange={handleViewChange}
              options={[]}
              // optionLabel="view_name"
              // optionValue="id"
              // optionLabel="view_name"
              // optionValue="id"
              data-pr-position="left"
              placeholder={`${lastMonth.monthShort} ${lastMonth.startDate}, ${lastMonth.year} to ${firstMonth.monthShort} ${firstMonth.endDate}, ${firstMonth.year} `} // id="viewList"
              // id="viewList"
              dropdownIcon={
                <GoTriangleDown className="text-primary_light text-lg" />
              }
              className="!w-[360px] !h-[35px] md:!w-[250px] rounded xl: custom_select"
              // itemTemplate={viewListOptionTemp}
              // filter
              emptyMessage={null}
            />
          </div>
        </div>
        <CubeTable
          rowData={tableData}
          columnData={columnData}
          sortField={columnData?.at(-1)?.field}
          disableHeader
          isColumnAction
          isSkeletonLoading={
            isLoading || !columnData || columnData.length === 0
          }
          isLoading={isFetching}
          isError={isError}
          rowSize={70}
          rowsPerPageOptions={[70, 100, 150]}
          rows
          minHeight="440px"
          isFilter
          isTotalCost
          isCheckBoxMode
          csvFileName="Tag Key Audit"
          handleSelectAll={handleSelectAll}
          handleSingleSelect={handleSingleSelect}
          handleRequiredChange={handleRequiredChange}
          scrollToLastColumn
          checkBoxHeaderClassName="frozenTableHeader"
          disableDownload
          handleSave={handleSave}
          changed={changed}
          isCustomHeaderWidth
        />
      </section>
    );
  }
);

export default TagAuditTable;
