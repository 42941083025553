import React from "react";

const PrivacyPolicy = () => {
  return (
    <div id="privacy__policy" className=" bg-secondary_light">
      <div className="py-20 container mx-auto">
        <div className="privacy__policy__banner flex items-center text-3xl text-secondary_light px-10">
          <h1>Privacy Policy </h1>
        </div>
        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal text-[20px]">Data We Collect </h3>
          <p>
            CloudLens is committed to protecting your privacy. We may collect
            your personal data for business purposes in a variety of ways,
            including, but not limited to, when you visit our websites, complete
            webforms, and in connection with other activities, services, events,
            features, or resources we make available. You may be asked to
            provide, as appropriate, personal data including name, email
            address, company, title, phone number, address, and information on
            your cloud infrastructure. You may, however, visit our websites and
            interact with us anonymously. We will collect your personal
            information only if you voluntarily submit such information to us.
            You may always refuse to supply personally identifiable information,
            except when it is necessary and required to provide certain
            services.
          </p>
        </div>
        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal text-[20px]">
            Online Navigational Data{" "}
          </h3>

          <p className="my-3">
            We collect anonymous usage data on our websites, such as IP address,
            referring site, and browser type. We also use cookies to personalize
            your experience. We do not correlate this data with personally
            identifiable information except when you have given your explicit
            consent or for security and legal purposes, such as preventing data
            theft.
          </p>
          <p>
            You may choose to set your web browser to refuse cookies and to
            alert you when cookies are being stored. If you choose to refuse
            cookies, the functionality of some portions of our websites may be
            limited.
          </p>
          <p className="my-3">
            With your explicit consent, our websites may also use webforms and
            other data collection, storage, and correlation tools and methods to
            enhance your experience by collecting and leveraging personally
            identifiable information.
          </p>
        </div>

        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal text-[20px]">
            How We Use Data We Collect{" "}
          </h3>
          <p>
            CloudLens may collect and use your personally identifiable
            information for the following business purposes:{" "}
          </p>
          <ul className="ml-10 list-disc">
            <li className="my-3">
              To personalize your experience: We may use information to
              understand how you use the services and resources we provide
              online and elsewhere.
            </li>
            <li>
              To improve our websites, offerings, and services: We may use
              feedback you provide to improve our products and services.
            </li>
            <li className="my-3">
              To communicate with you: We may use your contact information to
              send you communication and updates pertaining to your interests.
              Your contact information may also be used to respond to your
              inquiries, questions, and/or other requests. If you choose to
              provide your email address, you will receive emails that may
              include company news, updates, related product, or service
              information, etc. If at any time you would like to unsubscribe
              from receiving future emails and communication, we include an
              unsubscribe link within each email.
            </li>
          </ul>
        </div>
        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal text-[20px]">
            How We Manage & Store Data{" "}
          </h3>

          <p>
            We do not sell, trade, or rent your personal data to others. We may
            use third-party service providers to help us operate our business
            and administer activities on our behalf, such as sending email. We
            may share your information with these third parties for those
            limited purposes only, provided that you have given us your consent.
          </p>
        </div>
        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal text-[20px]">How We Secure Data </h3>

          <p>
            We have adopted appropriate data collection, storage, and processing
            practices and security measures to prevent unauthorized access,
            alteration, disclosure, destruction, or other misuse of information
            under our control. Only authorized personnel are allowed physical
            and/or digital access to servers and data. All servers and data
            backups are kept secure from physical and electronic intrusion by
            use of firewalls and redundant security systems, which are
            periodically evaluated and upgraded.
          </p>
        </div>
        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal text-[20px]">
            Managing Your Data, Opting Out & Unsubscribing{" "}
          </h3>

          <p>
            We make it easy for you to manage your email subscriptions, update
            your personal data, or unsubscribe with a link within each email and
            webpage. At any time, you may request to review your data or that we
            delete it. Please email privacy@cloudlens.com with any questions
            related to your personal data.
          </p>
        </div>
        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal text-[20px]">
            Changes & Updates to this Privacy Policy{" "}
          </h3>

          <p>
            CloudLens may update this privacy policy at this time. When we do,
            we will revise the updated date at the beginning of this page. We
            encourage you to frequently check www.cloudlens.com/privacy for any
            changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it
            is your responsibility to review this privacy policy periodically
            and become aware of modifications.
          </p>
        </div>
        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal text-[20px]">
            Your Acceptance of These Terms{" "}
          </h3>

          <p>By using our websites, you agree to these terms.</p>
          <p>
            You are explicitly giving CloudLens additional consent to enhance
            your experience by collecting and leveraging personally identifiable
            information when you select “I consent” in the Privacy Policy
            section of our webforms, opting in at an event, or consenting via a
            designated CloudLens consent form.
          </p>
        </div>
        <div className="px-10 pt-5 pb-10  bg-white">
          <h3 className="mb-5 font-normal text-[20px]">
            Contacting CloudLens{" "}
          </h3>

          <p>
            If you have any questions about this Privacy Policy, the practices
            of this site, or your dealings with this site, please contact us
            at privacy@cloudlens.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
