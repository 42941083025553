import React from "react";

const TermsConditions = () => {
  return (
    <div id="terms__conditions" className=" bg-secondary_light">
      <div className="py-20 container mx-auto">
        <div className="terms__conditions__banner flex items-center text-3xl text-secondary_light px-10">
          <h1>Terms of Use </h1>
        </div>
        <div className="p-10 content__paragraph bg-white">
          <p>
            Please read the following statements and policies concerning your
            use of finops.cloud-lens.com/ and related properties. By accessing,
            using, or downloading any materials from this website, you agree to
            be bound by these notices, terms, and conditions.{" "}
          </p>
          <p>
            All materials provided on this website, including but not limited to
            information, documents, products, logos, graphics, sounds, and
            services, have been provided by CloudLens or by third parties and
            are the copyrighted works. None of the materials may be copied,
            reproduced, distributed, displayed posted, or transmitted in any
            form or by any means without the prior express written consent of
            CloudLens.{" "}
          </p>
          <p>
            CloudLens grants you permission to download Materials on this
            website provided that: (1) the appropriate copyright notice appears
            in the Materials; (2) the use of such Materials is solely for
            personal, informational use and will not be copied or posted on any
            networked computer, broadcast in any media, or used for commercial
            gain; and (3) the Materials are not modified in any way.
            Unauthorized use of any downloaded Materials may violate copyright,
            trademark, and privacy laws.
          </p>
          <p>
            This website may contain links to websites controlled by third-
            parties. CloudLens does not endorse or accept any responsibility for
            the contents or use of these Materials or associated websites.
          </p>
          <p>
            Except where expressly provided otherwise by CloudLens, all
            comments, feedback, information, or materials submitted to CloudLens
            through this website shall be considered non-confidential and
            CloudLens property. By providing these submissions to CloudLens, you
            agree to assign to CloudLens, at no charge, all worldwide rights,
            title and interest in copyrights and other intellectual property
            rights to the Submissions. You will remain responsible for the
            Submissions that you provide, and their legality, reliability,
            appropriateness, originality, and copyright.
          </p>
          <p>
            The materials on the website are provided “as is”, CloudLens
            disclaims express or implied representations, warranties,
            guaranties, and conditions, except to the extent that such
            disclaimers are held to be legally invalid. CloudLens makes no
            representations, warranties, guaranties, or conditions as to the
            quality, suitability, truth, accuracy, or completeness of any of the
            materials contained on the website.{" "}
          </p>
          <p>
            CloudLens shall not be liable for any damages suffered because of
            using, distributing, or downloading any materials found on this
            website. In no event shall CloudLens be liable for any indirect,
            punitive, special, incidental, or consequential damage (including
            but not limited to loss of business, revenue, profits, use, data or
            other economic advantage), however it arises, even if CloudLens has
            been advised of the possibility of such damage. You agree to hold
            CloudLens harmless from, and you covenant not to sue CloudLens for
            any claims related to the use of this website.{" "}
          </p>
          <p>
            No logo, graphic, or sound from the website may be copied without
            express written consent from CloudLens.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
