/* eslint no-alert:"off" */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-nested-ternary: "off" */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { TreeTable } from "primereact/treetable";
import { Checkbox } from "primereact/checkbox";
import { FilterMatchMode, FilterService } from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { BsInfoCircle } from "react-icons/bs";
import { FiDelete } from "react-icons/fi";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import _ from "lodash";
import { Row } from "primereact/row";
import { Button } from "primereact/button";
import "./cubeTree.css";
import { HiArrowSmallDown, HiArrowSmallUp } from "react-icons/hi2";
import TreeViewHeader from "./TreeViewHeader";
import TreeViewCustomHeader from "./TreeViewCustomHeader";
import {
  flatMap,
  formatCommas,
  toolTipNameChanger,
} from "../../../helpers/helpers";

const CubeTreeView = forwardRef(
  (
    {
      routeName,
      rowData,
      handleExpand,
      handleCollapse,
      visibleColumns,
      isLoading,
      type,
      isError,
      groupData,
      sortable = 1,
      cellClassName = "",
      isCheckBoxSelection,
      resetView,
      isGroupView,
      getGroupData,
      handleInfo,
      expandedKey,
      selectedViewData,
      onToggle,
      nodeData,
      frozenWidth = "455px",
      filter = 1,
      cellWidth = "10rem",
      cellHeight = "41px",
      customHeaderProps = {},
      headerGroupLabel = "Total Cost",
      isExcelJs = false,
      tableColumn,
      anomalyRowData,
      isAnomaly = false,
      handleColorFilterChange,
      clearColorFilter,
    },
    ref
  ) => {
    const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const filterReference = useRef({});
    const tableReference = useRef({});
    const [filters, setFilters] = useState({});
    const [isReset, setIsReset] = useState(false);
    const [filterValue, setFilterValue] = useState({});

    const handleFilterChange = () => {
      const treeTableElement =
        tableReference.current && tableReference.current.getElement
          ? tableReference.current.getElement()
          : null;
      const tableRows =
        treeTableElement &&
        treeTableElement.getElementsByClassName("name-cell-1");
      if (tableRows && isGroupView) {
        const visibleRowData = [];
        let totalCost = {};
        for (const rowElement of tableRows) {
          const dataRow = rowElement.getAttribute("data-row");
          if (dataRow && typeof dataRow === "string") {
            const parsedDataRow = JSON.parse(dataRow);
            const cleanedDataRow = {};
            for (const key in parsedDataRow) {
              if (Object.prototype.hasOwnProperty.call(parsedDataRow, key)) {
                let value = parsedDataRow[key];
                if (typeof value === "string") {
                  value = parseFloat(value.replace(/[^0-9.-]+/g, ""));
                }
                if (typeof value === "object") {
                  value = parseFloat(value);
                }
                cleanedDataRow[key] = value;
              }
            }
            visibleRowData.push(cleanedDataRow);
          }
        }
        if (visibleRowData && visibleRowData.length > 0) {
          for (const col of visibleColumns) {
            const objectKey =
              col.field && typeof col.field === "string"
                ? col.field.replace(/(\w+)\.(\d+)/g, "$1[$2]")
                : null;
            if (objectKey) {
              if (type !== "tag_overview") {
                const value = visibleRowData
                  .map((doc) => {
                    return doc && typeof doc === "object"
                      ? _.get(doc, objectKey) || 0
                      : doc[objectKey]
                      ? doc[objectKey]
                      : 0;
                  })
                  .reduce((a, b) => a + b, 0);
                totalCost[objectKey.split(".")[0]] = Math.round(value);
              } else {
                const response = {};
                visibleRowData.forEach((item) => {
                  for (const key in item) {
                    if (key !== "name") {
                      response[key] = item[key] || 0;
                    }
                  }
                });
                totalCost = response;
              }
            }
          }
        }
        if (getGroupData && typeof getGroupData === "function") {
          getGroupData(totalCost);
        }
      }
    };

    const renderDiff = (value) => {
      const color = value < 0 ? "#008F1D" : "#E22929";
      const ArrowComponent = value < 0 ? HiArrowSmallDown : HiArrowSmallUp;
      return (
        <div
          className="flex items-center w-1/2 justify-end text-xs"
          style={{ color }}
        >
          {value.toFixed(2)}%
          <ArrowComponent />
        </div>
      );
    };

    const renderCostCell = (data, field, restrictDiff) => {
      const anomalyData = data && data.anomaly_data;
      let anomaly = "";

      if (anomalyData) {
        for (const key in anomalyData) {
          if (
            anomalyData[key].month.toLowerCase() ===
            field.split("-").pop().toLowerCase()
          ) {
            anomaly = anomalyData[key].anomaly;
          }
        }
      }

      const value =
        data && field && data[field] ? formatCommas(data[field]) : "0";
      const index = visibleColumns.findIndex((doc) => doc.field === field);
      let diff;
      if (index && index !== -1) {
        const curVal =
          data &&
          data[visibleColumns[index].field] &&
          data[visibleColumns[index].field];
        const prevVal =
          data &&
          data[visibleColumns[index - 1].field] &&
          data[visibleColumns[index - 1].field];
        diff = curVal && prevVal && ((curVal - prevVal) / prevVal) * 100;
      }

      return (
        <div
          className={`flex ${
            type === "tag_overview" ? "pr-0 justify-start" : "justify-end"
          } w-full items-center`}
        >
          <div
            className={`text-right ${
              anomaly === true ? " px-2 bg-red-700 text-white rounded-md" : ""
            }`}
          >{`${type !== "tag_overview" ? `$${value}` : `${value}`}`}</div>
          {!restrictDiff &&
            type !== "tag_overview" &&
            type !== "anomaly" &&
            (diff ? (
              renderDiff(diff)
            ) : (
              <div className="w-1/2 text-right" style={{ fontSize: "10px" }}>
                N/A
              </div>
            ))}
        </div>
      );
    };

    const renderCountCell = (data, key) => {
      const field = key.split(".")[0];
      if (data && field && data[field]) {
        const countObj = data[field];
        const splitedVal = countObj.split("/");
        const diff = (Number(splitedVal[0]) / Number(splitedVal[1])) * 100;
        return (
          <div className="w-full flex justify-end items-center">
            <div className="w-1/2 text-right">{countObj}</div>
            <div
              className="flex w-1/2 items-center justify-end text-sm"
              style={{
                color: "rgba(24, 172, 118, 1)",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {diff.toFixed(1)}%
            </div>
          </div>
        );
      }
      return (
        <div className="w-full flex justify-end items-center">
          <div
            className="w-1/2 text-right"
            style={{
              color: "rgba(102, 102, 102, 1)",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            00
          </div>
          <div
            className="flex w-1/2 justify-end items-center text-sm"
            style={{ color: "red", fontSize: "12px" }}
          >
            N/A
          </div>
        </div>
      );
    };

    const renderMultiCountCell = (data, key) => {
      const field = key.split(".")[0];
      const value = data && field && data[field.trim()];

      if (value && Object.keys(value).length > 0) {
        const diff = (Number(value.keyCount) / Number(value.totalCount)) * 100;
        return (
          <div className="w-full flex justify-end items-center">
            <div className="w-1/2 text-right">
              {formatCommas(value.keyCount)}/
              {`${formatCommas(value.totalCount)}`}
            </div>
            <div
              className="flex items-center justify-end text-sm w-1/2"
              style={{ color: "green", fontSize: "12px" }}
            >
              {diff.toFixed(1)} %
            </div>
          </div>
        );
      }
      return (
        <div className="w-full flex justify-end items-center">
          <div className="w-1/2 text-right">0/0</div>
          <div
            className="text-right w-1/2"
            style={{ color: "red", fontSize: "12px" }}
          >
            N/A
          </div>
        </div>
      );
    };

    const renderRecommendationCell = (data, key) => {
      const value = _.get(data, key);
      const column =
        visibleColumns && Array.isArray(visibleColumns)
          ? visibleColumns.find((doc) => doc.field === key)
          : null;
      const symbol = column?.type && column.type === "number" ? "" : "$";
      return (
        <div>
          {symbol}
          {formatCommas(value)}
        </div>
      );
    };

    const renderBody = ({ data }, { field }) => {
      switch (type) {
        case "count":
          return renderCountCell(data, field);
        case "multi_count":
          return renderMultiCountCell(data, field);
        case "growth_timeline":
          return renderCostCell(data, field);
        case "growth_meter":
          return renderCostCell(data, field, true);
        case "recommendation":
          return renderRecommendationCell(data, field);
        default:
          return renderCostCell(data, field);
      }
    };

    const toggleOverlayPanel = (key, event) => {
      if (filterReference.current && filterReference.current[key]) {
        filterReference.current[key].toggle(event);
      }
    };

    const handleFilterFieldChange = (value, fieldType) => {
      setFilterValue((prev) => {
        const prevState = { ...prev };
        prevState[fieldType] = value;
        return prevState;
      });
    };

    const getFilterType = (fieldType) => {
      switch (fieldType) {
        case "contain":
          return FilterMatchMode.CONTAINS;
        case "start":
          return FilterMatchMode.STARTS_WITH;
        case "end":
          return FilterMatchMode.ENDS_WITH;
        case "less":
          return FilterMatchMode.LESS_THAN;
        case "lessEqual":
          return FilterMatchMode.LESS_THAN_OR_EQUAL_TO;
        case "greater":
          return FilterMatchMode.GREATER_THAN;
        case "greaterEqual":
          return FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
        case "array_includes":
          return fieldType;
        case "number_between":
          return fieldType;
        default:
          return FilterMatchMode.CONTAINS;
      }
    };

    const handleFilterTypeChange = (value, fieldType) => {
      const filterType = getFilterType(value);
      if (fieldType && filterType) {
        setFilters((prev) => {
          const prevState = { ...prev };
          prevState[fieldType].matchMode = filterType;
          prevState[fieldType].filterType = value;
          return prevState;
        });
      }
    };

    const hidePanel = (fieldType) => {
      if (
        filterReference &&
        filterReference.current &&
        filterReference.current[fieldType]
      )
        filterReference.current[fieldType].hide();
    };

    const handleFind = (fieldType) => {
      const value = filterValue && filterValue[fieldType];
      if (fieldType && value) {
        setFilters((prev) => {
          const prevState = prev ? { ...prev } : {};
          prevState[fieldType] = { ...prevState[fieldType], value };
          return prevState;
        });
      }
      hidePanel(fieldType);
    };

    const handleClear = (fieldType) => {
      if (fieldType) {
        setFilters((prev) => {
          const prevState = { ...prev };
          prevState[fieldType].value = null;
          return prevState;
        });
      }
      setFilterValue((prev) => {
        const prevState = { ...prev };
        prevState[fieldType] = null;
        return prevState;
      });
      hidePanel(fieldType);
      setIsReset(true);
      if (clearColorFilter && typeof clearColorFilter === "function") {
        clearColorFilter();
      }
    };

    const handleCustomFilterChange = (
      fieldType,
      key,
      originalEvent,
      isPrevApplied
    ) => {
      const isTag =
        originalEvent.target && originalEvent.target.value.includes("/");
      if (fieldType === "custom") {
        const value =
          key !== "name" && !isTag
            ? Number(originalEvent.target.value)
            : originalEvent.target.value;
        setFilterValue((prev) => {
          const prevState = { ...prev };
          const preValue =
            prevState[key] && Array.isArray(prevState[key]) && !isPrevApplied
              ? [...prevState[key]]
              : [];
          const index = preValue.findIndex((doc) => doc === value);
          if (index !== -1) {
            preValue.splice(index, 1);
          } else {
            preValue.push(value);
          }
          prevState[key] = preValue;
          return prevState;
        });
      }
      if (fieldType === "selectAll") {
        setFilterValue((prev) => {
          const prevState = { ...prev };
          prevState[key] = originalEvent;
          return prevState;
        });
      }
      if (fieldType === "concat") {
        setFilterValue((prev) => {
          const prevState = { ...prev };
          prevState[key] =
            prevState[key] && Array.isArray(prevState[key])
              ? [...prevState[key], ...originalEvent]
              : originalEvent;
          return prevState;
        });
      }
      if (fieldType === "remove") {
        const validKeys = [];
        if (
          filterValue &&
          filterValue[key] &&
          Array.isArray(filterValue[key])
        ) {
          for (const filterKey of filterValue[key]) {
            if (
              !originalEvent ||
              (originalEvent && !originalEvent.includes(filterKey))
            ) {
              validKeys.push(filterKey);
            }
          }
        }
        setFilterValue((prev) => {
          const prevState = { ...prev };
          prevState[key] = validKeys;
          return prevState;
        });
      }
    };

    const isFilterApplied = () => {
      let isApplied = false;
      if (filters && Object.keys(filters).length) {
        for (const key in filters) {
          if (key) {
            const filterField = filters[key];
            if (filterField.value !== null) {
              isApplied = true;
            }
          }
        }
      }
      return isApplied;
    };

    const initialFilterUpdate = () => {
      const updatedFilters = {};
      for (const column of visibleColumns) {
        const { field } = column;
        updatedFilters[field] = {
          value: null,
          matchMode: getFilterType("array_includes"),
          filterType: "array_includes",
        };
      }
      updatedFilters.name = {
        value: null,
        matchMode: getFilterType("array_includes"),
        filterType: "array_includes",
      };
      setFilters(updatedFilters);
    };

    const resetFilterValue = () => {
      setFilterValue((prev) => {
        const obj = {};
        for (const key in prev) {
          if (key) {
            obj[key] = null;
          }
        }
        return obj;
      });
    };

    const handleClearFilter = (selectedVal) => {
      if (isFilterApplied() || selectedVal) {
        initialFilterUpdate();
        resetFilterValue();
        setIsReset(true);
        if (clearColorFilter && typeof clearColorFilter === "function") {
          clearColorFilter();
        }
      } else {
        alert("No filter applied");
      }
    };

    const renderNameCell = (row) => {
      const { key, data } = row;
      const level = key && typeof key === "string" ? key.split("-").length : 1;
      const cellData =
        data && Object.keys(data).length > 0 ? JSON.stringify(data) : null;
      const drillName = row?.currentDrill;
      return (
        <div
          className="inline-flex items-center relative"
          style={{ top: "4px" }}
        >
          <Tooltip target=".treeTooltip" className="custom_tooltip" />
          {handleInfo &&
            typeof handleInfo === "function" &&
            drillName?.toLowerCase() !== "application name" &&
            drillName?.toLowerCase() !== "application_name" && (
              <BsInfoCircle
                aria-hidden
                className="text-[#666666] hover:text-secondary block cursor-pointer mr-1"
                onClick={() => handleInfo(row, data.name)}
              />
            )}

          <div
            className={`name-cell-${level} treeTooltip cursor-pointer !text-start truncate w-[250px]`}
            data-row={cellData}
            style={{
              color: "rgba(102, 102, 102, 1)",
              fontSize: "16px",
              fontWeight: "400",
            }}
            data-pr-tooltip={
              row?.next_drill && !row?.resource_count
                ? `${toolTipNameChanger(row.next_drill)}: ${formatCommas(
                    row?.data_count
                  )}`
                : row?.next_drill && row?.resource_count
                ? `${toolTipNameChanger(row.next_drill)}: ${formatCommas(
                    row?.data_count
                  )} \n Resources: ${formatCommas(row?.resource_count)}`
                : null
            }
            data-pr-position="top"
          >
            {data && data.name ? data.name : "N/A"}
          </div>
        </div>
      );
    };

    const renderHeaderGroup = () => {
      return (
        <ColumnGroup>
          <Row>
            <Column
              header={
                Boolean(filter) ? (
                  <TreeViewHeader
                    header={{ field: "name", label: "Name" }}
                    filterValue={filterValue}
                    filters={filters}
                    handleFilterTypeChange={handleFilterTypeChange}
                    handleFilterFieldChange={handleFilterFieldChange}
                    handleClear={handleClear}
                    handleFind={handleFind}
                    toggleOverlayPanel={toggleOverlayPanel}
                    filterReference={filterReference}
                    searchKeyData={rowData}
                    handleCustomFilterChange={handleCustomFilterChange}
                    clearAllFilter={handleClearFilter}
                    type={type}
                  />
                ) : (
                  "Name"
                )
              }
              field="name"
              style={{ width: "18rem", height: "41px" }}
              className={`sticky_header ${isCheckBoxSelection && "!p-2 "}`}
              filter={Boolean(filter)}
              filterPlaceholder="Search"
              filterMatchMode="contains"
            />
            {visibleColumns &&
              visibleColumns.length > 0 &&
              visibleColumns.map((col, colIndex) => {
                const uniqueIndex = `column-${colIndex}`;
                const headerClassName = col.headerClassName
                  ? col.headerClassName
                  : "";
                const header = Boolean(filter) ? (
                  <TreeViewHeader
                    header={col}
                    filterValue={filterValue}
                    filters={filters}
                    handleFilterTypeChange={handleFilterTypeChange}
                    handleFilterFieldChange={handleFilterFieldChange}
                    handleClear={handleClear}
                    handleFind={handleFind}
                    toggleOverlayPanel={toggleOverlayPanel}
                    filterReference={filterReference}
                    searchKeyData={rowData}
                    handleCustomFilterChange={handleCustomFilterChange}
                    clearAllFilter={handleClearFilter}
                    type={type}
                    isAnomaly={isAnomaly}
                    handleColorFilterChange={handleColorFilterChange}
                  />
                ) : (
                  col.label
                );
                return (
                  <Column
                    key={uniqueIndex}
                    field={col.field}
                    header={header}
                    style={{ width: cellWidth, height: cellHeight }}
                    sortable={Boolean(sortable)}
                    className="!p-2"
                    headerClassName={headerClassName}
                  />
                );
              })}
          </Row>
          <Row>
            <Column
              header={headerGroupLabel}
              style={{ width: "18rem", height: "41px" }}
              className={`sticky_header ${isCheckBoxSelection && "!p-2"}`}
            />
            {visibleColumns &&
              visibleColumns.length > 0 &&
              visibleColumns.map((col, colIndex) => {
                const uniqueIndex = `column-${colIndex}`;
                const isGrowthMeter = type === "growth_meter";
                return (
                  <Column
                    key={uniqueIndex}
                    header={renderCostCell(groupData, col.field, isGrowthMeter)}
                    style={{ width: cellWidth, height: cellHeight }}
                    className="!p-2"
                  />
                );
              })}
          </Row>
        </ColumnGroup>
      );
    };

    const handleSelectionChange = (event) => {
      const { value } = event;
      setSelectedNodeKeys(value);
      const flattedData = flatMap(rowData);
      const selectedKeysLen = Object.keys(value).length;
      setSelectAll(flattedData.length <= selectedKeysLen);
    };

    const handleUpdate = (nodes) => {
      if (nodes && Array.isArray(nodes) && nodes.length) {
        const obj = { ...selectedNodeKeys };
        for (const key of nodes) {
          obj[key] = {
            checked: true,
            partialChecked: false,
          };
        }
        setSelectedNodeKeys(obj);
      }
    };

    const updateSelectAll = (flag) => {
      if (flag) {
        const flattedData = flatMap(rowData);
        const obj = {};
        for (const data of flattedData) {
          const { key } = data;
          obj[key] = {
            checked: true,
            partialChecked: false,
          };
        }
        setSelectedNodeKeys(obj);
      } else {
        setSelectedNodeKeys({});
      }
    };

    const handleSelect = () => {
      setSelectAll((prev) => {
        const flag = !prev;
        updateSelectAll(flag);
        return flag;
      });
    };

    const selectedKeyFilter = (value, b) => {
      if (b && Array.isArray(b) && b.length > 0) {
        return b.includes(value);
      }
      return true;
    };

    const betweenFilter = (val, fieldValue) => {
      if (Array.isArray(fieldValue) && fieldValue.length === 2) {
        const value = val && typeof val === "number" ? val : 0;
        const min = fieldValue[0];
        const max = fieldValue[1];
        return min <= value && max >= value;
      }
      return true;
    };

    const handleReset = () => {
      if (resetView && typeof resetView === "function") resetView();
    };

    const handleToggle = (e) => {
      const keys = e.value;
      if (onToggle && typeof onToggle === "function") onToggle(keys);
    };

    const handleGlobalFilter = (e) => {
      setFilters(e.filters);
    };

    useEffect(() => {
      if (filters && Object.keys(filters).length > 0 && !isReset) {
        handleFilterChange();
      }
    }, [filters, isReset, visibleColumns]);

    useEffect(() => {
      if (
        visibleColumns &&
        Array.isArray(visibleColumns) &&
        Object.keys(filters).length === 0
      ) {
        initialFilterUpdate();
      }
    }, [visibleColumns]);

    useEffect(() => {
      if (isReset) {
        setIsReset(false);
      }
    }, [isReset]);

    const handleExcelJs = (event) => {
      const selectedData = selectedNodeKeys
        ? Object.keys(selectedNodeKeys)
        : [];
      if (selectedData && selectedData.length > 0) {
        const sortByKey = selectedData.sort(
          (a, b) => a.split("-")[0] - b.split("-")[0]
        );
        const flattedData = flatMap(anomalyRowData);
        const excelData = [];
        for (const key of sortByKey) {
          const data = flattedData.find((doc) => doc.key === key);
          if (data && data.data) {
            const doc = data.data;
            excelData.push(doc);
          }
        }
        return excelData;
      }
      alert("Please select at least one row");
      event.preventDefault();
      event.stopPropagation();
      return [];
    };

    const [csvData, setCsvData] = useState([]);
    const handleDownload = (event) => {
      const selectedData = selectedNodeKeys
        ? Object.keys(selectedNodeKeys)
        : [];

      if (selectedData && selectedData.length > 0) {
        const sortByKey = selectedData.sort(
          (a, b) => a.split("-")[0] - b.split("-")[0]
        );
        const flattedData = flatMap(rowData);
        const excelData = [];

        for (const key of sortByKey) {
          const data = flattedData.find((doc) => doc.key === key);
          if (data && data.data) {
            const doc = data.data;
            const keySet = data.key.split("-");
            const obj = {};
            const category = nodeData
              ? nodeData.find((docNode) => docNode.level + 1 === keySet.length)
                  ?.column_name
              : "";
            obj.Category = category;

            obj.Name = doc.name;
            for (const filterKey of visibleColumns) {
              const field = filterKey.field.split(".")[0];
              if (doc[field] && doc[field].count && doc[field].totalCount) {
                obj[
                  filterKey.label
                ] = `${doc[field].count}/${doc[field].totalCount}`;
              } else if (doc[field] && doc[field].count) {
                obj[filterKey.label] = `${doc[field].count}`;
              } else if (doc[field] && doc[field].totalCount) {
                obj[
                  filterKey.label
                ] = `'${doc[field].keyCount}/${doc[field].totalCount}'`;
              } else {
                obj[filterKey.label] = doc[filterKey.field]
                  ? doc[filterKey.field]
                  : "0";
              }
            }
            excelData.push(obj);
          }
        }

        setCsvData(excelData);
      } else {
        alert("Please select at least one row");
        event.preventDefault();
        event.stopPropagation();
      }
    };
    const paginatorRight = (
      <div className="flex items-center">
        <Button
          type="button"
          icon="pi pi-download"
          text
          className="icon_btn_primary"
          style={{ color: "white" }}
        />
      </div>
    );

    const treeTableProps = {};
    const defaultColumnProps = {};
    const visibleColumnProps = {};
    const headerGroup = isGroupView ? renderHeaderGroup() : null;

    if (isGroupView) {
      treeTableProps.headerColumnGroup = headerGroup;
      treeTableProps.className = "customTree groupView";
      defaultColumnProps.className = "sticky_cell";
    } else {
      treeTableProps.frozenWidth = frozenWidth;
      treeTableProps.className = "customTree";
      defaultColumnProps.className = `tree_header !px-4 !p-2 ${
        sortable && "p-sortable-column"
      }`;
      defaultColumnProps.frozen = true;
    }
    if (isCheckBoxSelection) {
      treeTableProps.selectionMode = "checkbox";
      treeTableProps.selectionKeys = selectedNodeKeys;
      treeTableProps.onSelectionChange = handleSelectionChange;
      defaultColumnProps.className += " !p-2  custom-section-checkbox";
    }
    useImperativeHandle(ref, () => ({
      expandedKeys: selectedNodeKeys ? Object.keys(selectedNodeKeys) : [],
      updateExpandedKey: handleUpdate,
      setSelectedNodeKeys,
    }));
    FilterService.register("array_includes", selectedKeyFilter);
    FilterService.register("number_between", betweenFilter);
    return (
      filters &&
      Object.keys(filters).length > 0 &&
      !isReset && (
        <div>
          <div className="relative">
            {typeof resetView === "function" && (
              <>
                <Tooltip target=".resetTool" className="custom_tooltip" />
                <div
                  aria-hidden
                  data-pr-tooltip="Collapse Entire Field"
                  data-pr-position="top"
                  className={`absolute z-[1] ${
                    isAnomaly ? "top-[16px]" : "top-[11px]"
                  }
                  left-[12px] w-[22px] h-[22px] rounded-md bg-white flex justify-center items-center resetTool hover:bg-blue-300 cursor-pointer`}
                  onClick={handleReset}
                >
                  <span className="rotate-180">
                    <FiDelete className="!w-[12px]" />
                  </span>
                </div>
              </>
            )}
            {isCheckBoxSelection && (
              <div
                className={`absolute ${
                  isAnomaly ? "top-[14px]" : "top-[9px]"
                } left-[2.5rem] z-[1]`}
              >
                <Checkbox onChange={handleSelect} checked={selectAll} />
              </div>
            )}
            <TreeTable
              value={rowData}
              onExpand={handleExpand}
              onCollapse={handleCollapse}
              onToggle={handleToggle}
              expandedKeys={expandedKey}
              scrollable
              scrollHeight={routeName === "customReports" ? "340px" : "320px"}
              sortMode="single"
              filters={filters}
              loading={isLoading}
              ref={tableReference}
              sortField={visibleColumns.at(-1).field}
              sortOrder="-1"
              onFilter={handleGlobalFilter}
              paginator
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              paginatorRight={paginatorRight}
              rows={70}
              rowsPerPageOptions={[70, 100, 150]}
              {...treeTableProps}
            >
              <Column
                field="name"
                header={
                  Boolean(filter) ? (
                    <TreeViewHeader
                      header={{ field: "name", label: "Name" }}
                      filterValue={filterValue}
                      filters={filters}
                      handleFilterTypeChange={handleFilterTypeChange}
                      handleFilterFieldChange={handleFilterFieldChange}
                      handleClear={handleClear}
                      handleFind={handleFind}
                      toggleOverlayPanel={toggleOverlayPanel}
                      filterReference={filterReference}
                      searchKeyData={rowData}
                      handleCustomFilterChange={handleCustomFilterChange}
                      clearAllFilter={handleClearFilter}
                      type={type}
                    />
                  ) : (
                    "Name"
                  )
                }
                filter={Boolean(filter)}
                filterPlaceholder="Search"
                filterField="name"
                filterMatchMode="contains"
                filterHeaderClassName="!absolute !border-transparent"
                expander
                style={{ width: "18rem", height: "41px" }}
                body={renderNameCell}
                {...defaultColumnProps}
              />
              {visibleColumns && visibleColumns.length > 0 ? (
                visibleColumns.map((col, colIndex) => {
                  const uniqueIndex = `column-${colIndex}`;
                  const header = Boolean(filter) ? (
                    <TreeViewHeader
                      header={col}
                      filterValue={filterValue}
                      filters={filters}
                      handleFilterTypeChange={handleFilterTypeChange}
                      handleFilterFieldChange={handleFilterFieldChange}
                      handleClear={handleClear}
                      handleFind={handleFind}
                      toggleOverlayPanel={toggleOverlayPanel}
                      filterReference={filterReference}
                      searchKeyData={rowData}
                      handleCustomFilterChange={handleCustomFilterChange}
                      clearAllFilter={handleClearFilter}
                      type={type}
                    />
                  ) : Boolean(col?.headerType) ? (
                    <TreeViewCustomHeader
                      type={col.headerType}
                      field={col.field}
                      header={col.label}
                      {...customHeaderProps}
                    />
                  ) : (
                    col.label
                  );
                  let cellClass = ` ${cellClassName} `;
                  if (col.cellClassName) cellClass += col.cellClassName;
                  return (
                    <Column
                      key={uniqueIndex}
                      field={col.field}
                      header={header}
                      style={{ width: cellWidth, height: cellHeight }}
                      body={renderBody}
                      className={`tree_header !p-2 ${cellClass}`}
                      {...visibleColumnProps}
                      sortable={Boolean(sortable)}
                      align="center"
                    />
                  );
                })
              ) : (
                <Column
                  field=""
                  header=""
                  style={{ width: cellWidth, height: "57px" }}
                />
              )}
            </TreeTable>
          </div>
        </div>
      )
    );
  }
);

export default CubeTreeView;
