import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import DateSelector from "./DateSelector";
import { Tooltip } from "primereact/tooltip";

const groupByYear = (dateArray) => {
  return dateArray.reduce((acc, value) => {
    const year = new Date(value).getFullYear();
    if (!acc[year]) acc[year] = [];
    acc[year].push(value);
    return acc;
  }, {});
};

function TableCustomHeader({
  type,
  header,
  field,
  headerProps,
  filters,
  setFilters,
  customSearch = false,
}) {
  const datePopoverRef = useRef();
  const dateKeys =
    headerProps && headerProps.dateKeys
      ? groupByYear(headerProps.dateKeys)
      : [];

  const handleChange = (key) => {
    if (headerProps && typeof headerProps.handleDateChange === "function") {
      headerProps.handleDateChange(key);
    }
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (typeof setFilters === "function") {
      setFilters((prev) => {
        if (prev && Object.keys(prev).length) {
          const prevState = { ...prev };
          prevState[field].matchMode = FilterMatchMode.CONTAINS;
          prevState[field].value = value;
          return prevState;
        }
        return prev;
      });
    }
  };

  const value =
    filters?.[field]?.matchMode === FilterMatchMode.CONTAINS
      ? filters[field].value
      : "";

  const renderHeader = (key) => {
    const label = field?.split(".")[0];
    switch (key) {
      case "select":
        return (
          <div className="flex items-center">
            {header && <span>{header}</span>}
            <span
              className="px-3 py-2 bg-white rounded-md text-black ml-3 text-xs flex items-center"
              aria-hidden
              onClick={(e) => datePopoverRef.current.toggle(e)}
            >
              <span className="truncate cursor-pointer">{label}</span>
            </span>
            <OverlayPanel ref={datePopoverRef}>
              <DateSelector option={dateKeys} onChange={handleChange} />
            </OverlayPanel>
          </div>
        );
      case "search":
        return (
          <div className="">
            <span className="mr-3">{header}</span>
            {/* <Tooltip
              content="Preview Unavailable - request Demo"
              target=".search"
              position="top"
            /> */}
            <InputText
              className={`${customSearch && "!w-[120px]"} search cursor-pointer`}
              placeholder="Search"
              value={value}
              // disabled
              onChange={handleSearchChange}
            />
          </div>
        );

      default:
        return <div>{header}</div>;
    }
  };
  return renderHeader(type);
}

export default TableCustomHeader;
