import React from "react";

const SecurityPolicy = () => {
  return (
    <div id="privacy__policy" className=" bg-secondary_light">
      <div className="py-20 container mx-auto">
        <div className="privacy__policy__banner flex items-center text-3xl text-secondary_light px-10">
          <h1>Security Policy </h1>
        </div>
        <div className="px-10 py-5  bg-white">
          <p>
            CloudLens is committed to fulfilling information security and data
            privacy requirements.
          </p>
        </div>
        <div className="px-10   bg-white">
          <p>
            We consistently improve our security management system, focusing on
            compliance, risk management, and incident resolution to safeguard
            information in all forms, ensuring protection throughout its
            lifecycle.
          </p>
        </div>

        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal text-[25px]">Security Measures </h3>
          <p>
            CloudLens ensures that all servers within its production environment
            run up-to-date anti-virus software with real-time monitoring,
            updated at least daily.
          </p>
        </div>

        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal  text-[25px]">
            Account Protection Policy
          </h3>
          <h3 className="mb-5 font-normal text-[20px]">
            Login Security/Password Policy:{" "}
          </h3>
          <p>
            When users log into their CloudLens instance using their email
            address and password, CloudLens enforces a minimum password
            compliance:
          </p>
          <ul className="ml-10 list-disc">
            <li className="my-3">
              Passwords must be a minimum length of 16 characters.
            </li>
            <li>
              Passwords must contain uppercase and lowercase characters, at
              least 1 digit, and 1 symbol.
            </li>
            <li className="my-3">Passwords expire every 90 days.</li>
            <li className="my-3">
              Automatic account lock-out occurs after 3 failed login attempts.
            </li>
            <li className="my-3">
              Passwords are secured using a one-way hash algorithm. Customers
              can customize password complexity and other settings to increase
              overall password security.
            </li>
          </ul>
        </div>

        <div className="px-10 pb-5  bg-white">
          <h3 className="mb-5 font-normal text-[25px]">Access Control </h3>
          <p>
            CloudLens treats all customer data as highly sensitive and ensures
            it is protected accordingly. Only authorized, vetted, and trained
            CloudLens operations team members have direct access to systems
            containing user data. Those with access are only permitted to view
            data in aggregate for operational activities or in detail for
            troubleshooting, with all team members undergoing background checks.
          </p>
        </div>
        <div className="px-10 bg-white">
          <p>
            Application data is accessed solely by CloudLens Cloud Advisors for
            service delivery and by authorized Operations or other CloudLens
            employees for troubleshooting purposes with express consent.
          </p>
        </div>
        <div className="px-10 py-5  bg-white">
          <p>
            A maintained list records CloudLens team members with access to the
            production environment, and access attestations are reviewed at
            least every 3 months.
          </p>
        </div>

        <div className="px-10 pb-5  bg-white">
          <h3 className="mb-5 font-normal text-[25px]">
            Data Removal from CloudLens
          </h3>
          <p>
            Customer data in production environments is never duplicated outside
            of the cloud environments, nor stored on employee workstations or
            removable media. Upon termination of a CloudLens Enterprise contract
            or at the customer's request, their data is entirely deleted from
            the live production database within 30 days. Customer database
            backups are also destroyed in compliance with this policy.
          </p>
        </div>

        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal  text-[25px]">
            Data Backup and Storage
          </h3>
          <p>
            CloudLens takes a backup of the customer's primary database every 24
            hours. These backups are encrypted and stored at offsite locations
            (backup data centers) to ensure availability in the event of a
            restore requirement.
          </p>
        </div>
        <div className="px-10  bg-white">
          <p>
            Authorized members of the CloudLens operations team, who have
            undergone training and authorization to decrypt backups, are the
            only ones capable of decrypting encrypted backups. Access to backup
            locations is restricted to authorized members of the CloudLens
            operations team for monitoring backup process performance.
          </p>
        </div>

        <div className="px-10 py-5  bg-white">
          <h3 className="mb-5 font-normal  text-[25px]">
            Maintenance Policies
          </h3>
          <h3 className="mb-5 font-normal text-[20px]">Planned Maintenance:</h3>
          <p >
            CloudLens conducts planned maintenance during scheduled windows to
            minimize service disruptions. Efforts are made to provide at least 5
            days' notice before the event.
          </p>
        </div>
        <div className="px-10  bg-white">
          <h3 className=" font-normal text-[20px]">
            Planned Maintenance Windows:
          </h3>
          <p className="py-5">
            These windows are chosen to reduce downtime or slowdowns for users
            and businesses. Maintenance is typically scheduled between 9 p.m.
            and midnight local customer time to align with CloudLens's data
            collection and analytics processes. We strive to keep outages brief
            and regularly review our schedule to minimize user impact.
          </p>
        </div>
        <div className="px-10  bg-white">
          <h3 className="font-normal text-[20px]">
            Emergency Maintenance:
          </h3>
          <p className="pt-5 pb-10 ">
            In unforeseen circumstances, emergency maintenance may be required,
            potentially causing service inaccessibility for a period. Our goal
            is to minimize these occurrences and provide notice to identified
            customer contacts via email. Like planned maintenance, we aim to
            minimize disruption during service outages.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SecurityPolicy;
