import moment from "moment";

const minMonthShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const m = [
  "",
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
const getFormattedDate = (key) => {
  const date = new Date(key);
  const dateStr = `${m[date.getMonth() + 1]} ${date.getFullYear()}`;
  return dateStr.charAt(0).toUpperCase() + dateStr.slice(1);
};
function calculateCumulativeSumArray(dataArray) {
  const cumulativeSumArray = [];
  let cumulativeSum = 0;
  for (const value of dataArray) {
    cumulativeSum += value;
    cumulativeSumArray.push(cumulativeSum);
  }
  return cumulativeSumArray;
}

const formatCurrencyZeroRound = (currency) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0, // Set minimumFractionDigits to 0 to round off to a whole number
    maximumFractionDigits: 0, // Set maximumFractionDigits to 0 to remove decimal places
  });
  const numericValue =
    typeof currency === "string" ? Number(currency) : currency; // Convert the value to a number
  return formatter.format(numericValue).replace(/^(\D+)/, "$1");
};

function formatNumberIntoK(num) {
  if (num === 0) {
    return 0;
  }
  if (num >= 1000 || num < 0) {
    const units = ["", "K", "M", "B", "T"];
    const unitIndex = Math.floor(Math.log10(Math.abs(num)) / 3);
    const unitValue = units[unitIndex];
    const toFixed = unitValue === "K" ? 0 : 1;
    const formattedNum = (num / 1000 ** unitIndex).toFixed(toFixed);

    return `${formattedNum}${unitValue}`;
  }
  if (num < 1000) {
    return num.toFixed(0);
  }
  return 0;
}

function formatCommas(value) {
  return (Math.round(value) || 0).toLocaleString("en-US");
}

const formattedDates = (data) => {
  return data?.map((item) => {
    const monthIndex = parseInt(item.month) - 1; // Adjust for zero-based indexing
    const year = item.year.slice(-2); // Extract the last two characters of the year
    return `${minMonthShort[monthIndex]}'${year}`;
  });
};

const formattedIndexArr = (data) => {
  return data?.map((item) => {
    const monthIndex = parseInt(item.month) - 1; // Adjust for zero-based indexing
    const year = item.year.slice(-2); // Extract the last two characters of the year
    return `${minMonthShort[monthIndex]}`;
  });
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Example usage:
export const isValidEmail = (email) => {
  return emailRegex.test(email);
};

function handleCollapse(event, expandedKey) {
  const { node } = event;
  const filteredObj = { ...expandedKey };
  delete filteredObj[node.key];
  Object.keys(filteredObj).forEach((objKey) => {
    if (
      objKey.startsWith(node.key) &&
      objKey.split("-").length >= node.key.split("-").length
    ) {
      delete filteredObj[objKey];
    }
  });
  return filteredObj;
}

function splitStringOnCapitalLetters(string) {
  // Use regular expression to split the string
  const result = string
    .split(/(?=[A-Z])/)
    .map((str) => str.toLowerCase().trim());
  const finalResult = result.join("-");
  return finalResult;
}

function getMaxLength(expandedKey = {}) {
  let maxLength = 0;
  Object.keys(expandedKey).forEach((key) => {
    const keyParts = key.split("-");
    const keyLength = keyParts.length > 1 ? keyParts.length : 1;
    if (keyLength > maxLength) {
      maxLength = keyLength;
    }
  });
  return maxLength;
}
function flatMap(array) {
  let result = [];
  if (array && Array.isArray(array) && array.length > 0) {
    // eslint-disable-next-line
    array.forEach(function (a) {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(flatMap(a.children));
      }
    });
  }

  return result
    .filter((doc) => doc.key)
    .map(({ key, data }) => ({ key, data }));
}
const toolTipNameChanger = (name) => {
  switch (name) {
    case "SubscriptionName":
      return "Subscriptions";
    case "ResourceGroup":
      return "Resource Groups";
    case "ResourceName":
      return "Resources";
    case "MeterCategory":
      return "Meter Categories";
    case "MeterSubCategory":
      return "Meter Sub-Categories";
    case "ResourceLocation":
      return "Resource Locations";
    case "Business Unit":
      return "Business Units";
    case "Application Name":
      return "Applications";
    default:
      return name;
  }
};
const monthShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const filtrateColumn = (prev, actionColumn) => {
  const prevState = [...prev];
  const index = prevState.findIndex((doc) => doc.label.includes("Action"));
  if (index === -1) {
    prevState.push(actionColumn);
  }
  return prevState;
};

const getAdditionalMonth = (month) => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth() + month, // get additional month from the current month
    1
  ).toLocaleDateString("en-US", { month: "long", year: "numeric" });
};

const getDateRanges = (lastMonths, futureMonths) => {
  const currentMonth = moment();
  const lastMonthsStart = currentMonth
    .clone()
    .subtract(lastMonths, "months")
    .startOf("month")
    .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
  const futureMonthsEnd = currentMonth
    .clone()
    .add(futureMonths, "months")
    .endOf("month")
    .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");

  return {
    lastMonths: lastMonthsStart,
    futureMonths: futureMonthsEnd,
  };
};

const getRangeDateSet = (from, to) => {
  const dateSet = [];
  const fromDate = new Date(from);
  const toDate = new Date(to);
  while (getFormattedDate(fromDate) !== getFormattedDate(toDate)) {
    const updatedDate = new Date(fromDate.setMonth(fromDate.getMonth() + 1));
    dateSet.push(getFormattedDate(updatedDate));
  }
  dateSet.unshift(getFormattedDate(from));
  return dateSet;
};
function handleBreadCrumb(drillIndex, expandedKey) {
  if (drillIndex !== 0) {
    const newExpandedKey = Object.keys(expandedKey).reduce((result, key) => {
      const keyParts = key.split("-");
      if (keyParts.length < drillIndex) {
        const updatedResult = {
          ...result,
          [key]: expandedKey[key],
        };
        return updatedResult;
      }
      return result;
    }, {});
    return newExpandedKey;
  }
  return {};
}

function getDateRange(offset) {
  // Create Date objects for the start and end of the date range
  const startDate = new Date();
  const endDate = new Date();

  // Adjust the start date based on the offset
  startDate.setMonth(startDate.getMonth() + offset);
  startDate.setDate(1); // Set the day to the first of the month

  // Handle the case where the month goes beyond the available range
  if (startDate.getMonth() < 0) {
    startDate.setMonth(0); // Set to January of the current year
    startDate.setFullYear(startDate.getFullYear() - 1); // Move back to the previous year
  }

  // Set the end date to the last day of the month
  endDate.setFullYear(startDate.getFullYear(), startDate.getMonth() + 1, 0);

  // Get month names arrays
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthNamesFull = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Format the date range string
  const startDateString = `${
    monthNames[startDate.getMonth()]
  } ${startDate.getDate()}, ${startDate.getFullYear()}`;
  const endDateString = `${
    monthNames[endDate.getMonth()]
  } ${endDate.getDate()}, ${endDate.getFullYear()}`;

  // Create detailed object
  const detailedObj = {
    startDate: startDate.getDate(),
    endDate: endDate.getDate(),
    monthShort: monthNames[startDate.getMonth()],
    monthFull: monthNamesFull[startDate.getMonth()],
    year: startDate.getFullYear(),
  };

  return { detailedObj, string: `${startDateString} to ${endDateString}` };
}



// Function definition
function modifyArray(rawData) {
  const startDate = new Date();
  let startMonth = startDate.getMonth()+1 + 2 +1 
  const startIndex = rawData?.findIndex(month => month.month == startMonth);

  if (startIndex === -1) {
      console.log("Invalid start month provided.");
      return;
  }
  const first = rawData?.slice(startIndex)
  const startYear = first[0].year
  const second = rawData?.slice(0, startIndex).map(item => ({
    ...item,
    year: item.year === startYear.toString() ? (parseInt(item.year) + 1).toString() : item.year
}));
  // second.
  const modifiedArray = [...first,...second]
  return modifiedArray
}

export {
  formattedIndexArr,
  formattedDates,
  formatNumberIntoK,
  formatCommas,
  formatCurrencyZeroRound,
  calculateCumulativeSumArray,
  handleCollapse,
  splitStringOnCapitalLetters,
  getMaxLength,
  flatMap,
  toolTipNameChanger,
  monthShort,
  filtrateColumn,
  getAdditionalMonth,
  getDateRanges,
  getRangeDateSet,
  handleBreadCrumb,
  getDateRange,
  modifyArray
};
