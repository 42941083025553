import { GrNext } from 'react-icons/gr';
import { useEffect } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
// import { useSelector, useDispatch } from 'react-redux';
// import { handleAmortChange } from '../../features/base/baseSlice';
// import { getLocalStroageData, setLocalStroage } from '../../helpers/LocalHelper';
// import localConstant from '../../constant/localConstant';

function CubeBreadCrumb({
  items,
  showToggle,
  showVm,
  checked,
  onToggleChange,
  onVmChange,
  noBottomBorder
}) {
  const amortState = '';
  const cloudPartner = 'azure';
  const localAmort = '';

  const handleChange = (e) => {
  };

  const updateLocalAmort = () => {
  };

  const scrollToView = () => {
    document
      .getElementById('scroll_ele')
      ?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };

  useEffect(() => {
    if (localAmort && typeof localAmort === 'string') {
      updateLocalAmort();
    }
    scrollToView();
  }, []);

  return (
    <header
      className={`flex items-center  ${
        !noBottomBorder ? `border-b pb-2` : 'pb-2'
      } justify-between`}>
      <div className="flex items-center" id="scroll_ele">
        {items &&
          items.length > 0 &&
          items.map((nav, navIndex) => {
            const iconVisible = !(items.length === navIndex + 1);
            return (
              <div key={nav.label} className="flex items-center">
                <div className={navIndex === 0 ? ' font-semibold ' : ''}>{nav.label}</div>
                {iconVisible && (
                  <div className="mx-2">
                    <GrNext />
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {showToggle && cloudPartner !== 'aws' && (
        <div>
          <div className="flex items-center">
            <p className="mr-3">Amortization</p>
            <InputSwitch tooltip='preview unavailable - request demo' checked={amortState} onChange={handleChange} />
          </div>
        </div>
      )}
      {showVm && cloudPartner !== 'aws' && (
        <div className="flex items-center">
          <span>All VMs</span>
          <InputSwitch className="mx-2" checked={checked} onChange={(e) => onVmChange(e)} />
          <span>Persistent VMs</span>
        </div>
      )}
    </header>
  );
}

export default CubeBreadCrumb;
