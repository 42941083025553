import "./App.css";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import ScrollToTopButton from "./Components/ScrollToTopButton";
import TermsConditions from "./Components/TermsConditions";
import Wrapper from "./Wrapper";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "primeicons/primeicons.css";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import SecurityPolicy from "./Components/SecurityPolicy";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTopButton />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Wrapper />} />
          <Route path="/terms_and_conditions" element={<TermsConditions />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/security_policy" element={<SecurityPolicy />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
