import ReactEcharts from "echarts-for-react";
import moment from "moment";
import {
  calculateCumulativeSumArray,
  formatCurrencyZeroRound,
  formatNumberIntoK,
} from "../../../helpers/helpers";
import { formatCommas } from "../../../helpers/helpers";
import { formattedDates, formattedIndexArr } from "../../../helpers/helpers";
import React from "react";

const EBasicLineChart = React.memo(
  ({
    graphVal,
    graphYTD,
    type,
    xAxisName,
    yAxisName,
    rawData,
    handleXAxisLabelClick,
    isLegendType = "vertical",
    minHeight = "310px",
    isForecasted = false,
    isLabelClick = false,
  }) => {
    const chartProperty = type === "resource_forecast" ? "#366CBD" : "#E96529";
    const yearData = rawData && formattedDates(rawData); // data return like Jan'23
    const indexArr = rawData && formattedIndexArr(rawData);
    const currentMonth = moment().format("MMM");
    const currentMonthIndex = isForecasted
      ? indexArr &&
        (indexArr.indexOf(currentMonth) - 1 + indexArr.length) % indexArr.length
      : 12;
    const options = {
      grid: {
        left: "4%",
        right: "6%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        name: xAxisName,
        nameLocation: "middle",
        nameGap: 40,
        boundaryGap: false,
        axisLabel: {
          color: "black",
        },

        data: yearData,
        triggerEvent: true,
      },

      yAxis: {
        type: "value",
        name: yAxisName,
        nameLocation: "middle",
        nameRotate: 90,
        nameGap: type === "resource_forecast" ? 40 : 60,
        axisLabel: {
          color: "black",
          formatter: (value) => {
            return type === "resource_forecast"
              ? formatNumberIntoK(value)
              : `$${formatNumberIntoK(value)}`;
          },
        },
      },

      tooltip: {
        show: true,
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            show: true,
          },
        },
        formatter: (params) => {
          let value;
          if (params[0]?.dataIndex <= currentMonthIndex) {
            value =
              type === "resource_forecast"
                ? `${params[0]?.axisValue}: ${formatCommas(params[0].value)} ${
                    currentMonthIndex === params[0] &&
                    currentMonthIndex !== yearData.length?.dataIndex
                      ? ` <br> <br> Previous Forecast: ${formatCommas(
                          rawData[currentMonthIndex].previous_forecast_count
                        )}`
                      : ""
                  }`
                : `${params[0]?.axisValue}: ${formatCurrencyZeroRound(
                    graphVal[params[0]?.dataIndex]
                  )} | YTD: ${formatCurrencyZeroRound(
                    graphYTD[params[0]?.dataIndex]
                  )} ${
                    currentMonthIndex === params[0] &&
                    currentMonthIndex !== yearData.length?.dataIndex
                      ? `<br> <br>Previous Forecast: ${formatCurrencyZeroRound(
                          rawData[currentMonthIndex].previous_forecast_cost
                        )}`
                      : ""
                  }`;
          } else if (params[0]?.dataIndex >= currentMonthIndex) {
            value =
              type === "resource_forecast"
                ? `${params[1]?.axisValue}: ${formatCommas(params[1]?.value)}`
                : `${params[1]?.axisValue}: ${formatCurrencyZeroRound(
                    graphVal[params[1]?.dataIndex]
                  )} | YTD: ${formatCurrencyZeroRound(
                    graphYTD[params[0]?.dataIndex]
                  )}`;
          }

          return value;
        },

        textStyle: {
          color: "black",
          whiteSpace: "pre-line",
        },
        backgroundColor: type === "resource_forecast" ? "#ebf1f9" : " #fdeee8",
        borderColor: chartProperty,
      },
      legend: {
        orient: `${isLegendType}`,
        right: 10,
        data: isForecasted ? ["Current Data", "Forecasted Data"] : [],
        textStyle: {
          color: "black",
        },
      },

      series: [
        {
          name: "Current Data",
          data:
            type === "resource_forecast"
              ? graphVal.map((value, index) => {
                  let val;
                  if (index <= currentMonthIndex) {
                    val = value;
                  } else if (index > currentMonthIndex) {
                    val = null;
                  }
                  return val;
                })
              : calculateCumulativeSumArray(graphVal).map((value, index) => {
                  let val;
                  if (index <= currentMonthIndex) {
                    val = value;
                  } else if (index > currentMonthIndex) {
                    val = null;
                  }
                  return val;
                }),
          type: "line",
          itemStyle: {
            color: chartProperty,
          },
          lineStyle: {
            color: chartProperty,
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color:
                    type === "resource_forecast"
                      ? "rgba(53, 115, 177, 1)"
                      : "rgba(237, 98, 44, 1)",
                },
                {
                  offset: 1,
                  color:
                    type === "resource_forecast"
                      ? "rgba(235, 241, 249, 0.2)"
                      : "rgba(253, 238, 232, 0.2)",
                },
              ],
            },
          },
          label: {
            show: true,
            position: "top",
            padding: [0, 2, 0, 20],
            formatter: (params) => {
              const { value } = params;
              return type === "resource_forecast"
                ? `${formatNumberIntoK(value)}`
                : `$${formatNumberIntoK(value)}`;
            },
          },
        },
        {
          name: "Forecasted Data",
          data:
            type === "resource_forecast"
              ? graphVal.map((value, index) => {
                  let val;
                  if (index >= currentMonthIndex) {
                    val = value;
                  }
                  return val;
                })
              : calculateCumulativeSumArray(graphVal).map((value, index) => {
                  let val;
                  if (index >= currentMonthIndex) {
                    val = value;
                  }
                  return val;
                }),
          type: "line",
          itemStyle: {
            color: "#595959",
          },
          lineStyle: {
            color: "#737373",

            type: "dashed",
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#bfbfbf",
                },

                {
                  offset: 1,
                  color: "#ffffff",
                },
              ],
            },
          },

          label: {
            show: true,
            position: "top",
            rotate: 0,
            padding: [0, 2, 0, 20],
            formatter: (params) => {
              const { value } = params;
              return type === "resource_forecast"
                ? `${formatNumberIntoK(value)}`
                : `$${formatNumberIntoK(value)}`;
            },
          },
        },
      ],
    };
    const onChartClick = (params) => {
      if (isLabelClick) {
        handleXAxisLabelClick(params.value, type);
      }
    };

    const onEvents = {
      click: onChartClick,
    };
    return (
      <ReactEcharts
        option={options}
        style={{ width: "100%", height: minHeight }}
        onEvents={onEvents}
      />
    );
  }
);
export default EBasicLineChart;
