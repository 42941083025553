import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

function TreeViewCustomHeader({ field, type, header, goPrev, goNext }) {
  const renderHeader = () => {
    if (type === 'select') {
      const dateLabel = field?.split('.');
      return (
        <div className="flex items-center justify-between absolute top-[5px]">
          <p>{header}</p>
          <p className="px-4 py-2 bg-white text-black flex rounded-lg !ml-2">
            <p
              className="cursor-pointer hover:bg-gray-300 rounded-lg"
              aria-hidden
              onClick={() => {
                if (goPrev && typeof goPrev === 'function') goPrev(dateLabel?.[0]);
              }}>
              <GrFormPrevious />
            </p>
            <p className="text-[10px] mx-2 w-[45px] truncate">{dateLabel?.[0]}</p>
            <p
              className="cursor-pointer hover:bg-gray-300 rounded-lg"
              aria-hidden
              onClick={() => {
                if (goNext && typeof goNext === 'function') goNext(dateLabel?.[0]);
              }}>
              <GrFormNext />
            </p>
          </p>
        </div>
      );
    }
    return <div>{header}</div>;
  };

  return renderHeader();
}

export default TreeViewCustomHeader;
