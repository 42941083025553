import Feature from "./Components/Feature";
import Header from "./Components/Header";
import Overview from "./Components/Overview";
import Pricing from "./Components/Pricing";
import Service from "./Components/Service";
import ContactForm from "./Components/ContactForm";
import Blog from "./Components/Blog";

function Wrapper() {
    return (
        <div className="bg-white">
            <Header />
            <Overview />
            <Feature />
            <Service />
            <Blog />
            <Pricing />
            <ContactForm />
        </div>
    );
}

export default Wrapper;
