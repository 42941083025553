/* eslint no-nested-ternary: "off" */
/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Avatar } from "primereact/avatar";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Button } from "primereact/button";
import { BsInfoCircle } from "react-icons/bs";
import { FilterMatchMode, FilterService } from "primereact/api";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineEdit, MdClose, MdOutlineModeEdit } from "react-icons/md";
import { BsEye } from "react-icons/bs";
import { MdOutlineStopScreenShare } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { GoTriangleDown } from "react-icons/go";
import { TbShareOff } from "react-icons/tb";
import { GiExpand } from "react-icons/gi";
import { Dialog } from "primereact/dialog";
import PropsType from "prop-types";
import "./cubeTable.css";
import moment from "moment";
import { formatCommas } from "../../../helpers/helpers";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import TableFilterHeader from "./tableFilterHeader";
import TableCustomHeader from "./tableCustomHeader";
import { Dropdown } from "primereact/dropdown";
import _ from "lodash";

const userInputOpt = [
  { name: "Yes", value: "Yes" },
  { name: "No", value: "No" },
];
function CubeTable(props) {
  const {
    handleRowSelect,
    rowData = [],
    disableHeader = false,
    disableDownload = false,
    columnData,
    handleView,
    handleDelete,
    rowSize = 7,
    minHeight = "425px",
    customSearchInput,
    handleEdit,
    isLoading,
    isSkeletonLoading,
    isError,
    isCheckBoxMode,
    handleSelectAll,
    handleSingleSelect,
    csvFileName,
    handleInfo,
    totalCost,
    isTotalCost,
    flag,
    isInfoMode,
    getTotalCost,
    isFilter,
    customHeaderProps,
    isExpander,
    truncateNone,
    isShareAndScheduleMode = false,
    isScheduleMode = false,
    handleShare,
    handleSchedule,
    rowsPerPageOptions,
    sortField = "Cost",
    scrollToLastColumn,
    checkBoxHeaderClassName = "",
    handleRequiredChange,
    isSave,
    handleSave,
    changed,
    isCustomHeaderWidth,
    handleAppEdit,
    handleAppCreate,
    isAppEditable,
    adminCenter,
    removePaginator = true,
  } = props;

  const cellHeight = adminCenter ? "py-3" : "";
  const childProps = {
    ...props,
    isExpander: false,
    truncateNone: true,
  };
  const column =
    columnData && columnData.length > 0
      ? columnData
      : rowData && rowData.length > 0
      ? Object.keys(rowData[0])
          .filter(
            (doc) =>
              (isCheckBoxMode &&
                doc !== "isChecked" &&
                doc !== "id" &&
                doc !== "row_id") ||
              !isCheckBoxMode
          )
          .map((label) => ({ field: label, label }))
      : [];
  const [filters, setFilters] = useState({});
  const [excelData, setExcelData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [filterAppliedKey, setFilterAppliedKey] = useState({});
  const previousCustomFilterRef = useRef({});
  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const customFilter = { ...filters };
    customFilter.global.value = value;
    setFilters(customFilter);
  };

  const initialFilterOptionUpdate = () => {
    const initialOpt = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };
    for (const col of column) {
      const { field } = col;
      const type = "string";
      if (type === "string") {
        initialOpt[field] = { value: null, matchMode: "array_includes" };
      }
    }
    setFilters(initialOpt);
  };

  const onDelete = (event, row) => {
    event.stopPropagation();
    if (handleDelete && typeof handleDelete === "function") handleDelete(row);
  };

  const onShare = (event, row) => {
    event.stopPropagation();
    if (handleShare && typeof handleShare === "function") handleShare(row);
  };

  const onSchedule = (event, row) => {
    event.stopPropagation();
    if (handleSchedule && typeof handleSchedule === "function")
      handleSchedule(row);
  };

  const onDisabledAction = (e) => {
    e.stopPropagation();
  };

  const onEdit = (event, row) => {
    event.stopPropagation();
    if (handleEdit && typeof handleEdit === "function") handleEdit(row);
  };

  const onView = (event, row) => {
    event.stopPropagation();
    if (handleView && typeof handleView === "function") handleView(row);
  };

  const onInfo = (event, row, field) => {
    event.stopPropagation();
    if (handleInfo && typeof handleInfo === "function") handleInfo(row, field);
  };

  const renderInfo = (value, row, field) => {
    return (
      <div className="flex items-center max-h-12">
        <div onClick={(e) => onInfo(e, row, field)}>
          <BsInfoCircle className="text-gray-400 hover:text-secondary w-[15px] h-[15px]" />
        </div>
        <div className="mx-2">{value}</div>
      </div>
    );
  };

  const updateFilterOperator = (field, value, matchMode) => {
    setFilters((prev) => {
      const prevState = { ...prev };
      if (matchMode === "array_includes") {
        previousCustomFilterRef.current[field] = value;
      }
      if (Object.prototype.hasOwnProperty.call(prevState, field)) {
        prevState[field] = { value, matchMode: matchMode };
      }
      return prevState;
    });
  };

  const handleClearAllFilter = () => {
    initialFilterOptionUpdate();
    previousCustomFilterRef.current = {};
  };

  const renderFilterOverlay = (event, tableData, col, key) => {
    return (
      <TableFilterHeader
        headerEvent={event}
        rowData={tableData}
        column={col}
        setFilterOperator={updateFilterOperator}
        previousKeys={previousCustomFilterRef.current}
        clearAll={handleClearAllFilter}
        id={`${key}-header`}
        filterAppliedKey={filterAppliedKey}
        setFilterAppliedKey={setFilterAppliedKey}
        setInitialFilterOpt={initialFilterOptionUpdate}
      />
    );
  };

  const renderColumnAction = () => {
    return (
      <div className="flex items-center hover:text-secondary">
        <span className="mr-2">
          <AiOutlineEdit />
        </span>
        <span>Edit</span>
      </div>
    );
  };

  const renderHeaders = () => {
    return (
      <ColumnGroup>
        <Row>
          {isCheckBoxMode && (
            <Column
              align={isAppEditable ? "left" : "center"}
              header={(props) => renderCheckBox("head", props)}
              field="isChecked"
              frozen
              headerClassName={checkBoxHeaderClassName}
            />
          )}
          {column &&
            column.length > 0 &&
            column.map((col, colIndex) => {
              const uniqueKey = `${col.field}-${colIndex}`;
              let customStyle = {};
              const lastColumn = colIndex + 1 === column.length;
              const customBody =
                col.field.toLowerCase() === "cost" ||
                col.field.toLowerCase() === "resource count" ||
                col.field.toLowerCase() === "quantity" ||
                col.field.toLowerCase() === "count" ||
                (col && col.type);
              const cellType =
                col && col.type ? col.type : col.field.toLowerCase();
              const isRightAlign =
                cellType === "cost" ||
                cellType === "resource count" ||
                cellType === "quantity" ||
                cellType === "count";
              const isRoles = cellType === "action_view";
              const columnFrozen = Boolean(col.frozen);
              const sortable = Object.prototype.hasOwnProperty.call(
                col,
                "sortable"
              )
                ? col.sortable
                : true;
              const header = col.headerType ? (
                <TableCustomHeader
                  type={col.headerType}
                  customSearch={col.isCustomSearch}
                  header={col.label}
                  field={col.field}
                  headerProps={customHeaderProps}
                  filters={filters}
                  setFilters={setFilters}
                />
              ) : (
                col.label
              );
              const filter = Object.prototype.hasOwnProperty.call(col, "filter")
                ? col.filter
                : isFilter;
              if (col.width) {
                if (isCustomHeaderWidth) {
                  customStyle.maxWidth = col.width;
                  customStyle.fontFamily = 'roboto, sans-serif';
                } else {
                  customStyle.minWidth = col.width;
                  customStyle.fontFamily = 'roboto, sans-serif';
                }
              }
              if (col.style) customStyle = { ...customStyle, ...col.style };
              if (col.field === "id") {
                return (
                  <Column
                    key={uniqueKey}
                    field={col.field}
                    header={header}
                    body={(a, b) => renderCustomColBody(a, b, cellType)}
                    filterHeader={(e) => renderFilterOverlay(e, rowData, col, uniqueKey)}
                    align={isRoles ? 'right' : 'left'}
                    style={customStyle}
                    headerClassName={`${uniqueKey}-header ${
                      col?.headerClassName
                    } ${lastColumn && "lastColumnHeader"}`}
                    frozen={columnFrozen}
                  />
                );
              }
              return (
                
                <Column
                  header={header}
                  align={isRightAlign && "right"}
                  key={uniqueKey}
                  field={col.field}
                  sortable={col.field != "Key"}
                  body={
                    customBody
                      ? (a, b) => renderCustomColBody(a, b, cellType)
                      : null
                  }
                  filterHeader={(e) => renderFilterOverlay(e, rowData, col, uniqueKey)}
                  style={customStyle}
                  frozen={columnFrozen}
                  headerClassName={`${uniqueKey}-header ${
                    col?.headerClassName
                  }  ${lastColumn && "lastColumnHeader"}`}
                />
              );
            })}
        </Row>
        {isTotalCost && (
          <Row>
            {isCheckBoxMode && (
              <Column header={null} className="secondary-header" frozen />
            )}
            {column &&
              column.length > 0 &&
              column.map((col, colIndex) => {
                const uniqueKey = `${col.field}-${colIndex}`;
                const isCostColumn =
                  (col.field && col.field.toLowerCase() === "cost") ||
                  (col.groupLabel && col.groupType !== "header");
                const isZeroColumn = col.groupLabel === 0;
                const header = col.groupLabel
                  ? col.groupType === "number"
                    ? formatCommas(col.groupLabel)
                    : col.groupType !== "header"
                    ? `$${formatCommas(col.groupLabel)}`
                    : col.groupLabel
                  : colIndex === 0
                  ? "Total Cost"
                  : isCostColumn
                  ? `$${totalCost ? formatCommas(totalCost) : 0}`
                  : isZeroColumn
                  ? 0
                  : "";
                const columnFrozen = Boolean(col.frozen);
                return (
                  <Column
                    header={header}
                    align={isCostColumn || isZeroColumn ? "right" : "left"}
                    key={uniqueKey}
                    className="secondary-header"
                    frozen={columnFrozen}
                  />
                );
              })}
          </Row>
        )}
      </ColumnGroup>
    );
  };
  const renderCellByType = (value, type, row, field) => {
    let label = "";
    let fullName = "";
    let activeClass = "";
    if (type === "user_profile") {
      const nameSet = value.toUpperCase().split(" ");
      label =
        row.last_name && typeof row.last_name === "string"
          ? `${value.toUpperCase()[0]}${row.last_name.toUpperCase()[0]}`
          : nameSet.length > 1
          ? nameSet.map((name) => name[0])
          : nameSet[0].slice(0, 2);
      fullName = `${row.name} ${row.last_name ? row.last_name : ""}`;
    }
    if (type === "hyperLink") {
      if (Object.prototype.hasOwnProperty.call(row, "active")) {
        if (row.active) {
          activeClass = "activeCell text-secondary !underline";
        } else {
          activeClass = "inActiveCell cursor-default";
        }
      }
    }
    switch (type) {
      case "cost":
        return (
          <div className="max-h-12">${value ? formatCommas(value) : "0"}</div>
        );
      case "count":
        return (
          <div className="max-h-12">{value ? formatCommas(value) : "0"}</div>
        );
      case "toggle":
        return (
          <div className="flex justify-center max-h-12">
            <Dropdown
              value={row.required}
              onChange={(e) => handleRequiredChange(row)}
              options={userInputOpt}
              optionLabel="name"
              className={`${
                row.required === "Yes"
                  ? "positive_dropdown"
                  : "negative_dropdown"
              }`}
              dropdownIcon={
                <GoTriangleDown className="text-secondary text-lg" />
              }
            />
          </div>
        );
      case "resource count":
      case "quantity":
        return (
          <div className="max-h-12">{value ? formatCommas(value) : "0"}</div>
        );
      case "date":
        return (
          <div className="max-h-12">
            {moment(new Date(value)).format("lll")}
          </div>
        );
      case "null_field":
        return <div className="max-h-12">{value !== null ? value : "-"}</div>;

      case "user_profile":
        return (
          <div className="flex items-center max-h-12">
            <Avatar
              label={label}
              style={{
                backgroundColor: "#297cc5",
                color: "#ffffff",
                borderRadius: "25%",
                width:"25px",
                height:"25px",
                fontSize:'small'
              }}
            />
            <div className="mx-3">{fullName}</div>
          </div>
        );
      case "restricted_action":
        return (
          <div className={`flex ${cellHeight} max-h-12`}>
            <Tooltip target=".editButton" className="custom_tooltip" />
            <div
              className="px-2 border-r border-slate-300 editButton"
              aria-hidden="true"
              data-pr-tooltip="Edit"
              data-pr-position="left"
              onClick={(e) => onEdit(e, row)}
            >
              <MdOutlineModeEdit className="text-gray-400 hover:text-secondary" />
            </div>
            <Tooltip target=".deleteButton" className="custom_tooltip" />
            <div className="px-2">
              <RiDeleteBin6Line
                className="text-gray-400 hover:text-red-500 deleteButton"
                aria-hidden="true"
                data-pr-tooltip="Delete"
                data-pr-position="right"
                onClick={(e) => onDelete(e, row)}
              />
            </div>
          </div>
        );
      case "action":
        return (
          <div className={`flex ${cellHeight} max-h-12`}>
            <Tooltip target=".viewTool" />
            <div
              className="px-2 border-r border-slate-300 viewTool"
              aria-hidden="true"
              data-pr-tooltip="View"
              data-pr-position="left"
              onClick={(e) => onView(e, row)}
            >
              <BsEye className="text-gray-400 hover:text-secondary" />
            </div>
            <Tooltip target=".deleteTool" />
            <div className="px-2">
              <RiDeleteBin6Line
                className="text-gray-400 hover:text-red-500 deleteTool"
                aria-hidden="true"
                data-pr-tooltip="Delete"
                data-pr-position="right"
                onClick={(e) => onDelete(e, row)}
              />
            </div>
          </div>
        );
      case "action_view":
        return (
          <div className={`flex ${cellHeight} max-h-12`}>
            <Tooltip target=".viewTool" />
            <div
              className=" ml-auto px-2 border-r border-slate-300 viewTool "
              aria-hidden="true"
              data-pr-tooltip="View"
              data-pr-position="left"
              onClick={(e) => onView(e, row)}
            >
              <BsEye className="text-gray-400 hover:text-secondary" />
            </div>
            <Tooltip target=".editButton" />
            <div
              className="px-2  editButton "
              aria-hidden="true"
              data-pr-tooltip="Edit"
              data-pr-position="left"
              onClick={(e) => onEdit(e, row)}
            >
              <MdOutlineModeEdit className="text-gray-400 hover:text-secondary" />
            </div>
          </div>
        );
      case "edit_action":
        return (
          <div className={`flex ${cellHeight} max-h-12`}>
            {isShareAndScheduleMode && (
              <div className="flex">
                <Tooltip target=".viewTool" className="custom_tooltip" />
                <div className={"px-2 border-r border-slate-300 "}>
                  <TbShareOff
                    className={` ${
                      Boolean(row.share) ? "text-blue-400" : "!text-gray-400"
                    } viewTool`}
                    aria-hidden="true"
                    data-pr-tooltip="Stop Share"
                    data-pr-position="bottom"
                    onClick={
                      Boolean(row.share)
                        ? (e) => onShare(e, row)
                        : onDisabledAction
                    }
                  />
                </div>
                <Tooltip target=".viewTool" className="custom_tooltip" />
                <div className="px-2 border-r border-slate-300">
                  <MdOutlineStopScreenShare
                    className={` ${
                      Boolean(row.schedule) ? "text-blue-400" : "!text-gray-400"
                    } viewTool`}
                    aria-hidden="true"
                    data-pr-tooltip=" Stop Schedule"
                    data-pr-position="bottom"
                    onClick={
                      Boolean(row.schedule)
                        ? (e) => onSchedule(e, row)
                        : onDisabledAction
                    }
                  />
                </div>
              </div>
            )}

            <Tooltip target=".editButton" className="custom_tooltip" />
            <div
              className="px-2 border-r border-slate-300 editButton"
              aria-hidden="true"
              data-pr-tooltip="Edit"
              data-pr-position="bottom"
              onClick={(e) => onEdit(e, row)}
            >
              <MdOutlineModeEdit className="text-gray-400 hover:text-secondary" />
            </div>
            <Tooltip target=".deleteButton" className="custom_tooltip" />
            <div className="px-2">
              <RiDeleteBin6Line
                className="text-gray-400 hover:text-red-500 deleteButton"
                aria-hidden="true"
                data-pr-tooltip="Delete"
                data-pr-position="bottom"
                onClick={(e) => onDelete(e, row)}
              />
            </div>
          </div>
        );
      case "delete_only":
        return (
          <div className={`flex ${cellHeight} max-h-12`}>
            {isShareAndScheduleMode && (
              <div className="flex">
                <Tooltip target=".viewTool" className="custom_tooltip" />
                <div className={"px-2 border-r border-slate-300 "}>
                  <TbShareOff
                    className={` ${
                      Boolean(row.share) ? "text-blue-400" : "!text-gray-400"
                    } viewTool`}
                    aria-hidden="true"
                    data-pr-tooltip="Stop Share"
                    data-pr-position="bottom"
                    onClick={
                      Boolean(row.share)
                        ? (e) => onShare(e, row)
                        : onDisabledAction
                    }
                  />
                </div>
                <Tooltip target=".viewTool" className="custom_tooltip" />
                <div className="px-2 border-r border-slate-300">
                  <MdOutlineStopScreenShare
                    className={` ${
                      Boolean(row.schedule) ? "text-blue-400" : "!text-gray-400"
                    } viewTool`}
                    aria-hidden="true"
                    data-pr-tooltip=" Stop Schedule"
                    data-pr-position="bottom"
                    onClick={
                      Boolean(row.schedule)
                        ? (e) => onSchedule(e, row)
                        : onDisabledAction
                    }
                  />
                </div>
              </div>
            )}

            <Tooltip target=".deleteButton" className="custom_tooltip" />
            <div className="px-2">
              <RiDeleteBin6Line
                className="text-gray-400 hover:text-red-500 deleteButton"
                aria-hidden="true"
                data-pr-tooltip="Delete"
                data-pr-position="bottom"
                onClick={(e) => onDelete(e, row)}
              />
            </div>
          </div>
        );
      case "edit_only":
        return (
          <div className={`flex ${cellHeight} max-h-12`}>
            {isShareAndScheduleMode && (
              <div className="flex">
                <Tooltip target=".viewTool" className="custom_tooltip" />
                <div className={"px-2 border-r border-slate-300 "}>
                  <TbShareOff
                    className={` ${
                      Boolean(row.share) ? "text-blue-400" : "!text-gray-400"
                    } viewTool`}
                    aria-hidden="true"
                    data-pr-tooltip="Stop Share"
                    data-pr-position="bottom"
                    onClick={
                      Boolean(row.share)
                        ? (e) => onShare(e, row)
                        : onDisabledAction
                    }
                  />
                </div>
                <Tooltip target=".viewTool" className="custom_tooltip" />
                <div className="px-2 border-r border-slate-300">
                  <MdOutlineStopScreenShare
                    className={` ${
                      Boolean(row.schedule) ? "text-blue-400" : "!text-gray-400"
                    } viewTool`}
                    aria-hidden="true"
                    data-pr-tooltip=" Stop Schedule"
                    data-pr-position="bottom"
                    onClick={
                      Boolean(row.schedule)
                        ? (e) => onSchedule(e, row)
                        : onDisabledAction
                    }
                  />
                </div>
              </div>
            )}
            <Tooltip target=".editButton" className="custom_tooltip" />
            <div
              className="px-2 editButton"
              aria-hidden="true"
              data-pr-tooltip="Edit"
              data-pr-position="bottom"
              onClick={(e) => onEdit(e, row)}
            >
              <MdOutlineModeEdit className="text-gray-400 hover:text-secondary" />
            </div>
          </div>
        );
      case "alert_type":
        return (
          <div
            className={`text-${
              value === "threshold" ? "orange" : "green"
            }-500 capitalize font-medium max-h-12`}
          >
            {value}
          </div>
        );
      case "is_schedule_only":
        return (
          <div className="flex justify-center items-center max-h-12">
            <Tooltip target=".viewTool" className="custom_tooltip" />
            {isScheduleMode && (
              <div>
                <MdOutlineStopScreenShare
                  className={` ${
                    !row.isRestriction && Boolean(row.schedule)
                      ? "text-blue-400"
                      : "!text-gray-400"
                  } viewTool`}
                  aria-hidden="true"
                  data-pr-tooltip="Stop Schedule"
                  data-pr-position="bottom"
                  onClick={
                    !row.isRestriction && Boolean(row.schedule)
                      ? (e) => onSchedule(e, row)
                      : onDisabledAction
                  }
                />
              </div>
            )}
          </div>
        );
      case "info":
        return renderInfo(value, row, field);
      case "truncateCell":
        return (
          <div
            className={`${!truncateNone && "w-[25rem]"} truncateCell max-h-12`}
            data-pr-tooltip={value}
            data-pr-position="bottom"
          >
            <Tooltip target=".truncateCell" className="custom_tooltip" />
            {value}
          </div>
        );
      case "hyperLink":
        return (
          <div className={activeClass} onClick={(e) => onView(e, row)}>
            {value}
          </div>
        );
      case "unassign":
        return <div className="max-h-12">{value || "N/A"}</div>;
      default:
        return <div />;
    }
  };

  const renderCustomColBody = (row, currentRowData, type) => {
    const field =
      currentRowData && currentRowData.field ? currentRowData.field : "";
    const value = row && field ? _.get(row, field) : null;
    return renderCellByType(value, type, row, field);
  };

  const renderColumn = () => {
    return (
      column &&
      column.length > 0 &&
      column.map((col, colIndex) => {
        const uniqueKey = `${col.field}-${colIndex}`;
        let customStyle = {};
        const customBody =
          col.field.toLowerCase() === "cost" ||
          col.field.toLowerCase() === "resource count" ||
          col.field.toLowerCase() === "quantity" ||
          (col && col.type);
        const cellType =
          isInfoMode && colIndex === 0
            ? "info"
            : col && col.type
            ? col.type
            : col.field.toLowerCase();
        const isRightAlign =
          cellType === "cost" ||
          cellType === "resource count" ||
          cellType === "quantity" ||
          cellType === "count";
        const isCustomBody = col.field === "id" || cellType === "info";
        const columnFrozen = Boolean(col.frozen);
        const sortable = Object.prototype.hasOwnProperty.call(col, "sortable")
          ? col.sortable
          : true;
        const header = col.headerType ? (
          <TableCustomHeader
            type={col.headerType}
            header={col.label}
            field={col.field}
            headerProps={customHeaderProps}
          />
        ) : (
          col.label
        );
        if (col.width) {
          if (isCustomHeaderWidth) {
            customStyle.minWidth = col.width;
            customStyle.fontFamily = 'roboto, sans-serif';

          } else {
            customStyle.minWidth = col.width;
            customStyle.fontFamily = 'roboto, sans-serif';
          }
        }
        if (col.style) customStyle = { ...customStyle, ...col.style };

        if (isCustomBody) {
          return (
            <Column
              key={uniqueKey}
              field={col.field}
              header={header}
              body={(a, b) => renderCustomColBody(a, b, cellType)}
              style={
                colIndex === 0
                  ? { color: flag ? "#297cc5" : "#333333", ...customStyle }
                  : null
              }
              frozen={columnFrozen}
            />
          );
        }

        return (
          <Column
            align={isRightAlign && "right"}
            key={uniqueKey}
            field={col.field}
            header={header}
            sortable={sortable}
            body={
              customBody ? (a, b) => renderCustomColBody(a, b, cellType) : null
            }
            style={customStyle}
            frozen={columnFrozen}
          />
        );
      })
    );
  };

  const updateSearchValue = () => {
    onGlobalFilterChange({ target: { value: customSearchInput } });
  };

  const handleCheckBoxChange = (isHeader, data, originalEvent) => {
    const isChecked = originalEvent.target.checked;
    if (isHeader) {
      if (handleSelectAll && typeof handleSelectAll === "function")
        handleSelectAll(isChecked);
    } else {
      if (handleSingleSelect && typeof handleSingleSelect === "function")
        handleSingleSelect(isChecked, data);
    }
  };

  const handleDownload = (params, done) => {
    const dataList = isCheckBoxMode
      ? rowData.filter((doc) => doc.isChecked)
      : rowData;
    if (dataList && dataList.length > 0) {
      const csvData = [];
      for (const row of dataList) {
        const obj = {};
        for (const { field, label } of column) {
          if (label && !label.toLowerCase().includes("action")) {
            obj[label] = _.get(row, field) || 0;
          }
        }
        csvData.push(obj);
      }
      setExcelData(csvData);
      done();
    } else {
      alert("Please select at least one row");
      done(false);
    }
  };

  const renderCheckBox = (type, row, rowProps) => {
    const isHeader = type === "head";
    const value = isHeader
      ? row.props.value.every((doc) => doc.isChecked)
      : row[rowProps.field];
    const data = isHeader ? row.props.value : row;
    return (
      <div className="flex !justify-evenly !items-center">
        <input
          className=" more relative mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-300 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0  before:content-[''] checked:border-secondary checked:bg-secondary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)]"
          type="checkbox"
          checked={value}
          // onChange={(e) => {
          //   e.stopPropagation();
          //   handleCheckBoxChange(isHeader, data, e);
          // }}
        />
        {row.active && isAppEditable && !isHeader && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              handleAppEdit(row);
            }}
          >
            <MdOutlineEdit className="w-12px" />
          </span>
        )}
        {!row.active && isAppEditable && !isHeader && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              handleAppCreate(row);
            }}
          >
            <IoMdAddCircleOutline className="w-14px" />
          </span>
        )}
      </div>
    );
  };

  const header = renderHeaders();
  const paginatorRight = (
    <div className="flex items-center">
      {isExpander && (
        <span
          className="mr-2 text-secondary cursor-pointer"
          aria-hidden
          onClick={() => setVisible(true)}
        >
          <GiExpand className="w-5 h-5" />
        </span>
      )}
      {isSave && (
        <Button
          type="button"
          icon="pi pi-save"
          disabled={!changed}
          className="icon_btn_primary !mx-3"
          style={{ color: "white" }}
          onClick={handleSave}
        />
      )}
      {!disableDownload && (
        <Button
          type="button"
          icon="pi pi-download"
          className="icon_btn_primary"
          text
          style={{ color: "white" }}
        />
      )}
    </div>
  );

  const handleTableValueChange = (tableData) => {
    if (tableData && Array.isArray(tableData)) {
      const totalAmount = tableData.reduce(
        (acc, doc) => acc + Number(doc.Cost),
        0
      );
      if (getTotalCost && typeof getTotalCost === "function")
        getTotalCost(totalAmount);
    }
  };

  const selectedKeyFilter = (value, b) => {
    if (b && Array.isArray(b) && b.length > 0) {
      return b.includes(value);
    }
    return true;
  };

  const betweenFilter = (val, fieldValue) => {
    if (Array.isArray(fieldValue) && fieldValue.length === 2) {
      const value = val && typeof val === "string" ? Number(val) : val;
      const min = fieldValue[0];
      const max = fieldValue[1];
      return min <= value && max >= value;
    }
    return true;
  };

  const scrollToLastColumnFn = () => {
    const table = document.getElementsByClassName("p-datatable-wrapper");
    const lastColumn = document.getElementsByClassName("lastColumnHeader")?.[0];
    const wrapper = table?.[0];
    if (wrapper) {
      wrapper.scrollLeft = lastColumn?.getBoundingClientRect()?.left;
    }
  };

  useEffect(() => {
    if (
      disableHeader &&
      typeof customSearchInput === "string" &&
      Object.keys(filters).length > 0
    ) {
      updateSearchValue();
    }
  }, [customSearchInput, disableHeader]);

  useEffect(() => {
    if (
      column &&
      Array.isArray(column) &&
      column.length > 0 &&
      Object.keys(filters).length === 0
    ) {
      initialFilterOptionUpdate();
    }
    if (scrollToLastColumn) {
      scrollToLastColumnFn();
    }
  }, [column, filters]);

  FilterService.register("array_includes", selectedKeyFilter);
  FilterService.register("number_between", betweenFilter);

  if (isSkeletonLoading || Object.keys(filters).length === 0) {
    return <div className="border rounded-xl shadow"></div>;
  }
  return (
    <div className="">
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        className="expander_modal"
      >
        <header className="flex justify-end py-3">
          <div
            className="p-3 rounded-full hover:bg-blue-100"
            aria-hidden
            onClick={() => setVisible(false)}
          >
            <MdClose className="text-secondary" />
          </div>
        </header>
        <CubeTable {...childProps} />
      </Dialog>
      {rowData.length === 0 || isError ? (
        <div className="w-full h-[512px] shadow"></div>
      ) : 
      (
        <DataTable
          sortField={sortField}
          sortOrder={-1}
          value={Array.isArray(rowData) ? rowData : []}
          paginator={removePaginator}
          rows={rowSize}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          rowsPerPageOptions={
            rowsPerPageOptions ? rowsPerPageOptions : [5, 12, 25, 50]
          }
          paginatorRight={paginatorRight}
          filters={filters}
          headerColumnGroup={header}
          loading={isLoading}
          onRowSelect={(e) => {
            initialFilterOptionUpdate();
            if (handleRowSelect && typeof handleRowSelect === "function") {
              handleRowSelect(e);
            } else {
              onView(e.originalEvent, e.data);
            }
          }}
          width="100%"
          selectionMode="single"
          className="shadow overflow-hidden cube-table"
          scrollable
          scrollHeight={minHeight}
          onValueChange={handleTableValueChange}
          selectionAutoFocus={false}
        >
          {isCheckBoxMode && (
            <Column
              align={"right"}
              header={(props) => renderCheckBox("head", props)}
              field="isChecked"
              body={(row, event) => renderCheckBox("body", row, event)}
              frozen
              headerClassName={checkBoxHeaderClassName}
            />
          )}
          {renderColumn()}
        </DataTable>
      )
      }
    </div>
  );
}

CubeTable.prototype = {
  handleDownload: PropsType.func,
  handleRowSelect: PropsType.func,
};

export default CubeTable;
