import ReactEcharts from "echarts-for-react";
import {
  formatCommas,
  formatCurrencyZeroRound,
} from "../../../helpers/helpers";

function EMeterChart({
  totalKey = 12000,
  currentVal = 10256,
  minHeight = "280px",
  meterLabel = "Tag Maturity",
}) {
  const options = {
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: totalKey,
        splitNumber: 1,
        itemStyle: {
          color: "#4183C5",
          shadowColor: "rgba(0,138,255,0.45)",
          shadowBlur: 10,
          shadowOffsetX: 1,
          shadowOffsetY: 1,
        },
        progress: {
          show: true,
          roundCap: true,
          width: 14,
        },
        pointer: {
          icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
          length: "75%",
          width: 10,
          offsetCenter: [0, "5%"],
          color: "red",
          value: 0,
        },
        axisLine: {
          roundCap: true,
          lineStyle: {
            width: 14,
          },
        },
        axisTick: {
          splitNumber: 1,
          lineStyle: {
            width: 2,
            color: "#999",
          },
        },
        splitLine: {
          length: 2,
          lineStyle: {
            width: 3,
            color: "#999",
          },
        },
        axisLabel: {
          distance: 25,
          color: "#999",
          fontSize: 8,
        },
        title: {
          show: false,
        },
        detail: {
          backgroundColor: "#fff",
          width: "60%",
          fontSize: "15",
          fontFamily: "Neue Montreal, sans-serif",
          fontWeight: "300",
          lineHeight: 30,
          height: 50,
          offsetCenter: [0, "35%"],
          valueAnimation: true,
          formatter() {
            return `${
              totalKey && meterLabel ? meterLabel : "Tag Maturity"
            }\n  ${
              totalKey && meterLabel
                ? `Out of 12,000 resources,\n ${formatCommas(
                    currentVal
                  )} are tagged with atleast one key`
                : `Out of ${formatCommas(12000)} resources,\n ${formatCommas(
                    currentVal
                  )} are tagged with \n `
            }`;
          },
          rich: {
            value: {
              fontSize: 25,
              fontWeight: "bolder",
              color: "#777",
            },
            unit: {
              fontSize: 10,
              color: "#999",
              padding: [0, 0, -20, 10],
            },
          },
        },
        data: [
          {
            value: currentVal,
          },
        ],
      },
    ],
  };

  return (
    <div>
      <ReactEcharts
        option={options}
        style={{ width: "270px", height: "250px" }}
      />
    </div>
  );
}

export default EMeterChart;
