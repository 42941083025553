import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { IoLocationOutline, IoMailOutline } from "react-icons/io5";
import { MdOutlineLocalPhone } from "react-icons/md";
import Rocket from "../assets/icons/rocket.svg";
import RocketInv from "../assets/icons/rocket_inv.svg";
import { Toast } from "primereact/toast";
import { isValidEmail } from "../helpers/helpers";
import PhoneInput from "react-phone-number-input";
import "../Components/Take_A_Tour/Common/phoneNumber.css";
import "../css/MobileContactForm.css";

import { Dialog } from "primereact/dialog";

const ContactForm = () => {
    const [form, setForm] = useState({});
    const [isHovered, setIsHovered] = useState(false);

    const toast = useRef(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [captchaText, setCaptchaText] = useState("");
    const [userInput, setUserInput] = useState("");
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

    // Generate a random string for the CAPTCHA
    const generateCaptcha = () => {
        const captchaChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let captcha = "";
        for (let i = 0; i < 6; i++) {
            captcha += captchaChars.charAt(Math.floor(Math.random() * captchaChars.length));
        }
        setCaptchaText(captcha);
    };

    const openModal = () => {
        if (!isValidEmail(form.email)) {
            showError("Please enter valid mail!");
            return;
        }

        if (form.phone.length > 13) {
            showError("Please enter valid phone number!");
            return;
        }

        if (!form.name || !form.organization || !form.email || !form.phone || !form.message) {
            showWarn();
            return;
        }
        setIsModalOpen(true);
        generateCaptcha(); // Generate CAPTCHA when modal opens
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setUserInput(""); // Clear user input when modal closes
    };

    const verifyCaptcha = () => {
        if (userInput === captchaText) {
            setIsCaptchaVerified(true);
            // alert('CAPTCHA Verified!');
            handleSubmit();
            closeModal();
        } else {
            setIsCaptchaVerified(false);
            alert("Incorrect CAPTCHA. Please try again.");
            generateCaptcha(); // Regenerate CAPTCHA on failure
        }
    };

    const showSuccess = () => {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Message Content",
            life: 3000,
        });
    };

    const showError = (msg) => {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: msg,
            life: 3000,
        });
    };

    const showWarn = () => {
        toast.current.show({
            severity: "warn",
            summary: "Warn",
            detail: "Please fill all required fields",
        });
    };

    const handleChange = (label, e) => {
        setForm({
            ...form,
            [label]: label === "phone" ? e : e.target.value,
        });
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch("https://122.165.79.72:5000/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(form),
            });

            if (!response.ok) {
                showError("Something went wrong!");
                throw new Error("Failed to submit form");
            } else {
                showSuccess();
                setForm({});
                console.log(response, "successfull send");
            }
        } catch (error) {
            showError("Something went wrong!");
            // Handle errors here
            console.error("Error submitting form:", error.message);
            // Optionally, you can show an error message to the user
        }
    };

    return (
        <div id="contact" className="pt-16">
            <Toast ref={toast} />
            <section className="bg-secondary_light py-10 h-full contact__content ">
                <div className="container mx-auto">
                    <div className=" h-full bg-secondary_light flex-col justify-center items-center py-[25px]">
                        <div className="flex justify-around items-start gap-12 mobile__view__contactdetails">
                            <div className="mobile__view__contactform">
                                <div className="flex-col !mt-3">
                                    <span className="p-float-label">
                                        <InputText
                                            required
                                            type="text"
                                            value={form.name || ""}
                                            onChange={(e) => handleChange("name", e)}
                                            className="!border-b-2 !border-[#E3E5E9] custom-input  !mb-5"
                                        />
                                        <label className="absolute left-0 text-primary" htmlFor="name">
                                            Name *
                                        </label>
                                    </span>
                                </div>
                                <div className="flex justify-between gap-3 !mt-3 ">
                                    <span className="p-float-label">
                                        <InputText
                                            required
                                            type="text"
                                            value={form.organization || ""}
                                            onChange={(e) => handleChange("organization", e)}
                                            className="!border-b-2 !border-[#E3E5E9]  custom-row !mb-5"
                                        />
                                        <label className="absolute left-0 text-primary" htmlFor="organization">
                                            Organization *
                                        </label>
                                    </span>
                                    <span className="p-float-label">
                                        <PhoneInput
                                            international
                                            defaultCountry="US"
                                            value={form.phone || ""}
                                            onChange={(e) => handleChange("phone", e)}
                                            className="!border-b-2 !border-[#E3E5E9] custom-row !mb-5"
                                        />
                                    </span>
                                </div>
                                <div className="flex-col !mt-3">
                                    <span className="p-float-label">
                                        <InputText
                                            required
                                            type="email"
                                            value={form.email || ""}
                                            onChange={(e) => handleChange("email", e)}
                                            className="!border-b-2 !border-[#E3E5E9] custom-input !mb-5"
                                        />
                                        <label className="absolute left-0 text-primary" htmlFor="email">
                                            Email *
                                        </label>
                                    </span>
                                </div>
                                <div className="flex-col !mt-3">
                                    <span className="p-float-label">
                                        <InputTextarea
                                            required
                                            type="textarea"
                                            value={form.message || ""}
                                            onChange={(e) => handleChange("message", e)}
                                            rows={0}
                                            cols={30}
                                            className="!border-b-2 !border-[#E3E5E9] custom-input  !mb-6 textarea__content"
                                        />
                                        <label className="absolute left-0 text-primary" htmlFor="message">
                                            Message *
                                        </label>{" "}
                                    </span>
                                </div>
                                <div className="flex justify-start items-center !w-[200px]">
                                    <Button onClick={openModal} label="Submit" className="btn-grad-submit rounded-lg" />
                                </div>
                            </div>
                            <div className="mobile__view__contactcontent xl:w-1/2 lg:w-1/2 md:w-1/2 md:max-lg:w-1/3">
                                <h2 className="md:text-[35px] lg:text-[35px] text-[25px] xl:text-[35px] text-primary  leading-[50px] font-medium">
                                    Connect with us for Seamless FinOps Solutions
                                    <span className="dot">.</span>
                                </h2>
                                <br />
                                <div>
                                    <div className="flex items-center">
                                        <div className="w-[75px]">
                                            <button className="location__icon">
                                                <IoLocationOutline />
                                            </button>
                                        </div>
                                        <div className="w-[250px]">
                                            <h3 className="font-medium font-sans text-2xl">Texas</h3>
                                            <p className="font-normal">McKinney, TX 75070.</p>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="flex items-center">
                                        <div className="w-[75px]">
                                            <button className="location__icon">
                                                <IoMailOutline />
                                            </button>
                                        </div>
                                        <div className="w-[250px]">
                                            <p>hey@cubesimple.com</p>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="flex items-center">
                                        <div className="w-[75px]">
                                            <button className="location__icon">
                                                <MdOutlineLocalPhone />
                                            </button>
                                        </div>
                                        <div className="w-[250px]">
                                            <p>+1 (908) 842-3758</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container mx-auto contact__banner z-10">
                <div className="border-2 flex contact__banner__partner rounded-lg divide-[#e52d27] bg-white justify-center divide-x">
                    <div
                        className="w-2/12 flex justify-center items-center"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <img src={isHovered ? Rocket : RocketInv} alt="rocket" />
                    </div>
                    <div className="w-10/12 px-7 py-3">
                        <h3 className="text-primary xl:text-[20px] lg:text-[20px] md:text-[20px] text-[15px]">
                            Partner With Us
                        </h3>
                        <h2 className="text__gradient">
                            Optimize Your Financial Strategy with Our Personalized Solutions?
                        </h2>
                        <p className="text-primary xl:text-[15px] lg:text-[15px] md:text-[15px] text-[12px]">
                            Partner with us to streamline your cloud transformation journey and propel your financial
                            processes to new heights. Unleash the power of personalized FinOps solutions for a seamless
                            and efficient path to financial excellence.
                        </p>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <Dialog
                    header="Please enter the characters shown below:"
                    visible={isModalOpen}
                    style={{ width: "500px" }}
                    onHide={closeModal}
                    resizable={false}
                    draggable={false}
                    dismissableMask
                >
                    <div className="text-center">
                        <div className="p-field my-1 flex justify-center items-center">
                            {captchaText && (
                                <div className="flex justify-center items-center bg-[#003971] h-[40px] w-[100px] text-white rounded-sm">
                                    {" "}
                                    {captchaText}
                                </div>
                            )}
                            &nbsp;
                            <InputText
                                type="text"
                                className="border"
                                value={userInput}
                                onChange={(e) => setUserInput(e.target.value)}
                                placeholder="Enter CAPTCHA"
                            />
                        </div>
                        <button className="btn-grad-blue" onClick={verifyCaptcha}>
                            Verify
                        </button>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default ContactForm;
