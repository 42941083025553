import React, { useState } from "react";
import Azure from "../assets/icons/Azure.png";
import AWS from "../assets/icons/aws.png";
import Oracle from "../assets/icons/oracle.png";
import SnowFlake from "../assets/icons/snowflake.png";
import Databricks from "../assets/icons/databricks.png";
import ContactModalForm from "./ContactModalForm";
import "../css/MobileHeader.css"
const Header = () => {
  const [modalVisible, setModalVisible] = useState(false);

    const toggleModalVisibility = () => {
        setModalVisible(!modalVisible);
    };
  const cloudImg = [Azure, AWS, Oracle, SnowFlake, Databricks];
  return (
    <div className="bg-secondary_light">
      <div className=" container mx-auto">
        <div className="flex flex-col items-center justify-start py-40 space-y-10 web__view__header ">
          <h1 className="text-primary text-center font-medium font-sans text-4xl leading-normal">
            Gain control over your cloud spend<span className="dot">.</span>
            <br />
            Introduce your business context to your cloud
            <span className="dot">.</span>
            <br />
            Optimize your footprint,{" "}
            <i className="font-light">one business component at a time</i>
            <span className="dot">.</span>
          </h1>
          <p className="text-primary text-center w-[750px]">
            CloudLens is a one-stop solution for your enterprise Cloud FinOps
            with <br />
            User-controlled Show-back Models, Anomaly Detection, Resource Tag
            Governance,
            <br />
            and more!
          </p>
          <div>
            <button className="btn-grad rounded-lg"onClick={toggleModalVisibility} >
              Request free demo
            </button>
          </div>
          <div className="flex flex-row justify-center items-center">
            Works with &nbsp;
            {cloudImg.map((i, index) => (
              <img key={index} src={i} className={i===SnowFlake?'h-6 px-3':'h-6 px-1'} alt="cloud" />
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center justify-start py-20 px-10 space-y-10 mobile__view__header ">
          <h1 className="text-primary text-center font-medium font-sans text-3xl leading-normal">
            Gain control over your cloud spend<span className="dot">.</span>
            Introduce your business context to your cloud
            <span className="dot">.</span>
            Optimize your footprint,{" "}
            <i className="font-light">one business component at a time</i>
            <span className="dot">.</span>
          </h1>
          <p className="text-primary text-center">
            CloudLens is a one-stop solution for your enterprise Cloud FinOps
            with
            User-controlled Show-back Models, Anomaly Detection, Resource Tag
            Governance,
        
            and more!
          </p>
          <div>
            <button className="mobile-btn-grad rounded-lg"onClick={toggleModalVisibility} >
              Request free demo
            </button>
          </div>
          <div className="flex flex-row justify-center items-center">
            <span className="text-nowrap">Works with &nbsp;</span>
            {cloudImg.map((i, index) => (
              <img key={index} src={i} className={i===SnowFlake?'h-6 px-3':'h-6 px-1'} alt="cloud" />
            ))}
          </div>
        </div>
      </div>
      <ContactModalForm visible={modalVisible} setVisible={setModalVisible} />
    </div>
  );
};

export default Header;
