import { useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import { formatCommas } from '../../../helpers/helpers';
 
function EBasicHorizontalChart({  pricingModelDataFetching }) {
    const pricingModelData = {
        "status": true,
        "status_code": 200,
        "message": "Pricing model data listed successfully",
        "data": [
            {
                "PricingModel": "OnDemand",
                "Cost": "	233876.9",
                "Percentage": 88.79940472363886
            },
            {
                "PricingModel": "Reservation",
                "Cost": "	116072.9",
                "Percentage": 11.176057951659873
            },
            {
                "PricingModel": "Spot",
                "Cost": "50.14107",
                "Percentage": 0.024537324701276262
            }
        ]
    }
  const [isOnDemandHovered, setIsOnDemandHovered] = useState(false);
  const [isReservationHovered, setIsReservationHovered] = useState(false);
  const [isSpotHovered, setIsSpotHovered] = useState(false);
 
  const onDemandData = pricingModelData?.data?.filter((e) => e.PricingModel === 'OnDemand')[0];
  const reservationData = pricingModelData?.data?.filter(
    (e) => e.PricingModel === 'Reservation'
  )[0];
  const spotData = pricingModelData?.data?.filter((e) => e.PricingModel === 'Spot')[0];
  const getTooltipData = (data) => {
    return (
      <div className="flex items-center justify-center flex-wrap">
        <p className="whitespace-nowrap">{data?.PricingModel}:&nbsp;</p>
        <p className="whitespace-nowrap">${formatCommas(data?.Cost)}</p>
      </div>
    );
  };
 
  return (
    <div className="pb-2">
      <div className="flex items-center justify-start gap-2">
        <p className="text-slate-500 text-xs pb-4">Cost Breakdown by Pricing Model</p>
        <div className="flex justify-center items-center pl-2 pb-4 gap-4">
          <div className="flex justify-center items-center gap-1">
            <Tooltip target=".onDemandLabel" position="top" disabled={pricingModelDataFetching}>
              {getTooltipData(onDemandData)}
            </Tooltip>
            <div
              className="onDemandLabel h-3 w-6 bg-[#5470C8] rounded-sm"
              onMouseEnter={() => setIsOnDemandHovered(true)}
              onMouseLeave={() => setIsOnDemandHovered(false)}
            />
            <p className="text-slate-500 text-xs flex justify-center items-center">OnDemand</p>
          </div>
          <div className="flex justify-center items-center gap-1">
            <Tooltip target=".reservationLabel" position="top" disabled={pricingModelDataFetching}>
              {getTooltipData(reservationData)}
            </Tooltip>
            <div
              className="reservationLabel h-3 w-6 bg-[#91CC75] rounded-sm"
              onMouseEnter={() => setIsReservationHovered(true)}
              onMouseLeave={() => setIsReservationHovered(false)}
            />
            <p className="text-slate-500 text-xs flex justify-center items-center">Reservation</p>
          </div>
          <div className="flex justify-center items-center gap-1">
            <Tooltip target=".spotLabel" position="top" disabled={pricingModelDataFetching}>
              {getTooltipData(spotData)}
            </Tooltip>
            <div
              className="spotLabel h-3 w-6 bg-[#E96629] rounded-sm"
              onMouseEnter={() => setIsSpotHovered(true)}
              onMouseLeave={() => setIsSpotHovered(false)}
            />
            <p className="text-slate-500 text-xs flex justify-center items-center">Spot</p>
          </div>
        </div>
      </div>
      {pricingModelDataFetching && pricingModelData.length === 0 && !onDemandData ? (
        <div>
          <Skeleton className="w-full !h-12" />
        </div>
      ) : (
        <div className="pricingChartContainer">
          <div className="w-full flex h-12">
            <Tooltip target=".onDemandTooltip" position="top">
              {getTooltipData(onDemandData)}
            </Tooltip>
 
            <div
              style={{ width: `${onDemandData?.Percentage?.toFixed(2)}%` }}
              className={`bg-[#5470C8] pricingChart onDemandTooltip overflow-hidden text-white text-[12px] flex justify-center items-center ${
                isOnDemandHovered ? 'onDemand' : ''
              } text-sm`}>
              {onDemandData?.PricingModel}&nbsp;
              <span className="font-bold">{`${onDemandData?.Percentage?.toFixed(2)}%`}</span>
            </div>
            <Tooltip target=".reservationTooltip" position="top">
              {getTooltipData(reservationData)}
            </Tooltip>
            <div
              style={{ width: `${reservationData?.Percentage?.toFixed(2)}%` }}
              className={`bg-[#91CC75] pricingChart reservationTooltip overflow-hidden text-white text-[12px] flex justify-center items-center ${
                isReservationHovered ? 'reservation' : ''
              } text-sm`}>
              {' '}
              {reservationData?.PricingModel}&nbsp;
              <span className="font-bold">{`${reservationData?.Percentage?.toFixed(2)}%`}</span>
            </div>
            <Tooltip target=".spotTooltip" position="top">
              {getTooltipData(spotData)}
            </Tooltip>
            <div
              style={{ width: `${spotData?.Percentage?.toFixed(2)}%` }}
              className={`bg-[#E96629] pricingChart spotTooltip overflow-hidden text-white flex justify-center items-center ${
                isSpotHovered ? 'spot' : ''
              } text-sm`}>
              {' '}
              {spotData?.PricingModel}&nbsp;
              <span className="font-bold">{`${spotData?.Percentage?.toFixed(2)}%`}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default EBasicHorizontalChart;