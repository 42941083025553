import React from 'react';
import './Common/previewUnavailable.css'; // You'll need to create this CSS file
import { TbLock } from "react-icons/tb";

const PreviewUnavailableBYO = () => {
  return (
    <div className="modal-preview-container-byo pt-[30vh]">
      <div className="flex space-x-2 item-center modal-preview-content">
      <div><TbLock size={25}/></div>
      <p>Preview unavailable - request demo</p>
      </div>
    </div>
  );
}

export default PreviewUnavailableBYO;
