import { useEffect, useState } from "react";
import { Disclosure, Menu } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logoLens from "../assets/icons/logoLens.svg";
import { Link, useLocation } from "react-router-dom";
import Take_A_Tour from "./Take_A_Tour/Take_A_Tour";
import "../css/MobileNavbar.css";

const navigation = [
  { name: "Overview", subName: "About Us", href: "/#overview" },
  { name: "Solution", subName: "Leverage Tooling", href: "/#solution" },
  { name: "Product", subName: "Our Niche", href: "/#product" },
  { name: "Features", subName: "Our Accelerators", href: "/#features" },
  { name: "Contact", subName: "Contact Us", href: "/#contact" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const location = useLocation();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [currentNavItem, setCurrentNavItem] = useState(navigation[0].name);
  const [modalVisible, setModalVisible] = useState(false);

  const handleNavItemClick = (name) => {
    setCurrentNavItem(name);
  };

  const toggleModalVisibility = () => {
    setModalVisible(!modalVisible);
  };

  useEffect(() => {
    // Scroll to the section specified in the URL hash
    const hash = location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="bg-primary_theme sticky top-0 z-50">
      <Disclosure as="nav">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 right-3 flex items-center sm:hidden">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="relative flex items-center justify-center left-3">
                  <div className="flex flex-shrink-0 items-center">
                    <Link to="/">
                      <img
                        className="h-12 w-auto"
                        src={logoLens}
                        alt="CloudLens"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          onClick={() => handleNavItemClick(item.name)}
                          className={classNames(
                            item.current
                              ? "text-[#47b2e4]"
                              : "text-white hover:text-[#47b2e4]",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={
                            currentNavItem === item.current ? "page" : undefined
                          }
                        >
                          <div className="flex flex-col">
                            <span className="text-[15px]">{item.name}</span>
                            <span className="text-gray-400 text-[11px]">
                              {item.subName}
                            </span>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3 mobile__tour__btn">
                    <div>
                      <Menu.Button className="text-sm">
                        <span
                          className="border border-[#fffff] text-[#ffffff] hover:bg-[#0f83c4]  hover:border-[#0f83c4] hover:text-white px-10 py-2 rounded-lg"
                          onClick={toggleModalVisibility}
                        >
                          Take a Tour
                        </span>
                        {isTooltipVisible && (
                          <div className="absolute bg-gradient-to-br from-[#e52d27] via-[#cf545c] to-[#e52d27] text-white text-md px-5 ml-5 py-2 rounded mt-3 mx-auto z-10">
                            Coming Soon
                          </div>
                        )}
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-[#47b2e4] text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Take_A_Tour visible={modalVisible} setVisible={setModalVisible} />
    </div>
  );
}
