import React from "react";
// import '../../assets/css/common/cubeTable.css';
import { HiOutlineBellAlert, HiOutlineCircleStack } from "react-icons/hi2";
import { SlLayers } from "react-icons/sl";
import { BsSpeedometer2 } from "react-icons/bs";
import { ImTree } from "react-icons/im";

function Cards({ item, index, cardItems }) {
  let icon = null;
  let bgColor = "";
  let iconColor = "";

  switch (item.label) {
    // case "Subscriptions":
    //   icon = (
    //     <HiOutlineBellAlert className="w-4 h-4  md:w-6 lg:w-6 lg:h-6 md:h-6 font-bold" />
    //   );
    //   bgColor = "#E0FDF3";
    //   iconColor = "#27BD87";
    //   break;
    case "Resources":
      icon = (
        <SlLayers className="w-3 h-3 md:w-[22px] lg:w-[22px] lg:h-[22px] md:h-[22px] font-bold" />
      );
      bgColor = "#FAE8FF";
      iconColor = "#B120DA";
      break;
    case "Meter Categories":
      icon = (
        <BsSpeedometer2 className="w-4 h-4 md:w-6 lg:w-6 lg:h-4 md:h-6 font-bold" />
      );
      bgColor = "#E5F1FF";
      iconColor = "#308FFF";
      break;
    case "Total Cost":
      icon = (
        <HiOutlineCircleStack className="w-4 h-4 md:w-6 lg:w-6 lg:h-6  md:h-6 font-bold" />
      );
      bgColor = "#FFF6EF";
      iconColor = "#F57715";
      break;
    case "Resource Groups":
      icon = (
        <ImTree className="w-4 h-4 md:w-6 lg:w-6 lg:h-6  md:h-6 font-bold" />
      );
      bgColor = "#EBE8FF";
      iconColor = "#7E56EE";
      break;
    case "Products":
      icon = (
        <BsSpeedometer2 className="w-4 h-4  md:w-6 lg:w-6 lg:h-6 md:h-6 font-bold" />
      );
      bgColor = "#E0FDF3";
      iconColor = "#27BD87";
      break;
    case "Accounts":
      icon = (
        <HiOutlineBellAlert className="w-4 h-4 md:w-6 lg:w-6 lg:h-6 md:h-6 font-bold" />
      );
      bgColor = "#E5F1FF";
      iconColor = "#308FFF";
      break;
    default:
      icon = null;
  }

  const cardClass = `tracker_card${
    index === cardItems.length - 1
      ? "rounded-md shadow md:shadow md:rounded-md xl:rounded-none lg:rounded-none xl:shadow-none lg:shadow-none  md:border-r-2 md:border-white "
      : ""
  }`;

  return (
    <div className={cardClass}>
      <div className="h-[60px] md:h-16 xl:h-16 lg:h-16 bg-[#F9F9Fb] flex justify-start items-center card-container transition duration-300 ease-in-out cursor-pointer rounded-md md:rounded-md lg:rounded-none xl:rounded-none shadow md:shadow xl:shadow-none lg:shadow-none ">
        <div className="pt-1 flex item-center justify-center lg:justify-center md:items-center md:justify-center w-full md:pt-0 md:py-1.5 lg:py-1  md:pl-0 flex-col md:flex-row xl:flex-row lg:flex-row 2xl:flex-row">
          <div className="flex items-center justify-center sm:justify-center md:item-center md:justify-start lg:justify-start md:pt-2 lg:pt-0">
            <div
              className="w-6 h-6 md:w-12 lg:w-[3.25rem] lg:h-10 xl:w-[2.75rem] xl:h-10 md:h-10 shadow rounded md:rounded-lg flex items-center justify-center icon-container"
              style={{ backgroundColor: bgColor }}
            >
              {icon && React.cloneElement(icon, { color: iconColor })}
            </div>
            <div className="flex pl-2 md:pr-3 md:pl-3 lg:pl-0 lg:pr-0 flex-col justify-start md:justify-center lg:justify-center md:py-0 md:w-full">
              <div className="text-[11px] xl:w-[188px] sm:w-[115px] lg:w-[150px] md:w-[200px] flex-wrap md:flex-wrap item-center items-center justify-start md:text-sm lg:text-sm xl:text-lg text-gray-500 tracking-wide md:flex lg:flex">
                <div className="text-[10px] md:text-sm lg:text-sm xl:text-base font-bold text-gray-800 flex item-center justify-start md:justify-center md:pr-3 md:pl-3 lg:pl-5 lg:pr-5 xl:pl-2 xl:pr-2">
                  {item.label === "Total Cost" && <span>$</span>}
                  {(item && item.amount) || "0.00"}
                </div>
                <div className="mr-0 md:pl-2 lg:pl-5 lg:pr-0 xl:pl-0 xl:pr-0 flex item-center justify-start md:justify-center md:text-sm lg:text-xs xl:text-sm">
                  {item.label}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
