import React, { useState } from "react";
import EDoughNutChart from "./FinopsHealthComponents/EDoughNutChart";
import AppViewCard from "./FinopsHealthComponents/AppViewCard";
import EBasicHorizontalChart from "./FinopsHealthComponents/EBasicHorizontalChart";
import { InputSwitch } from "primereact/inputswitch";
import ChartCard from "./ChartCard";
import EMeterChart from "./FinopsHealthComponents/EMeterChart";
import EBasicLineChart from "./FinopsHealthComponents/EBasicLineChart";
import DashboardNegativeAnomaly from "./FinopsHealthComponents/DashboardNegativeAnomaly";
import DashboardPositiveAnomaly from "./FinopsHealthComponents/DashboardPositiveAnomaly";
import { SelectButton } from "primereact/selectbutton";
import Slider from "antd/lib/slider";
import { GoTriangleDown } from "react-icons/go";
import { Tooltip } from "primereact/tooltip";
import healthRawData from "./Jsonfiles/healthRawData.json";
import { getDateRange, modifyArray } from "../../helpers/helpers";
import { Dropdown } from "primereact/dropdown";

const marks = {
  0: {
    style: {
      fontSize: "10px",
    },
    label: "Very Low",
    value: 1,
  },
  25: {
    style: {
      fontSize: "10px",
    },
    label: "Low",
    value: 1.25,
  },
  50: {
    style: {
      fontSize: "10px",
    },
    label: "Moderate",
    value: 1.5,
  },
  75: {
    style: {
      fontSize: "10px",
    },
    label: "High",
    value: 1.75,
  },
  100: {
    style: {
      fontSize: "10px",
    },
    label: "VeryHigh",
    value: 2,
  },
};

const views = [
  { name: "All Keys", tagged: 10256 },
  { name: "Business App", tagged: 5645 },
  { name: "Resolver Group", tagged: 2896 },
  { name: "Issues Raised", tagged: 1489 },
];

function FinopsHealth() {
  const [taggedTags, setTaggedTags] = useState({
    name: "All Keys",
    tagged: 10256,
  });
  const handleXAxisLabelClick = () => {};
  const handleViewChange = (e) => {
    if (e.value) {
      setTaggedTags(e.value);
    } else {
      setTaggedTags({ name: "All Keys", tagged: 10256 });
    }
  };
  return (
    <div className=" p-1">
      <div className="flex justify-between pb-1 border-b-2">
        <span className="font-semibold ">Finops Health</span>
        <div>
          <Tooltip
            target=".amort"
            content="Preview unavailable - request demo"
            position="bottom"
          />
          <div className="flex amort">
            <div className="pr-2">Amortization</div>
            <InputSwitch disabled />
          </div>
        </div>
      </div>
      <div className="flex mt-2 justify-between items-center">
        <span className="text-xs text-gray-500"> Report from {getDateRange(-1).string}</span>
        <div>
        <Dropdown
            tooltip="preview unavailable - request demo"
            // value={
            //   filterQuery && filterQuery.key === "filter_id"
            //     ? filterQuery.data
            //     : null
            // }
            // onChange={handleFilterChange}
            options={[]}
            // optionLabel="filter_name"
            // optionLabel="filter_name"
            placeholder={getDateRange(-1).string}
            dropdownIcon={<GoTriangleDown className="text-secondary text-lg" />}
            className="!w-[290px] !h-[35px] md:!w-[265px] rounded xl: custom_select"
            // itemTemplate={filterListOptionTemp}
            // filter
          />
        </div>
      </div>
      <div className="my-2 rounded-md ">
        <AppViewCard />
      </div>
      <div>
        <EBasicHorizontalChart />
      </div>

      <div className="w-full flex flex-row gap-2">
        <div className="w-1/2">
          <ChartCard
            title={"Spend by Business Unit"}
            minWidth="200px"
            minHeight="350px"
          >
            <div className=" h-[313px] w-auto flex items-center justify-center">
              <EDoughNutChart />
            </div>
          </ChartCard>
        </div>
        <div className="w-1/2">
          <ChartCard
            title={"Resource Tagging"}
            minWidth="200px"
            minHeight="350px"
          >
            <Tooltip
              target=".more"
              content="Preview Unavailable - request demo"
              position="top"
            />
            <div className="flex items-center justify-center p-1 healthButton">
              <SelectButton
                value={taggedTags}
                options={views}
                onChange={(e) => handleViewChange(e)}
                optionLabel="name"
                className="w-fit"
              />
              <div className="flex text-base bg-white py-[3px] px-1 rounded-md border-2 border-gray-300 more">
                <span className="text-base">More</span>{" "}
                <GoTriangleDown className="text-secondary text-lg" />
              </div>
            </div>
            <div className="h-[255px] w-auto flex items-center justify-center">
              <EMeterChart currentVal={taggedTags.tagged} />
            </div>
          </ChartCard>
        </div>
      </div>
      <br />
      <div>
        {" "}
        <ChartCard
          title={"Cost Forecast"}
          width="600px"
          minHeight="350px"
          isDatePicker
          subTitle={`Forecast until : ${getDateRange(2).detailedObj.monthFull} ${getDateRange(2).detailedObj.year}`}
        >
          <div className="w-full h-[350px] flex items-center justify-center">
            <EBasicLineChart
              type="cost_forecast"
              isForecasted
              isLabelClick
              graphVal={[
                337605, 332874, 332270, 357316, 326819, 352567, 355669, 372102,
                350000, 349487, 347779, 340157, 336951,
              ]}
              graphYTD={[
                550028.7445, 590808.4674, 619799.6485, 651690.8205, 670479.0316,
                665143.719, 689586.1717, 684135.3234, 679386.5322, 708236.452,
                727771.6253, 722102.4536, 349487.0184, 697266.1335, 687935.9104,
                677108.2165,
              ]}
              isLegendType="horizontal"
              rawData={modifyArray(healthRawData)}
              handleXAxisLabelClick={handleXAxisLabelClick}
            />
          </div>
        </ChartCard>
      </div>
      <br />
      <Tooltip
        target=".more"
        content="Preview Unavailable - request Demo"
        position="top"
      />
      <div className="w-full flex flex-row gap-2">
        <div className="w-1/2">
          <ChartCard title={"Major Anomalies"}>
            <div className="w-full flex flex-row justify-between h-[50px] items-start py-2 pr-6 mb-3">
              <div className="w-1/2 bg-white pl-6">
                <select className="text-xs">
                  <option>Meter Category</option>
                </select>
                {/* <Dropdown disabled placeholder="Meter Category" className="bg-white" /> */}
              </div>
              <div className="flex flex-row justify-between items-start w-1/2 pointer-events-none">
                <span className="text-black text-xs pr-6 pt-2">Tolerance</span>
                <Slider
                  marks={marks}
                  className="!h-[25px] !w-[200px] more"
                  defaultValue={25}
                  step={25}
                  value={25}
                  // onChange={onSliderChange}
                  tooltip={{ formatter: null }}
                />
              </div>
            </div>
            <DashboardNegativeAnomaly />
          </ChartCard>
        </div>
        <div className="w-1/2">
          <ChartCard title={"Realized Savings"}>
            <div className="w-full flex flex-row justify-between h-[50px] items-start py-2 pr-6 mb-3">
              <div className="w-1/2 bg-white pl-6">
                <select className="text-xs">
                  <option>Meter Category</option>
                </select>
                {/* <Dropdown disabled placeholder="Meter Category" className="bg-white" /> */}
              </div>
              <div className="flex flex-row justify-between items-start w-1/2 pointer-events-none">
                <span className="text-black text-xs pr-6 pt-2">Tolerance</span>
                <Slider
                  marks={marks}
                  className="!h-[25px] !w-[200px] pointer-events-none more"
                  defaultValue={25}
                  step={25}
                  value={25}
                  // onChange={onSliderChange}
                  tooltip={{ formatter: null }}
                />
              </div>
            </div>

            <DashboardPositiveAnomaly />
          </ChartCard>
        </div>
      </div>
    </div>
  );
}

export default FinopsHealth;
