import ReactEcharts from 'echarts-for-react';
// import { getLocalStroageData } from '../../helpers/LocalHelper';
// import localConstant from '../../constant/localConstant';
import { formatCommas } from '../../../helpers/helpers';

const getMinSigma = (type) => {
  switch (type) {
    case 'aws':
      return 500;
    case 'oracle':
      return 500;
    default:
      return 10000;
  }
};

function AnomalyChart({ tableData, sigma, year, isPositiveAnomaly = false, customHeight }) {
  const sortedData = tableData.sort((a, b) => a.sigma_plus - b.sigma_plus);
  const cloudPartner = "azure"
  const meterNamesWithAnomaly = [];
  const actualSpentWithAnomaly = [];
  const costVarianceWithAnomaly = [];
  const runRateWithAnomaly = [];
  const meterNamesWithoutAnomaly = [];
  const actualSpentWithoutAnomaly = [];
  const costVarianceWithoutAnomaly = [];
  const runRateWithoutAnomaly = [];
  const dynamicSigma = getMinSigma(cloudPartner);

  sortedData.forEach((item) => {
    if (item.yearsData && item.yearsData[0]) {
      const yearData = item.yearsData[0][year];
      if (yearData && yearData[0]) {
        const costData = yearData[0];
        const sigmaPlus = parseFloat(Number(item.sigma_plus));
        const isAnomaly = costData.anomaly === true;
        if (sigmaPlus > dynamicSigma) {
          if (isAnomaly) {
            meterNamesWithAnomaly.push(item.MeterName);
            const cost = parseFloat(costData.cost);
            const difference = cost - sigmaPlus;
            costVarianceWithAnomaly.push(difference.toFixed(0));
            runRateWithAnomaly.push(sigmaPlus.toFixed(0));
            actualSpentWithAnomaly.push(sigmaPlus.toFixed(0));
          } else {
            meterNamesWithoutAnomaly.push(item.MeterName);
            const cost = parseFloat(costData.cost);
            const savings = sigmaPlus - cost;
            costVarianceWithoutAnomaly.push(savings.toFixed(0));
            runRateWithoutAnomaly.push(sigmaPlus.toFixed(0));
            actualSpentWithoutAnomaly.push(cost.toFixed(0));
          }
        }
      }
    }
  });

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter(params) {
        let tooltip = `${params[0].name}<br/>`;
        for (const { seriesName, value } of params) {
          let seriesValue = parseInt(value, 10);
          if (seriesName === 'Actual Spent') {
            const costVarianceParam = params.find((p) => p.seriesName === 'Cost Variance');
            if (costVarianceParam) {
              seriesValue += parseInt(costVarianceParam.value, 10);
            }
          }
          tooltip += `${seriesName}: $${formatCommas(seriesValue)}<br/>`;
        }
        return tooltip;
      }
    },
    legend: {
      data: [
        `Runrate At ${sigma}σ`,
        'Actual Spent',
        `${isPositiveAnomaly ? 'Cost Saving' : 'Cost Variance'}`
      ]
    },
    grid: {
      left: '5%',
      right: '25%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'value'
      }
    ],
    yAxis: [
      {
        type: 'category',
        axisTick: {
          show: false
        },
        data: isPositiveAnomaly ? meterNamesWithoutAnomaly : meterNamesWithAnomaly
      }
    ],
    series: [
      {
        name: `Runrate At ${sigma}σ`,
        type: 'bar',
        label: {
          show: true,
          position: 'right',
          formatter(params) {
            return `$${formatCommas(parseInt(params.value, 10))}`;
          }
        },
        emphasis: {
          focus: 'series'
        },
        data: isPositiveAnomaly ? runRateWithoutAnomaly : runRateWithAnomaly,
        barMinWidth: 25,
        barMaxWidth: 25,
        itemStyle: {
          color: '#5470C7'
        }
      },
      {
        name: 'Actual Spent',
        type: 'bar',
        stack: 'Total',
        emphasis: {
          focus: 'series'
        },
        data: isPositiveAnomaly ? actualSpentWithoutAnomaly : actualSpentWithAnomaly,
        barMinWidth: 25,
        barMaxWidth: 25,
        itemStyle: {
          color: '#F4C857'
        }
      },
      {
        name: `${isPositiveAnomaly ? 'Cost Saving' : 'Cost Variance'}`,
        type: 'bar',
        stack: 'Total',
        label: {
          show: true,
          position: 'right',
          formatter(params) {
            const actualSpentPositive = options.series.find(
              (series) => series.name === 'Actual Spent'
            ).data[params.dataIndex];
            const actualSpentNegative = options.series.find(
              (series) => series.name === 'Actual Spent'
            ).data[params.dataIndex];
            const totalSpent = parseInt(actualSpentNegative, 10) + parseInt(params.value, 10);
            return `$${
              !isPositiveAnomaly
                ? formatCommas(totalSpent, 10)
                : formatCommas(actualSpentPositive, 10)
            } | $${formatCommas(params.value, 10)}`;
          }
        },
        emphasis: {
          focus: 'series'
        },
        data: isPositiveAnomaly ? costVarianceWithoutAnomaly : costVarianceWithAnomaly,
        barMinWidth: 25,
        barMaxWidth: 25,
        itemStyle: {
          color: isPositiveAnomaly ? '#91CC75' : '#E96665'
        }
      }
    ]
  };
  return (
    <div>
      <ReactEcharts
        option={options}
        style={{
          width: '100%',
          minHeight: customHeight,
        }}
      />
    </div>
  );
}

export default AnomalyChart;
