import React, { useState, useEffect, useRef } from "react";
import Desktop from "../assets/icons/desktop.png";
import Laptop from "../assets/icons/laptop.png";
import Mobile from "../assets/icons/mobile.png";
import Picture1 from "../assets/icons/1.svg";
import Picture2 from "../assets/icons/2.svg";
import Picture3 from "../assets/icons/3.svg";
import Picture4 from "../assets/icons/4.svg";
import BG from "../assets/icons/device-bg.png";
import useIsVisible from "./UseIsVisible";
import "../css/MobileFeatures.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Feature = () => {
  const elementRef = useRef();
  useIsVisible(elementRef);
  const devices = [
    {
      device: BG,
      style: "device__bg ",
    },
    {
      device: Desktop,
      style: "desktop",
    },
    {
      device: Laptop,
      style: "laptop",
    },
    {
      device: Mobile,
      style: "mobile",
    },
  ];

  const stepper = [
    {
      stepVal: 25,
      stepLabel: 1,
      Image: Picture1,
      stepHeader: "Know your base",
      stepContextOne: "View costs at various hierarchies",
      stepContextTwo: "Define your own metrics and report",
    },
    {
      stepVal: 50,
      stepLabel: 2,
      Image: Picture2,
      stepHeader: "Apply the right context",
      stepContextOne: "Monitor your resource tagging",
      stepContextTwo: "Showback your business costs",
      stepContextThree: "Track and detect cost spikes",
    },
    {
      stepVal: 75,
      stepLabel: 3,
      Image: Picture3,
      stepHeader: "Convert insights to savings",
      stepContextOne: "Plan your next quarter spend",
      stepContextTwo: "Set soft and hard budget alerts",
      stepContextThree: "Optimize resource utilization",
    },
    {
      stepVal: 100,
      stepLabel: 4,
      Image: Picture4,
      stepHeader: "Scale across environments",
      stepContextOne: "Analyze your multi-cloud setup",
      stepContextTwo: "Have org-based visibilityApply the right context ",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          setTimeout(() => {
            if (entry.isIntersecting) {
              entry.target.querySelectorAll("li").forEach((el, index) => {
                el.classList.add("slide-in");
              });
            } else {

              entry.target.querySelectorAll("li").forEach((el) => {
                el.classList.remove("slide-in");
              });
            }
          }, index * 300);
        });
      },
      { threshold: 0.5 }
    );

    stepper.forEach((stepItem, index) => {
      observer.observe(document.getElementById(`content-${index}`));
    });

    return () => observer.disconnect();
  }, []);

  const DeviceComp = (view) => {
    const { device } = view;
    return <img className={device.style} alt="bg" src={device.device} />;
  };

  return (
    <div id="solution">
      <div className="web__view__solution ">
        <div className="flex flex-col mb-20">
          <div className="bg-secondary_light h-[700px]">
            <div className="text-center container mx-auto ">
              <h4 className="text-[35px] py-5 mt-16 text-primary">
                Why leverage any tooling for your FinOps
                <span className="dot">?</span>
              </h4>
              <p className="text-secondary px-48">
                According to Gartner, 'Organizations without a formal cost
                optimization process in place may be overspending by 70%'.
                <br /> Top challenges to be addressed include limited
                user-defined spend data breakup, uncontrolled noise in source
                data, and the absence of a unified platform for managing
                Multi-cloud environments.
              </p>
            </div>
          </div>

          <div className="container mx-auto">
            <div className="flex flex-row justify-center feature__image__grp h-[500px]">
              {devices.map((item, index) => {
                return <DeviceComp key={index} device={item} />;
              })}
            </div>
            <div className="-mt-52 mb-10 text-2xl ml-5 text-primary">
              Our Four-stage Approach
            </div>
            <div className="flex justify-center h-24">
              {stepper.map((item, stepIndex) => {
                const stepKey = `step-${stepIndex}`;
                return (
                  <div
                    key={stepKey}
                    className="flex items-center justify-around"
                  >
                    <div>
                      <CircularProgressbar
                        className="!text-[#b7e6f9] h-12"
                        value={item.stepVal}
                        text={item.stepLabel}
                      />
                    </div>
                    {stepIndex !== stepper.length - 1 && (
                      <div className="loader-line mx-10" />
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex justify-between">
              {stepper.map((stepItem, index) => (
                <div
                  key={`stepper-${index}`}
                  className={`w-1/4 mx-10 p-3 h-auto border border-[#003971] rounded-lg`}
                  id={`content-${index}`}
                >
                  <div className="flex justify-center">
                    <img src={stepItem.Image} alt={stepItem.Image} />
                  </div>
                  <p className="text-primary font-bold text-center text-base py-3">
                    {stepItem.stepHeader}
                  </p>
                  <ul className="px-5">
                    {stepItem.stepContextOne && (
                      <li className="text-primary text-sm leading-[20px] list-disc slide-out">
                        {stepItem.stepContextOne}
                      </li>
                    )}
                    {stepItem.stepContextTwo && (
                      <li className="text-primary text-sm leading-[20px] list-disc slide-out">
                        {stepItem.stepContextTwo}
                      </li>
                    )}
                    {stepItem.stepContextThree && (
                      <li className="text-primary text-sm leading-[20px] list-disc slide-out">
                        {stepItem.stepContextThree}
                      </li>
                    )}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mobile__view__solution ">
        <div className="flex flex-col mb-10">
          <div className="bg-secondary_light h-[500px]">
            <div className="text-center container mx-auto ">
              <h4 className="text-[25px] py-5 mt-10 text-primary">
                Why leverage any tooling for your FinOps
                <span className="dot">?</span>
              </h4>
              <p className="text-secondary px-5">
                According to Gartner, 'Organizations without a formal cost
                optimization process in place may be overspending by 70%'.
                <br /> Top challenges to be addressed include limited
                user-defined spend data breakup, uncontrolled noise in source
                data, and the absence of a unified platform for managing
                Multi-cloud environments.
              </p>
            </div>
          </div>

          <div className="container mx-auto">
            <div className="flex flex-row justify-center feature__image__grp h-[200px]">
              {devices.map((item, index) => {
                return <DeviceComp key={index} device={item} />;
              })}
            </div>
            <div className="text-2xl ml-5 text-primary">
              Our Four-stage Approach
            </div>
            <div className="hidden md:flex justify-center h-24">
              {stepper.map((item, stepIndex) => {
                const stepKey = `step-${stepIndex}`;
                return (
                  <div
                    key={stepKey}
                    className="flex items-center justify-between"
                  >
                    <div>
                      <CircularProgressbar
                        className="!text-[#b7e6f9] h-12"
                        value={item.stepVal}
                        text={item.stepLabel}
                      />
                    </div>
                    {stepIndex !== stepper.length - 1 && (
                      <div className="mobile-loader-line mx-10 lg:w-[13.1vw]" />
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex justify-between h-auto w-[100%] flex-col md:flex-row">
              {stepper.map((stepItem, index) => (
                <div
                  key={`stepper-${index}`}
                  className="w-[90%] md:w-[25%] mx-5 mt-3 md:mt-0 mb-3 md:mb-0 p-1 h-auto border border-[#003971] rounded-lg"
                  id={`content-${index}`}
                >
                  <div className="flex justify-center">
                    <img src={stepItem.Image} alt={stepItem.Image} />
                  </div>
                  <p className="text-primary font-bold text-center text-base py-3 md:h-[72px]">
                    {stepItem.stepHeader}
                  </p>
                  <ul className="px-12 md:px-5 pb-10">
                    {stepItem.stepContextOne && (
                      <li className="text-primary text-lg leading-[20px] list-disc">
                        {stepItem.stepContextOne}
                      </li>
                    )}
                    {stepItem.stepContextTwo && (
                      <li className="text-primary text-lg leading-[20px] list-disc">
                        {stepItem.stepContextTwo}
                      </li>
                    )}
                    {stepItem.stepContextThree && (
                      <li className="text-primary text-lg leading-[20px]">
                        {stepItem.stepContextThree}
                      </li>
                    )}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
