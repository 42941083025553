/* eslint no-alert: "off" */
/* eslint no-nested-ternary: "off" */
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Slider } from "primereact/slider";
import { FilterMatchMode } from "primereact/api";
import Select from "./select";
import { formatCommas } from "../../../helpers/helpers";
import { Tooltip } from "primereact/tooltip";

const initialFilterSet = {
  operator: "",
  value: "",
  prevSelection: false,
  selectedValues: [],
};

const filterOpt = [
  { label: "Starts With", value: FilterMatchMode.STARTS_WITH },
  { label: "Ends With", value: FilterMatchMode.ENDS_WITH },
  { label: "Contains", value: FilterMatchMode.CONTAINS },
  { label: "Custom", value: "array_includes" },
];

const filterNumberOpt = [
  { label: "Greater than", value: FilterMatchMode.GREATER_THAN },
  {
    label: "Greater than or Equal to",
    value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
  },
  {
    label: "Less than or Equal to",
    value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
  },
  { label: "Less than", value: FilterMatchMode.LESS_THAN },
  { label: "Custom", value: "array_includes" },
  { label: "Between", value: "number_between" },
];

function TableFilterHeader({
  rowData,
  column,
  setFilterOperator,
  previousKeys,
  headerEvent,
  clearAll,
  id,
  filterAppliedKey,
  setFilterAppliedKey,
}) {
  const [filterSet, setFilterSet] = useState(initialFilterSet);
  const [searchKeys, setSearchKeys] = useState(null);
  const [search, setSearch] = useState("");
  const [range, setRange] = useState(null);
  let columnType = "";

  const handleToggle = () => {
    const parentCell = document.getElementsByClassName(id)?.[0];
    const button = parentCell?.getElementsByTagName("button")?.[0];
    if (button) button.click();
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFilterSet((prev) => ({ ...prev, [name]: value }));
  };

  const updateSearchKey = () => {
    if (column && column.field) {
      const { field } = column;
      const keys = rowData
        .filter((doc, index, array) => {
          let flag = false;
          if (doc[field]) {
            const indexOf = array.findIndex((val) => val[field] === doc[field]);
            if (index === indexOf) {
              flag = true;
            }
          }
          return flag;
        })
        .map((doc) => doc[field]);
      setSearchKeys(keys);
    }
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    const value = searchKeys.filter((doc) =>
      doc.toString().toLowerCase().includes(search.toLowerCase())
    );
    setFilterSet((prev) => ({ ...prev, selectedValues: checked ? value : [] }));
  };

  const handleApply = () => {
    const { operator, value, selectedValues } = filterSet;
    if (column.field && operator) {
      if (operator === FilterMatchMode.GREATER_THAN) {
        setFilterOperator(column.field, parseFloat(value), operator);
      } else if (operator === FilterMatchMode.GREATER_THAN_OR_EQUAL_TO) {
        setFilterOperator(column.field, parseFloat(value), operator);
      } else if (operator === FilterMatchMode.LESS_THAN_OR_EQUAL_TO) {
        setFilterOperator(column.field, parseFloat(value), operator);
      } else if (operator === FilterMatchMode.LESS_THAN) {
        setFilterOperator(column.field, parseFloat(value), operator);
      } else if (operator === FilterMatchMode.CONTAINS) {
        setFilterOperator(column.field, value, operator);
      } else if (operator === "array_includes") {
        setFilterOperator(column.field, selectedValues, operator);
      } else if (operator === "number_between") {
        setFilterOperator(column.field, value, operator);
      } else {
        setFilterOperator(column.field, value, operator);
      }
    } else {
      alert("Please select a valid filter");
    }
    handleToggle();
    setFilterAppliedKey((prev) => ({ ...prev, [column.field]: true }));
  };

  const handleClear = () => {
    const { operator } = filterSet;
    if (column.field && operator) {
      setFilterOperator(column.field, null, operator);
    }
    handleToggle();
  };

  const handleCheck = (e, colType) => {
    const { value, checked } = e.target;
    setFilterSet((prev) => {
      const selectedValues = [...prev.selectedValues];
      const index = selectedValues.indexOf(value);
      if (!checked) {
        selectedValues.splice(index, 1);
      } else {
        selectedValues.push(colType === "number" ? Number(value) : value);
      }
      return { ...prev, selectedValues };
    });
  };

  const handlePrevSelection = (e) => {
    const { checked } = e.target;
    const filterValue = Array.isArray(filterSet.value) ? filterSet.value : [];
    if (previousKeys && previousKeys[column.field]) {
      const keys = Array.isArray(previousKeys[column.field])
        ? previousKeys[column.field]
        : [previousKeys[column.field]];
      const value = checked
        ? filterValue.concat(keys)
        : filterValue.filter((doc) => !keys.includes(doc));
      setFilterSet((prev) => ({ ...prev, prevSelection: checked, value }));
    }
  };

  const updateFilterSetValue = () => {
    const { operator } = filterSet;
    let value = "";
    if (operator === "array_includes") {
      value = filterAppliedKey[column.field]
        ? previousKeys[column.field]
        : filterSet.selectedValues;
    } else if (operator === "number_between") {
      const sortedKeys = searchKeys.sort((a, b) => Number(a) - Number(b));
      const min = parseInt(Number(sortedKeys[0]), 10);
      const max = parseInt(Number(sortedKeys.at(-1)), 10);
      value = [min, max];
      if (!range) {
        setRange({
          min,
          max,
        });
      }
    } else if (operator === FilterMatchMode.STARTS_WITH) {
      value = filterSet.value;
    } else if (operator === FilterMatchMode.ENDS_WITH) {
      value = filterSet.value;
    } else if (operator === FilterMatchMode.CONTAINS) {
      value = filterSet.value;
    } else {
      value = filterSet.value;
    }
    setFilterSet((prev) => ({ ...prev, selectedValues: value || [] }));
  };

  const handleRangeChange = (e) => {
    const { value } = e;
    setFilterSet((prev) => ({ ...prev, value }));
  };

  const handleClearAll = () => {
    if (clearAll && typeof clearAll === "function") clearAll();
    handleToggle();
  };

  useEffect(() => {
    if (!searchKeys && rowData && rowData.length > 0) {
      updateSearchKey();
    }
  }, [searchKeys, rowData]);

  useEffect(() => {
    if (searchKeys && searchKeys.length > 0 && filterSet.operator) {
      updateFilterSetValue();
    }
  }, [filterSet.operator]);

  useEffect(() => {
    if (
      headerEvent &&
      headerEvent.filterModel &&
      Object.keys(headerEvent.filterModel).length > 0 &&
      !filterSet.operator
    ) {
      setFilterSet((prev) => ({
        ...prev,
        operator: headerEvent.filterModel.matchMode,
      }));
    }
  }, [headerEvent]);

  if (column && Object.keys(column).length > 0) {
    columnType = column.filterType
      ? column.filterType
      : column.type === "number" || column.type === "string"
      ? column.type
      : column.field.toLowerCase() === "resource count"
      ? "number"
      : column.field.toLowerCase() === "cost"
      ? "cost"
      : "string";
  }

  const isStringPanel = columnType === "string";
  const isCustom = filterSet.operator === "array_includes";
  const isCustomBetween = filterSet.operator === "number_between";
  const isCustomEnable = isCustom || isCustomBetween;
  const minValue =
    filterSet.value && Array.isArray(filterSet.value) ? filterSet.value[0] : 0;
  const maxValue =
    filterSet.value && Array.isArray(filterSet.value) ? filterSet.value[1] : 0;
  const min = range && range.min ? range.min : 0;
  const max = range && range.max ? range.max : 0;
  return (
    <div className="p-4">
      <p className="mb-3">Filter By</p>
      <div className="w-full mb-3">
        <Select
          onChange={handleChange}
          value={filterSet.operator}
          name="operator"
          options={isStringPanel ? filterOpt : filterNumberOpt}
        />
      </div>
      <div>
        <InputText
          value={isCustomEnable ? "" : filterSet.value}
          onChange={handleChange}
          className="!p-2 !w-full"
          name="value"
          placeholder="Filter"
          disabled={isCustomEnable}
        />
      </div>
      {!isCustomBetween ? (
        <fieldset
          className={`border border-gray-400 p-2 my-3 ${
            !isCustomEnable && "opacity-50 pointer-events-none"
          }`}
        >
          <legend className="text-xs pr-2 pl-2 ml-6">Custom</legend>
          <Tooltip content="test" target="search" position="top" />
          <InputText
            value={search}
            // onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            className="my-2 !p-1 !text-xs w-full search"
          />

          <div className="h-[100px] w-[220px] overflow-y-auto mt-2">
            <div className="truncate w-[200px] flex items-center text-xs">
              <label htmlFor="selectAll">
                <input
                  id="selectAll"
                  type="checkbox"
                  onClick={handleSelectAll}
                />
                <span className="ml-2">Select All Search Result</span>
              </label>
            </div>
            <div className="truncate w-[200px] flex items-center text-xs">
              <label htmlFor="prevSelection">
                <input
                  id="prevSelection"
                  type="checkbox"
                  checked={filterSet.prevSelection}
                  onClick={handlePrevSelection}
                />
                <span className="ml-2">Add current selection to filter</span>
              </label>
            </div>
            <div className="truncate w-[200px] text-xs">
              {searchKeys &&
                searchKeys.length > 0 &&
                searchKeys
                  .filter(
                    (doc) =>
                      doc &&
                      doc
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                  )
                  .sort((a, b) => {
                    if (isStringPanel) {
                      return a.localeCompare(b);
                    }
                    return -(Number(a) - Number(b));
                  })
                  .map((label, key) => {
                    const uniqueKey = `select-${key}`;
                    const isChecked =
                      filterSet.selectedValues &&
                      filterSet.selectedValues.includes(label);
                    return (
                      <label
                        key={uniqueKey}
                        htmlFor={uniqueKey}
                        className="block"
                      >
                        <input
                          type="checkbox"
                          id={uniqueKey}
                          value={label}
                          checked={isChecked}
                          name={isStringPanel ? "string" : "number"}
                          onChange={(e) => handleCheck(e, columnType)}
                        />
                        <span className="ml-2">
                          {columnType === "cost" ? "$" : ""}
                          {!isStringPanel ? formatCommas(label) : label}
                        </span>
                      </label>
                    );
                  })}
            </div>
          </div>
        </fieldset>
      ) : (
        <div className="my-3 w-[220px]">
          <p className="text-xs text-violet-400">
            The filter range should be from min {min} to max {max}{" "}
          </p>
          <div className="mb-4">
            <div className="flex justify-between items-center py-4 text-xs text-blue-800">
              <p>{minValue}</p>
              <p>{maxValue}</p>
            </div>
            <Slider
              value={filterSet.value}
              min={min}
              max={max}
              name="range"
              onChange={handleRangeChange}
              className="w-14rem"
              range
            />
          </div>
        </div>
      )}

    </div>
  );
}

export default TableFilterHeader;
