/* eslint no-nested-ternary: "off" */
import { useEffect, useState } from "react";
import moment from "moment";
import AnomalyChart from "./anomalyChart";

const cloudPartner = "azure";

const marks = {
  0: {
    style: {
      width: "56px",
    },
    label: <span>Very Low</span>,
    value: 1,
  },
  25: {
    label: "Low",
    value: 1.25,
  },
  50: {
    label: "Moderate",
    value: 1.5,
  },
  75: {
    label: "High",
    value: 1.75,
  },
  100: {
    style: {
      width: "61px",
    },
    label: <span>Very High</span>,
    value: 2,
  },
};
const getMeterNameKeys = (type, item) => {
  switch (type) {
    case "aws":
      return item.line_item_product_code;
    case "oracle":
      return item.product_service;
    default:
      return item.MeterCategory;
  }
};
const getMinSigma = (type) => {
  switch (type) {
    case "aws":
      return 500;
    case "oracle":
      return 500;
    default:
      return 10000;
  }
};
const getInititalMetric = (type) => {
  switch (type) {
    case "aws":
      return { metricsName: "Product", metricsVal: "line_item_product_code" };
    case "oracle":
      return { metricsName: "Product", metricsVal: "product_service" };
    default:
      return { metricsName: "Meter Category", metricsVal: "MeterCategory" };
  }
};

const getMetricOpt = (type) => {
  switch (type) {
    case "aws":
      return [{ metricsName: "Product", metricsVal: "line_item_product_code" }];
    case "oracle":
      return [{ metricsName: "Product", metricsVal: "product_service" }];
    default:
      return [{ metricsName: "Meter Category", metricsVal: "MeterCategory" }];
  }
};
function DashboardPositiveAnomaly({
  monthFilter = {
    startDate: "2024-01-01",
    endDate: "2024-01-31",
  },
}) {
  const anomalyList = [
    {
      MeterCategory: "Storage",
      current_drill: "MeterCategory",
      Cost: 3452151.7563899998,
      mean: 575358.626065,
      deviation: 15592.227866841147,
      sigma_minus: 555868.3412314486,
      sigma_plus: 148483.9108985513,
      yearsData: [
        {
          2024: [
            {
              month: "January",
              cost: "129946.1978700000",
              anomaly: false,
            },
          ],
        },
      ],
    },
    {
      MeterCategory: "SQL Database",
      current_drill: "MeterCategory",
      Cost: 2413161.853,
      mean: 402193.6421666667,
      deviation: 10537.685783871446,
      sigma_minus: 389021.5349368274,
      sigma_plus: 99560.749396506,
      yearsData: [
        {
          2024: [
            {
              month: "January",
              cost: "94485.4658800000",
              anomaly: false,
            },
          ],
        },
      ],
    },
    {
      MeterCategory: "Backup",
      current_drill: "MeterCategory",
      Cost: 737149.2561,
      mean: 122858.20935,
      deviation: 15632.716502284658,
      sigma_minus: 103317.31372214419,
      sigma_plus: 30169.10497785584,
      yearsData: [
        {
          2024: [
            {
              month: "January",
              cost: "26141.6642300000",
              anomaly: false,
            },
          ],
        },
      ],
    },
  ];
  const metricsOpt = getMetricOpt(cloudPartner);
  const initialMetric = getInititalMetric(cloudPartner);
  const [metric, setMetric] = useState(initialMetric);
  const [selectedMetric, setSelectedMetric] = useState(1.25);
  const [sliderValue, setSliderValue] = useState(25);
  const [tableData, setTableData] = useState([]);
  const dateFormat = moment(monthFilter.startDate, "YYYY-MM-DD");
  const yearVal = dateFormat.year();
  const minSigma = getMinSigma(cloudPartner);
  const anomalies = tableData.filter((item) => {
    const sigmaPlusValue = item.sigma_plus;
    const anomalyData = item.yearsData[0]?.[yearVal];
    const anomaly = anomalyData && anomalyData[0]?.anomaly;
    return sigmaPlusValue > minSigma && anomaly === false;
  });
  const customChartHeight = `${(anomalies.length * 100).toString()}px`;

  const handleMetricChange = (e) => {
    setMetric(e.value);
  };

  const handleFetchAnomlay = () => {
    const params = {
      year: yearVal,
      "from-date": monthFilter.startDate,
      "to-date": monthFilter.endDate,
      metrics: metric.metricsVal,
      deviation: selectedMetric,
    };
  };

  const onSliderChange = (value) => {
    const sigmaVal = marks[value]?.value;
    setSelectedMetric(sigmaVal);
    setSliderValue(value);
  };

  const formatTableData = () => {
    const updatedData = anomalyList.map((item) => ({
      MeterName: getMeterNameKeys(cloudPartner, item),
      Cost: item.Cost,
      mean: item.mean,
      deviation: item.deviation,
      sigma_minus: item.sigma_minus,
      sigma_plus: item.sigma_plus,
      yearsData: item.yearsData,
    }));

    const nonEmptyArrays = updatedData.filter(
      (item) => !Array.isArray(item?.yearsData[yearVal])
    );
    setTableData(nonEmptyArrays);
  };

  useEffect(() => {
    if (selectedMetric) {
      handleFetchAnomlay();
    }
  }, []);

  useEffect(() => {
    if (anomalyList && anomalyList.length > 0) {
      formatTableData();
    }
  }, []);

  useEffect(() => {
    if (monthFilter.startDate && monthFilter.endDate) {
      handleFetchAnomlay();
    }
  }, []);

  useEffect(() => {
    if (monthFilter.startDate && monthFilter.endDate) {
      handleFetchAnomlay();
    }
  }, []);

  return (
    <main>
      <div className="w-full flex flex-row justify-between h-[50px] items-start py-2 pl-6 pr-12 mb-3"></div>
      <div className="flex flex-row justify-between items-center !w-full">
        <div className="w-full ">
          <AnomalyChart
            tableData={tableData}
            sigma={selectedMetric}
            year={yearVal}
            isPositiveAnomaly
            customHeight={customChartHeight}
          />
        </div>
      </div>
    </main>
  );
}

export default DashboardPositiveAnomaly;
