/* eslint-disable */
import { CiFilter } from 'react-icons/ci';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import { Button } from 'primereact/button';
import _ from 'lodash';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useEffect, useRef, useState } from 'react';
import { formatCommas } from '../../../helpers/helpers';

export const filterOpt = [
  { label: 'Starts With', value: 'start' },
  { label: 'Ends With', value: 'end' },
  { label: 'Contains', value: 'contain' },
  { label: 'Custom', value: 'array_includes' }
];

export const filterNumberOpt = [
  { label: 'Greater than', value: 'greater' },
  { label: 'Greater than or Equal to', value: 'greaterEqual' },
  { label: 'Less than or Equal to', value: 'lessEqual' },
  { label: 'Less than', value: 'less' },
  { label: 'Custom', value: 'array_includes' },
  { label: 'Between', value: 'number_between' }
];

export const anomalyFilterOpt = [
  { label: 'Color', value: 'color' },
  { label: 'Greater than', value: 'greater' },
  { label: 'Greater than or Equal to', value: 'greaterEqual' },
  { label: 'Less than or Equal to', value: 'lessEqual' },
  { label: 'Less than', value: 'less' },
  { label: 'Custom', value: 'array_includes' },
  { label: 'Between', value: 'number_between' }
];

export const colorKeys = [
  { color: 'bg-red-700', anomaly: true },
  { color: 'bg-white', anomaly: false }
];

function TreeViewHeader({
  header,
  filterValue,
  filters,
  handleFilterTypeChange,
  handleFilterFieldChange,
  handleClear,
  handleFind,
  toggleOverlayPanel,
  filterReference,
  searchKeyData,
  handleCustomFilterChange,
  clearAllFilter,
  handleColorFilterChange,
  type,
  isAnomaly
}) {
  const [search, setSearch] = useState('');
  const [range, setRange] = useState([]);
  const [previousSelection, setPreviousSelection] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchKeys, setSearchKeys] = useState(null);
  const { label, field } = header;
  const previousSearchKeys = useRef({});
  const previousAppliedFlag = useRef({});
  const isCount = type === 'count';

  const handleSelectAll = (type, isChecked, validKeys) => {
    const value = isChecked ? validKeys : [];
    handleCustomFilterChange('selectAll', type, value);
  };

  const handlePreviousSelection = (type, isChecked) => {
    setPreviousSelection((prev) => {
      const prevState = { ...prev };
      prevState[type] = isChecked;
      return prevState;
    });
    const value = previousSearchKeys.current[type];
    if (value && Array.isArray(value)) {
      handleCustomFilterChange(isChecked ? 'concat' : 'remove', type, value);
    }
  };

  const handleApply = (type, isCustom, value) => {
    if (isCustom && Array.isArray(value) && value.length > 0) {
      previousSearchKeys.current[type] = value;
      previousAppliedFlag.current[type] = true;
    }
    setSearch('');
    handleFind(type);
    setPreviousSelection((prev) => {
      const prevState = { ...prev };
      if (prevState.hasOwnProperty(type)) {
        delete prevState[type];
      }
      return prevState;
    });
    if (handleColorFilterChange && typeof handleColorFilterChange === 'function') {
      handleColorFilterChange(type, selectedItems);
    }
  };

  const handleClearAll = () => {
    if (clearAllFilter && typeof clearAllFilter === 'function') clearAllFilter(selectedItems);
  };

  const handleCheckBoxChange = (e, type) => {
    handleCustomFilterChange('custom', type, e, previousAppliedFlag.current[type]);
    previousAppliedFlag.current[type] = false;
  };

  const handleFilterByColor = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const exists = prevSelectedItems.includes(item.anomaly);
      return exists
        ? prevSelectedItems.filter((selectedItem) => selectedItem !== item.anomaly)
        : [...prevSelectedItems, item.anomaly];
    });
  };
  const renderPanel = (type) => {
    let min = 0;
    let max = 0;
    let validKeys;
    let isSelectAll = false;
    const isStringPanel = type === 'name';
    const fieldValue = filterValue && filterValue[type] ? filterValue[type] : null;
    const selectValue =
      filters && filters[type] && Object.keys(filters[type]).length
        ? filters[type].filterType
        : null;
    const isCustom = selectValue === 'array_includes';
    const isCustomBetween = selectValue === 'number_between';
    const isColor = selectValue === 'color';
    const isDisable = isCustom || isCustomBetween || isColor;
    if (isCustomBetween && searchKeys && Array.isArray(searchKeys)) {
      for (const numValue of searchKeys) {
        if (numValue && typeof numValue === 'number') {
          if (!min) {
            min = numValue;
          } else {
            if (min > numValue) min = numValue;
          }
          if (!max) {
            max = numValue;
          } else {
            if (max < numValue) max = numValue;
          }
        }
      }
      if (range && range.length === 0) {
        setRange([min, max]);
      }
    }
    if (isCustom) {
      validKeys =
        searchKeys &&
        Array.isArray(searchKeys) &&
        searchKeys.filter(
          (doc) => doc && doc.toString().toLowerCase().includes(search.toLowerCase())
        );
      if (validKeys && fieldValue) {
        isSelectAll = validKeys.length <= fieldValue.length;
      }
    }
    return (
      <div className="p-4 !font-neue-montreal tracking-[0.3px]">
        <p className="mb-3">Filter By</p>
        <div className="w-full mb-3">
        </div>
        <div>
          <InputText
            value={!isDisable ? fieldValue : ''}
            onChange={(e) => handleFilterFieldChange(e.target.value, type)}
            className="!p-2 !w-full"
            placeholder="Filter"
            disabled={isDisable}
          />
        </div>
        {!isCustomBetween && !isColor ? (
          <fieldset className="border border-gray-400 p-2 my-3 relative">
            <legend className="text-xs pr-2 pl-2 ml-6">Custom</legend>
            {!isCustom && (
              <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-[#ffffff9e] " />
            )}
            <InputText
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
              className="my-2 !p-1 !text-xs w-full"
            />

            <div className="h-[100px] w-[220px] overflow-y-auto mt-2">
              <div className="truncate w-[200px] flex items-center text-xs">
                <input
                  id={`selectAll-${type}`}
                  type="checkbox"
                  checked={isSelectAll}
                  onChange={(e) => handleSelectAll(type, e.target.checked, validKeys)}
                />
                <label htmlFor={`selectAll-${type}`} className="ml-2">
                  Select All Search Result
                </label>
              </div>
              <div className="truncate w-[200px] flex items-center text-xs">
                <input
                  id={`prevSelection-${type}`}
                  type="checkbox"
                  checked={previousSelection[type]}
                  onChange={(e) => handlePreviousSelection(type, e.target.checked)}
                />
                <label htmlFor={`prevSelection-${type}`} className="ml-2">
                  Add current selection to filter
                </label>
              </div>
              {searchKeys &&
                Array.isArray(searchKeys) &&
                searchKeys
                  .filter(
                    (doc) => doc && doc.toString().toLowerCase().includes(search.toLowerCase())
                  )
                  .map((doc, docIndex) => {
                    const elementID = `${doc}-${docIndex}`;
                    const isChecked = Array.isArray(fieldValue) && fieldValue.includes(doc);
                    if (typeof doc === 'object' && doc.count && doc.totalCount) {
                      const id = `${doc.count}-${docIndex}`;
                      const checked = Array.isArray(fieldValue) && fieldValue.includes(doc.count);
                      return (
                        <div key={id} className="truncate w-[200px] flex items-center text-xs">
                          <input
                            type="checkbox"
                            id={id}
                            value={` ${
                              doc.totalCount ? `${doc.count} / ${doc.totalCount}` : doc.count
                            }`}
                            checked={checked}
                            onChange={(e) => handleCheckBoxChange(e, type)}
                          />
                          <label
                            htmlFor={id}
                            className={
                              !isCount && typeof doc.count === 'number'
                                ? 'text-right w-[58px] truncate'
                                : 'ml-2'
                            }>
                            {!isCount && typeof doc.count === 'number'
                              ? `$${formatCommas(doc.count)}`
                              : ` ${
                                  doc.totalCount ? `${doc.count} / ${doc.totalCount}` : doc.count
                                }`}
                          </label>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={elementID}
                          className="truncate w-[200px] flex items-center text-xs">
                          <input
                            type="checkbox"
                            id={elementID}
                            value={doc}
                            checked={isChecked}
                            onChange={(e) => handleCheckBoxChange(e, type)}
                          />
                          <label
                            htmlFor={elementID}
                            className={
                              !isCount && typeof doc === 'number'
                                ? 'text-right w-[58px] truncate'
                                : 'ml-2'
                            }>
                            {isCount && typeof doc === 'number' ? `$${formatCommas(doc)}` : doc}
                          </label>
                        </div>
                      );
                    }
                  })}
            </div>
          </fieldset>
        ) : isColor ? (
          <fieldset className="border border-gray-400 p-2 my-3 relative">
            <legend className="text-xs pr-2 pl-2 ml-6">Color</legend>
            {colorKeys &&
              colorKeys.map((doc, colorIndex) => {
                const colorID = `${doc}-${colorIndex}`;
                const isColorChecked = selectedItems.includes(doc.anomaly);
                return (
                  <div key={colorID} className="truncate w-[200px] mb-2 flex items-center text-xs">
                    <input
                      type="checkbox"
                      id={colorID}
                      value={doc.anomaly}
                      checked={isColorChecked}
                      onChange={() => handleFilterByColor(doc, type)}
                    />
                    <div className={`w-[50px] h-[12px] ml-2 border border-gray-500 ${doc.color}`} />
                  </div>
                );
              })}
          </fieldset>
        ) : (
          <div className="my-3 w-[220px]">
            <p className="text-xs text-violet-400">
              The filter range should be from min {Math.round(min)} to max {Math.round(max)}{' '}
            </p>
            <div className="mb-4">
              <div className="flex justify-between items-center py-4 text-xs text-blue-800">
                <p>{range && range.length === 2 ? Math.round(range[0]) : 0}</p>
                <p>{range && range.length === 2 ? Math.round(range[1]) : 0}</p>
              </div>
              <Slider
                value={range}
                min={min}
                max={max}
                onChange={(e) => {
                  setRange(e.value);
                  handleFilterFieldChange(e.value, type);
                }}
                className="w-14rem"
                range
              />
            </div>
          </div>
        )}
        <div>
          <div className="flex justify-between my-3">
            <Button
              outlined
              label="Clear"
              onClick={() => handleClear(type)}
              className="!p-2 !h-fit !text-xs !font-normal !px-4 !border-secondary !text-secondary w-full"
            />
            <Button
              label="Apply"
              onClick={() => handleApply(type, isCustom, fieldValue)}
              className="!p-2 !h-fit !text-xs !font-normal !px-4 !bg-secondary !border-secondary !text-white w-full !ml-2"
            />
          </div>
          <Button
            outlined
            label="Clear All"
            onClick={handleClearAll}
            className="!p-2 !h-fit !text-xs w-full !font-normal !px-4 !border-secondary !text-secondary"
          />
        </div>
      </div>
    );
  };

  const updateSearchKeys = () => {
    const keys = searchKeyData.map((doc) => {
      const value = _.get(doc.data, field) || 0;
      return value;
    });
    const tagVal = keys.map((item) => {
      return `${item.count} / ${item.totalCount}`;
    });
    const hasTotalCount = keys.some((item) => item.totalCount !== undefined);
    if (hasTotalCount) {
      setSearchKeys(tagVal);
    } else {
      setSearchKeys(keys);
    }
  };

  useEffect(() => {
    if (
      searchKeyData &&
      Array.isArray(searchKeyData) &&
      searchKeyData.length > 0 &&
      !searchKeys &&
      field
    ) {
      updateSearchKeys();
    }
  }, [searchKeyData, searchKeys]);
  return (
    <div className="inline-flex items-center">
      <div>{label}</div>
      <div
        className="mx-3 cursor-pointer"
        aria-hidden
        onClick={(e) => toggleOverlayPanel(field, e)}>
        <CiFilter />
      </div>
      <OverlayPanel
        ref={(ref) => {
          filterReference.current[field] = ref;
        }}>
        {renderPanel(field)}
      </OverlayPanel>
    </div>
  );
}

export default TreeViewHeader;
