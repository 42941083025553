/* eslint no-nested-ternary: "off" */
import { Button } from "primereact/button";
import { HiOutlineSearch } from "react-icons/hi";
import { filtrateColumn } from "../../../helpers/helpers";
import CubeBreadCrumb from "../Common/cubeBreadCrumb";
import { useEffect, useState } from "react";
import CubeTable from "../TagGovComponents/cubeTable";
import { Tooltip } from "primereact/tooltip";
import sortedAlertData from '../Jsonfiles/sortedAlertData.json'

const columns = [
  { field: "alert_name", label: "Name" },
  { field: "application_name", label: "App" },
  { field: "alert_period", label: "Threshold Period" },
  { field: "threshold_limit_value", label: "Limit", type: "cost" },
  { field: "name", label: "Owner" },
  { field: "created_at", label: "Created", type: "date" },
];
const initialFormState = {
  flag: false,
  data: null,
};

const initialDeleteState = {
  flag: false,
  data: null,
  header:
    "Are you sure want to delete this data? All of this selected data will be deleted. This action cannot be undone",
  subHeader: "",
  name: "",
};
function AlertsList() {
  const permission = {
    create: 1,
    read: 1,
    update: 1,
    delete: 1,
    "alert-test": 1,
  };
  const items = [{ label: "Alerts" }];
  //   const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useState("");
  const [actionType, setActionType] = useState("");
  const [isSidebar, setIsSidebar] = useState(false);
  const [formStatus, setFormStatus] = useState(initialFormState);
  const [deleteStatus, setDeleteStatus] = useState(initialDeleteState);
  const [testLoading, settestLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState(columns);

  //   const {
  //     data: alerts = [],
  //     isLoading: isAlertsLoading,
  //     isError: isAlertsError,
  //     isFetching: isAlertsFetching,
  //   } = useGetAlertsQuery();
  //   const [deleteAlert] = useDeleteAlertMutation();
  //   const [getThresholdAlerts] = useLazyGetThresholdAlertsQuery();

  //   const sortedAlerts = [...alerts]
  //     .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  //     .filter((alert) => alert.alert_type !== "scheduled");

  const showToastMessage = (mode, heading, message) => {
    // dispatch(
    //   showToast({
    //     mode,
    //     heading,
    //     message,
    //     autoClose: 100
    //   })
    // );
  };

  const handleAction = (data, action) => {
    setFormStatus({
      flag: true,
      data,
    });
    setActionType(action);
    setIsSidebar(true);
  };

  const handleFormOpen = (data) => {
    handleAction(data, "EDIT");
  };

  const handleView = (data) => {
    handleAction(data, "VIEW");
  };

  const handleDelete = (data) => {
    const selectedDataName = data && data.alert_name ? data.alert_name : "";
    setDeleteStatus((prev) => ({
      ...prev,
      flag: true,
      data,
      subHeader: (
        <span>
          Type <span className="text-[#e70909]">{selectedDataName}</span> below
          to confirm the deletion
        </span>
      ),
      name: selectedDataName,
    }));
  };

  const onTestThreshold = async () => {
    // try {
    //   settestLoading(true);
    //   const response = await getThresholdAlerts();
    //   if (response.error) {
    //     showToastMessage('error', 'Error', 'An error occurred while testing thresholds');
    //   } else {
    //     showToastMessage('success', 'Success', 'Thresholds tested successfully');
    //   }
    // } catch (error) {
    //   showToastMessage('error', 'Error', 'An error occurred while testing thresholds');
    // } finally {
    //   settestLoading(false);
    // }
  };

  const handleSideBarClose = () => {
    setIsSidebar(false);
  };

  const handleCloseDialog = () => {
    setDeleteStatus(initialDeleteState);
  };

  const triggerDelete = () => {
    // deleteAlert(deleteStatus.data)
    //   .unwrap()
    //   .then(() => {
    //     handleCloseDialog();
    //     handleSideBarClose();
    //     dispatch(
    //       showToast({
    //         mode: 'success',
    //         heading: 'Deleted!',
    //         message: 'Alerts were deleted successfully'
    //       })
    //     );
    //   })
    //   .catch(() => {
    //     dispatch(
    //       showToast({
    //         mode: 'error',
    //         heading: 'Error',
    //         message: 'Something went wrong'
    //       })
    //     );
    //   });
  };
  const onCreateClick = () => {
    setActionType("CREATE");
    setIsSidebar(true);
  };

  const onSearchChange = (e) => {
    setSearchParam(e.target.value);
  };

  const updateTableColumn = () => {
    const actionColumn = { field: "id", label: "Actions", type: "" };
    actionColumn.type =
      Boolean(permission.update) && Boolean(permission.delete)
        ? "edit_action"
        : Boolean(permission.update)
        ? "edit_only"
        : Boolean(permission.delete)
        ? "delete_only"
        : null;
    setTableColumn((prev) => {
      return filtrateColumn(prev, actionColumn);
    });
  };

  useEffect(() => {
    if (
      permission &&
      (Boolean(permission.update) || Boolean(permission.delete))
    ) {
      updateTableColumn();
    }
  }, [permission]);

  return (
    <main>
      {/* <Sidebar
        visible={isSidebar}
        position="right"
        onHide={handleSideBarClose}
        className="!w-1/2 alert">
        {actionType === 'CREATE' && (
          <CreateAlert visible={formStatus.flag} onHide={handleSideBarClose} />
        )}
        {(actionType === 'EDIT' || actionType === 'VIEW') && (
          <EditAlert
            visible={formStatus.flag}
            onHide={handleSideBarClose}
            data={formStatus.data}
            actionType={actionType}
            onDelete={handleDelete}
          />
        )}
      </Sidebar> */}

      <CubeBreadCrumb items={items} />
      {/* <ConfirmDiaglog
        visible={deleteStatus.flag}
        dialogHeader={deleteStatus.header}
        dialogHeaderSubHeader={deleteStatus.subHeader}
        onHide={handleCloseDialog}
        confirmText={deleteStatus.name}
        onSubmit={triggerDelete}
      /> */}

      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5 !my-5">
        <div className="relative flex items-center w-42 !h-[35px] md:w-80">
          <input
            type="text"
            onChange={onSearchChange}
            placeholder="Search "
            className="bg-white border !h-[35px] border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:outline-none focus:ring-secondary focus:border-secondary block w-full pl-2 px-2 py-1"
          />
          <div className="absolute inset-y-0 right-4 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className="h-5 w-5 text-gray-500 justify-end" />
          </div>
        </div>

        <div className="flex justify-end gap-2">
          <Tooltip
            content="Preview unavailable - request demo"
            target=".newalert"
            position="top"
          />
          {Boolean(permission && permission.create) && (
            <Button
              label="New Alert"
              icon="pi pi-plus"
              iconPos="left"
              className="btn_primary !px-5 !py-2 !text-xs !font-bold newalert text-white"
              onClick={onCreateClick}
            />
          )}
        </div>
      </div>

      <div className="p-0">
        <CubeTable
          rowData={sortedAlertData}
          disableHeader
          disableDownload
          //   isSkeletonLoading={isAlertsLoading}
          columnData={tableColumn}
          customSearchInput={searchParam}
          handleDelete={handleDelete}
          handleEdit={handleFormOpen}
          handleView={handleView}
          rowSize={50}
          flag
          //   isError={isAlertsError}
          //   isLoading={isAlertsFetching}
        />
      </div>
    </main>
  );
}

export default AlertsList;
