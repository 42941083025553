import React, { useEffect, useState } from "react";
import CubeTreeView from "../Common/CubeTreeView";
import anomalyData from "../Jsonfiles/anomalyData.json";
// import excelRowData from "../Jsonfiles/excelRowData.json";
import { GoTriangleDown } from "react-icons/go";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";
import TreeBreadCrumb from "../Common/TreeBreadCrumb";
import { Dropdown } from "primereact/dropdown";
import { getDateRange, getMaxLength, handleBreadCrumb, handleCollapse } from "../../../helpers/helpers";

function AnomalyDetection() {
  const startDates = getDateRange(-2).detailedObj
  const endDates = getDateRange(-1).detailedObj
  const [expandedKey, setExpandedKey] = useState({});
  const [maxVal, setMaxVal] = useState(null);
  const handleExpand = () => {};
  const collapseTree = (event) => {
    const deletedExpandedKeys = handleCollapse(event, expandedKey);
    setExpandedKey(deletedExpandedKeys);
  };
  const handleGetGroupData = () => {};
  const handleColorFilterChange = () => {};
  const clearColorFilter = () => {};
  const selectedFilterKeys = [
    {
      field: "2023-december",
      label: `${startDates.monthShort} ${startDates.year}`,
      headerClassName: "!text-center",
    },
    {
      field: "2024-january",
      label: `${endDates.monthShort} ${endDates.year}`,
      headerClassName: "!text-center",
    },

    {
      field: "sigma_plus",
      label: "Runrate At 1.25σ",
      headerClassName: "!text-center",
    },
  ];

  const totalYearsCostData = {
    "2023-december": 372102,
    "2024-january": 350000,
    sigma_plus: 401371,
  };


  const tableColumn = [
    {
      field: "currentDrill",
      label: "Category",
      sort: true,
      style: {
        color: "gray",
        minWidth: "100px",
      },
    },
    {
      field: "name",
      label: "Name",
      sort: true,
      style: {
        color: "gray",
        minWidth: "150px",
      },
    },
    {
      field: "September 2023.cost",
      label: "Sep 2023",
      type: "number",
      style: {
        color: "gray",
        minWidth: "150px",
      },
      sort: true,
    },
    {
      field: "October 2023.cost",
      label: "Oct 2023",
      type: "number",
      style: {
        color: "gray",
        minWidth: "150px",
      },
      sort: true,
    },
    {
      field: "November 2023.cost",
      label: "Nov 2023",
      type: "number",
      style: {
        color: "gray",
        minWidth: "150px",
      },
      sort: true,
    },
    {
      field: "December 2023.cost",
      label: "Dec 2023",
      type: "number",
      style: {
        color: "gray",
        minWidth: "150px",
      },
      sort: true,
    },
    {
      field: "January 2024.cost",
      label: "Jan 2024",
      type: "number",
      style: {
        color: "gray",
        minWidth: "150px",
      },
      sort: true,
    },
    {
      field: "February 2024.cost",
      label: "Feb 2024",
      type: "number",
      style: {
        color: "gray",
        minWidth: "150px",
      },
      sort: true,
    },
    {
      label: "Runrate At 1.25σ",
      field: "sigma_plus",
      type: "number",
      sort: true,
      style: {
        minWidth: "175px",
      },
    },
  ];
  const toggle = (keys) => {
    setExpandedKey(keys);
  };

  const deleteExpandedKeys = (drillIndex) => {
    const updatedExpandkeys = handleBreadCrumb(drillIndex, expandedKey);
    setExpandedKey(updatedExpandkeys);
    setMaxVal(drillIndex - 1);
  };

  const getLength = () => {
    const maxval = getMaxLength(expandedKey);
    setMaxVal(maxval);
  };

  const handleReset = () => {
    setExpandedKey({});
  };
  useEffect(() => {
    getLength();
  }, [expandedKey]);

  return (
    <div>
      <div className="flex justify-between pb-1 border-b-2">
        <span className="font-semibold ">Anomaly Detection</span>
        <div>
          <Tooltip
            target=".amort"
            content="Preview unavailable - request demo"
            position="bottom"
          />
          <div className="flex amort">
            <div className="pr-2">Amortization</div>
            <InputSwitch disabled />
          </div>
        </div>
      </div>
      <div className="flex gap-4 py-4">
        {" "}
        <Dropdown
          tooltip="preview unavailable - request demo"
          // value={selectedView && selectedView.id}
          // onChange={handleViewChange}
          options={[]}
          // optionLabel="view_name"
          // optionValue="id"
          // optionLabel="view_name"
          // optionValue="id"
          placeholder={`${startDates.monthShort} ${startDates.startDate}, ${startDates.year} to ${endDates.monthShort} ${endDates.endDate}, ${endDates.year}`}
          // id="viewList"
          // id="viewList"
          dropdownIcon={
            <GoTriangleDown className="text-primary_light text-lg" />
          }
          className="!w-[360px] !h-[35px] md:!w-[250px] rounded xl: custom_select"
          // itemTemplate={viewListOptionTemp}
          // filter
          emptyMessage={null}
        />
        {/* <div className=" datePicker text-sm bg-white py-1.5 p-2 rounded border border-gray-200 dateTooltip text-slate-500">
          Jan 1, 2024 to Jan 31, 2024
        </div> */}
        <div className="flex items-center gap-1">
          <div className=" bg-gray-300 w-[1px] h-7"></div>
          <Tooltip
            target=".dateTooltip"
            content="Preview unavailable - request demo"
            position="bottom"
          />
          <div className="text-sm">Show the cost trend of</div>
          <Dropdown
            tooltip="preview unavailable - request demo"
            // value={selectedView && selectedView.id}
            // onChange={handleViewChange}
            options={[]}
            // optionLabel="view_name"
            // optionValue="id"
            // optionLabel="view_name"
            // optionValue="id"
            placeholder="Subscription Name"
            // id="viewList"
            // id="viewList"
            dropdownIcon={
              <GoTriangleDown className="text-primary_light text-lg" />
            }
            className="!w-[350px] !h-[35px] md:!w-[190px] rounded xl: custom_select"
            // itemTemplate={viewListOptionTemp}
            // filter
            emptyMessage={null}
          />
          <Tooltip
            target=".trendTooltip"
            content="Preview unavailable - request demo"
            position="bottom"
          />
        </div>
        <div className="flex items-center gap-1">
          <Tooltip
            target=".across"
            content="Preview unavailable - request demo"
            position="bottom"
          />
          <div className="text-sm">across</div>
          <Dropdown
            tooltip="preview unavailable - request demo"
            // value={selectedView && selectedView.id}
            // onChange={handleViewChange}
            options={[]}
            // optionLabel="view_name"
            // optionValue="id"
            // optionLabel="view_name"
            // optionValue="id"
            placeholder="Meter Category"
            // id="viewList"
            // id="viewList"
            dropdownIcon={
              <GoTriangleDown className="text-primary_light text-lg" />
            }
            className="!w-[350px] !h-[35px] md:!w-[160px] rounded xl: custom_select"
            // itemTemplate={viewListOptionTemp}
            // filter
            emptyMessage={null}
          />
        </div>
        <div className="flex items-center gap-1">
          <div className=" bg-gray-300 w-[1px] h-7"></div>

          <Tooltip
            target=".toleranceTooltip"
            content="Preview unavailable - request demo"
            position="bottom"
          />
          <div className="text-sm">Tolerance</div>
          <Dropdown
            tooltip="preview unavailable - request demo"
            // value={selectedView && selectedView.id}
            // onChange={handleViewChange}
            options={[]}
            // optionLabel="view_name"
            // optionValue="id"
            // optionLabel="view_name"
            // optionValue="id"
            placeholder="Low (1.25σ)"
            // id="viewList"
            // id="viewList"
            dropdownIcon={
              <GoTriangleDown className="text-primary_light text-lg" />
            }
            className="!w-[350px] !h-[35px] md:!w-[130px] rounded xl: custom_select"
            // itemTemplate={viewListOptionTemp}
            // filter
            emptyMessage={null}
          />

          {/* <div className="flex  datePicker text-sm bg-white py-1.5 p-2 rounded border-2 border-gray-200 toleranceTooltip text-slate-500">
            <div>Low (1.25σ)</div>{" "}
            <GoTriangleDown className="text-primary_light text-lg" />
          </div> */}
        </div>
      </div>
      <div>
        <TreeBreadCrumb
          data={[
            {
              label: "Meter Category",
            },
            {
              label: "Subscription Name",
            },
            {
              label: "Resource Group",
            },
            {
              label: "Resource Name",
            },
          ]}
          onClick={deleteExpandedKeys}
          maxVal={maxVal}
        />
        <CubeTreeView
          rowData={anomalyData}
          height="60vh"
          deviationData={"1.25"}
          handleExpand={handleExpand}
          handleCollapse={collapseTree}
          visibleColumns={selectedFilterKeys}
          //   ref={treeTableRef}
          expandedKey={expandedKey}
          type="anomaly"
          onToggle={toggle}
          groupData={totalYearsCostData}
          resetView={handleReset}
          isGroupView
          getGroupData={handleGetGroupData}
          tableColumn={tableColumn}
          // isExcelJs
          isAnomaly
          // anomalyRowData={excelRowData}
          isCheckBoxSelection
          handleColorFilterChange={handleColorFilterChange}
          clearColorFilter={clearColorFilter}
        />
      </div>
    </div>
  );
}

export default AnomalyDetection;
