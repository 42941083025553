import { IoMdClose } from "react-icons/io";

function ChartCard({
  title,
  children,
  subTitle,
  closeCard = false,
  onHide,
  isDatePicker = false,
  minWidth = "400px",
  minHeight,
}) {
  return (
    <div
      className={`bg-white shadow rounded-lg w-full h-[${minHeight}] rounded-tl-8 rounded-tr-8`}
    >
      <div
        className={`rounded-t-lg border bg-header px-6 py-2 flex  ${
          closeCard ? "flex-row justify-between" : "flex-col justify-center"
        } `}
      >
        <div
          className={`${
            isDatePicker && "flex w-full justify-between items-center"
          }`}
        >
          <h2 className="text-xs font-base text-secondary py-1">{title}</h2>
          {subTitle && <p className="text-xs text-secondary">{subTitle}</p>}
        </div>
        {closeCard && (
          <IoMdClose onClick={onHide} className="w-6 h-6 text-red-500" />
        )}
      </div>
      {children}
    </div>
  );
}

export default ChartCard;
