/* eslint no-console: "off" */
/* eslint no-alert: "off" */
/* eslint no-nested-ternary: "off" */
/* eslint camelcase: "off" */
/* eslint no-plusplus: "off" */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { GoTriangleDown } from "react-icons/go";
import { IoShareOutline } from "react-icons/io5";
import CubeBreadCrumb from "./Common/cubeBreadCrumb";
import CubeTreeView from "./Common/CubeTreeView";
import cumulativeData from "../../Components/Take_A_Tour/Jsonfiles/cumulativeData.json";
import TreeBreadCrumb from "./Common/TreeBreadCrumb";
import { getMaxLength, handleBreadCrumb } from "../../helpers/helpers";
import { getDateRange } from "../../helpers/helpers";

const items = [{ label: "Custom Reports" }];

const initialInfoState = {
  flag: false,
  title: "",
};
const initialShareState = {
  users: [],
  groups: [],
  reportType: null,
  shareModal: false,
  selectedShare: "",
  shareType: "everyone",
  selectedTab: "users",
};
const viewListOptionTemp = (option) => {
  return (
    <div className="font-neue-montreal flex align-items-center w-[100px] text-xs truncate ...">
      {option.view_name}
    </div>
  );
};
export const filterListOptionTemp = (option) => {
  return (
    <div className=" font-neue-montreal flex align-items-center w-[100px] text-xs truncate ...">
      {option.filter_name}
    </div>
  );
};
const initialFilterForm = {
  flag: false,
  data: null,
};
const initialRangeState = {
  type: "",
  value: [],
};
const firstMonth = getDateRange(-1).detailedObj;
const middleMonth = getDateRange(-2).detailedObj;
const lastMonth = getDateRange(-3).detailedObj;
const selectedFilterKeys = [
  {
    field: "2023-november",
    label: `${lastMonth.monthShort} ${lastMonth.year}`,
  },
  {
    field: "2023-december",
    label: `${middleMonth.monthShort} ${middleMonth.year}`,
  },
  {
    field: "2024-january",
    label: `${firstMonth.monthShort} ${firstMonth.year}`,
  },
];
const totalYearsCostData = {
  "2023-november": 1667664,
  "2023-december": 1702755,
  "2024-january": 2687882,
};

function CustomReports2() {
  const [viewList, setViewList] = useState([]);
  const [selectedView, setSelectedView] = useState(null);
  const [nodes, setNodes] = useState([
    {
      label: "Meter Category",
    },
    {
      label: "Meter Sub Category",
    },
  ]);
  const [isRangeChange, setIsRangeChange] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [canvasStatus, setCanvasStatus] = useState(false);
  const [isInfo, setIsInfo] = useState(initialInfoState);
  const treeTableRef = useRef();
  const shareOverLayRef = useRef(null);
  const location = useLocation();
  const [maxVal, setMaxVal] = useState(null);
  const [shareData, setShareData] = useState(initialShareState);
  const [showFilterForm, setShowFilterForm] = useState(initialFilterForm);
  const [filterQuery, setFilterQuery] = useState(null);
  const [reportRange, setReportRange] = useState(initialRangeState);
  const selectedMonths = "";
  const [expandedKey, setExpandedKey] = useState({});
  const captureRef = useRef(null);

  const openFilterForm = (data) => {
    setShowFilterForm({
      flag: true,
      data,
    });
  };

  const hideFilterForm = () => {};

  const fetchDrillCumulativeData = async (id, params, key, path) => {};

  const tooltipOptions = {
    position: "bottom",
  };

  const handleShareDataChange = (e) => {};

  const handleTabChange = (tab) => {};

  const handleShare = async () => {};

  const handleSchedule = async (email) => {};

  const handleShareApply = (email) => {};

  const shareTypeChange = (e) => {};

  const onHide = () => {};

  const handleShareModal = (selectedShareType) => {};

  const updateViewList = () => {};

  const resetView = () => {
    setExpandedKey({});
  };

  const getLength = () => {
    const maxval = getMaxLength(expandedKey);
    setMaxVal(maxval);
  };

  const handleViewChange = (e) => {};
  const handleInfo = (data, name) => {};

  const handleHide = () => {
    setIsInfo(initialInfoState);
  };

  const updateNewView = (value) => {};

  const handleView = (data) => {};

  const handleDelete = (id) => {};

  const handleExpand = (event) => {};

  const collapseTree = (event) => {};

  const updateLatestView = () => {};

  const fetchInitialCumulativeData = async () => {};

  const handleCanvasOpen = () => {};

  const handleCanvasClose = () => {};

  const handleReset = () => {
    setExpandedKey({});
  };

  const toggle = (keys) => {
    setExpandedKey(keys);
  };

  const handleGetGroupData = (groupData) => {};

  const deleteExpandedKeys = (drillIndex) => {
    const updatedExpandkeys = handleBreadCrumb(drillIndex, expandedKey);
    setExpandedKey(updatedExpandkeys);
    setMaxVal(drillIndex - 1);
  };

  const updateFilterQuery = (query) => {};

  const handleFilterChange = (e) => {};

  const handleRangeChange = (value) => {};

  const updateRangeValue = () => {};

  const updateInitialRange = () => {};

  const handleToggleChange = () => {};

  useEffect(() => {
    updateInitialRange();
  }, []);

  useEffect(() => {
    getLength();
  }, [expandedKey]);

  const skeletonState = skeletonLoader || isRangeChange;
  return (
    <main ref={captureRef} id="content">
      <div>
        <CubeBreadCrumb
          items={items}
          showToggle
          onToggleChange={handleToggleChange}
        />
      </div>
      <header className="flex flex-wrap pt-2 md:pt-3 justify-start md:flex-wrap tracking-[0.3px]">
        <div className="items-center grid grid-rows-2 md:grid-rows-1 grid-flow-col">
          <div className="flex">
            <p className="text-xs text-[#333333] mr-2 md:mr-0 md:pr-2 ">
              Select Report
            </p>
          </div>
          <div className="flex">
            <Dropdown
              tooltip="preview unavailable - request demo"
              // value={selectedView && selectedView.id}
              // onChange={handleViewChange}
              options={viewList}
              // optionLabel="view_name"
              // optionValue="id"
              // optionLabel="view_name"
              // optionValue="id"
              placeholder="Select a View"
              // id="viewList"
              // id="viewList"
              dropdownIcon={
                <GoTriangleDown className="text-primary_light text-lg" />
              }
              className="!w-[230px] !h-[35px] md:!w-[150px] rounded xl: custom_select"
              // itemTemplate={viewListOptionTemp}
              // filter
              emptyMessage={null}
            />
          </div>
        </div>
        <div className="flex items-center pl-6">
          <div className="flex items-center mr-3">
            <p className="text-xs text-[#333333] mr-2 md:mr-0 md:pr-2">
              Select Filter
            </p>
            <Dropdown
              tooltip="preview unavailable - request demo"
              // value={
              //   filterQuery && filterQuery.key === "filter_id"
              //     ? filterQuery.data
              //     : null
              // }
              // onChange={handleFilterChange}
              options={[]}
              // optionLabel="filter_name"
              // optionLabel="filter_name"
              placeholder="Select a filter"
              dropdownIcon={
                <GoTriangleDown className="text-secondary text-lg" />
              }
              className="!w-[230px] !h-[35px] md:!w-[150px] rounded xl: custom_select"
              // itemTemplate={filterListOptionTemp}
              // filter
            />
          </div>
          <Button
            icon="pi pi-filter"
            iconPos="left"
            className="icon_btn_primary !w-[35px] !h-[35px] !text-xs text-white !font-light"
            onClick={() => openFilterForm(filterQuery)}
            tooltip="preview unavailable - request demo"
            tooltipOptions={tooltipOptions}
          />
          {filterQuery && (
            <div
              className="text-secondary underline mx-4 cursor-pointer"
              onClick={() => updateFilterQuery(null)}
              aria-hidden
            >
              Clear Filter
            </div>
          )}
        </div>
        <div className="flex items-center xl:ml-auto lg:ml-auto md:ml-10"></div>
        <div className="flex items-center mx-2 gap-2">
          <Dropdown
            tooltip="preview unavailable - request demo"
            // value={
            //   filterQuery && filterQuery.key === "filter_id"
            //     ? filterQuery.data
            //     : null
            // }
            // onChange={handleFilterChange}
            options={[]}
            // optionLabel="filter_name"
            // optionLabel="filter_name"
            placeholder={`${lastMonth.monthShort} ${lastMonth.startDate},${lastMonth.year} - ${firstMonth.monthShort} ${firstMonth.endDate},${firstMonth.year}`}
            dropdownIcon={<GoTriangleDown className="text-secondary text-lg" />}
            className="!w-[270px] !h-[35px] md:!w-[245px] rounded xl: custom_select"
            // itemTemplate={filterListOptionTemp}
            // filter
          />
          <Button
            label="Create / View"
            icon="pi pi-plus"
            iconPos="left"
            className="btn_primary !h-[35px] !px-5 text-white !py-3 !text-xs !font-bold"
            onClick={handleCanvasOpen}
            tooltip="preview unavailable - request demo"
            tooltipOptions={tooltipOptions}
          />
        </div>

        <div className="py-2 flex lg:items-center  md:items-center xl:items-center">
          <Button
            tooltip="preview unavailable - request demo"
            icon={
              tableLoader || skeletonState ? (
                "pi pi-spin pi-spinner"
              ) : (
                <IoShareOutline className="w-[20px] h-[20px] text-white" />
              )
            }
            rounded
            aria-label="Filter"
            className={`btn_csv_primary !rounded-lg visible ${
              tableLoader || skeletonState ? "pointer-events-none" : ""
            }`}
          />
        </div>
      </header>
      <div>
        <TreeBreadCrumb
          data={nodes}
          onClick={deleteExpandedKeys}
          maxVal={maxVal}
        />
      </div>
      <section className="p-0">
        <CubeTreeView
          routeName='customReports'
          rowData={cumulativeData}
          handleExpand={handleExpand}
          handleCollapse={collapseTree}
          visibleColumns={selectedFilterKeys}
          isLoading={tableLoader}
          groupData={totalYearsCostData}
          isCheckBoxSelection
          ref={treeTableRef}
          resetView={handleReset}
          nodeData={nodes}
          selectedViewData={`Custom Reports (${
            selectedView && selectedView.view_name ? selectedView.view_name : ""
          })`}
          isGroupView
          getGroupData={handleGetGroupData}
          handleInfo={handleInfo}
          expandedKey={expandedKey}
          onToggle={toggle}
        />
      </section>
    </main>
  );
}

export default CustomReports2;
