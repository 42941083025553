/* eslint no-nested-ternary: "off" */
import { filtrateColumn } from "../../../helpers/helpers";
import UserGroups from "./userGroup";
import { useEffect, useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { Button } from "primereact/button";
import CubeBreadCrumb from "../Common/cubeBreadCrumb";
import CubeTable from "../TagGovComponents/cubeTable";
import { Tooltip } from "primereact/tooltip";
import RolesAndPermissions from "./rolesAndpermissions";

const items = [{ label: "Admin Center" }];
const userItems = [{ label: "Users" }];
const initialUserState = {
  flag: false,
  data: null,
};

const column = [
  { field: "name", label: "Name", type: "user_profile" },
  { field: "email", label: "Email Address" },
  { field: "created_at", label: "Created" },
  { field: "id", label: "Actions", type: "edit_action" },
];

const initialDeleteState = {
  flag: false,
  data: null,
  header:
    "Are you sure want to delete this data? All of this selected data will be deleted. This action cannot be undone",
  subHeader: "",
  name: "",
};

function AdminCenter() {
  const permission = {
    create: 1,
    read: 1,
    update: 1,
    delete: 1,
    edit: 1,
  };
  const [userStatus, setUserStatus] = useState(initialUserState);
  const [deleteStatus, setDeleteStatus] = useState(initialDeleteState);
  const [search, setSearch] = useState("");
  const [tableColumn, setTableColumn] = useState(column);
  //   const { data: userList = [], refetch, isError, isFetching, isLoading } = useGetUserListQuery();
  //   const [deleteUser] = useDeleteUserMutation();
  //   const dispatch = useDispatch();

  const openUserModal = (data) => {
    const formData =
      data && Object.keys(data).length
        ? { ...data, lastName: data.last_name }
        : data;
    setUserStatus({
      flag: true,
      data: formData,
    });
  };

  const handleHide = () => {
    setUserStatus(initialUserState);
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleEdit = (data) => {
    openUserModal(data);
  };

  const handleDelete = (data) => {
    const selectedDataName = data && data.email ? data.email : "";
    setDeleteStatus((prev) => {
      return {
        ...prev,
        flag: true,
        data,
        subHeader: `Type ${selectedDataName} below to confirm the deletion`,
        name: selectedDataName,
        userId: data.id,
      };
    });
  };

  const updateTableColumn = () => {
    const actionColumn = { field: "id", label: "Actions", type: "" };
    actionColumn.type =
      Boolean(permission.edit) && Boolean(permission.delete)
        ? "edit_action"
        : Boolean(permission.edit)
        ? "edit_only"
        : Boolean(permission.delete)
        ? "delete_only"
        : null;
    setTableColumn((prev) => {
      return filtrateColumn(prev, actionColumn);
    });
  };

  useEffect(() => {
    if (
      permission &&
      (Boolean(permission.edit) || Boolean(permission.delete))
    ) {
      updateTableColumn();
    }
  }, [permission]);

  return (
    <main>
      <CubeBreadCrumb items={items} noBottomBorder />

      <div className="flex justify-evenly gap-2 pb-1">
        <div className="w-full">
          <nav>
            <CubeBreadCrumb items={userItems} />
          </nav>
          <header className="w-full flex-wrap md:flex md:justify-between items-center mt-3">
            <div className="relative flex w-42 md:w-80">
              <input
                type="text"
                placeholder="Search"
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:outline-none focus:ring-secondary focus:border-secondary block w-full pl-2 px-2 py-1.5 md:w-[500px] lg:w-[500px] xl:w-[500px]"
                onChange={handleSearchChange}
              />
              <div className="absolute inset-y-0 right-4 flex items-center pl-3 pb-1 pointer-events-none">
                <HiOutlineSearch className="justify-end items-center h-5 w-5 text-gray-500" />
              </div>
            </div>
            <Tooltip target='.addUser' content='Preview unavailable - request demo' position="bottom"/>
            {permission && Boolean(permission.create) && (
              <Button
                label="Add User"
                icon="pi pi-plus"
                iconPos="right"
                className="btn_primary !px-4 !py-1 !h-[35px] !text-xs !font-bold text-white addUser"
                onClick={() => openUserModal(null)}
              />
            )}
          </header>
          <section className="mt-2">
            <CubeTable
              rowData={[
                {
                  id: 18,
                  name: "John",
                  email: "john@gmail.com",
                  email_verified_at: "2/12/23",
                  created_at: "2/12/23",
                  updated_at: "2/12/23",
                  profile_photo: "",
                  role_name: "Admin",
                  role_id: "1",
                },
                {
                  id: 19,
                  name: "Noah",
                  email: "noah@yahoo.com",
                  email_verified_at: "18/12/23",
                  created_at: "18/12/23",
                  updated_at: "18/12/23",
                  profile_photo: "",
                  role_name: "Admin",
                  role_id: "1",
                }
              ]}
              disableHeader
              disableDownload
              customSearchInput={search}
              columnData={tableColumn}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              rowSize={5}
              //   isError={isError}
              //   isLoading={isFetching}
              //   isSkeletonLoading={isLoading}
              csvFileName="User"
              adminCenter
            />
          </section>
        </div>
        <div className="w-full">
          <UserGroups adminCenter />
        </div>
      </div>
      <div>
        <RolesAndPermissions adminCenter />
      </div>
    </main>
  );
}

export default AdminCenter;
