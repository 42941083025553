import React, { useRef, useState } from "react";
import { RiDashboardLine } from "react-icons/ri";
import { TbTableOptions } from "react-icons/tb";
import { RxLayers } from "react-icons/rx";
import { HiOutlineTag, HiOutlineBellAlert } from "react-icons/hi2";
import { IoMdAnalytics } from "react-icons/io";
import { FaSearchDollar } from "react-icons/fa";
import { IoBarChartOutline, IoSpeedometerOutline } from "react-icons/io5";
import { AiOutlineCloudServer } from "react-icons/ai";
import { FaHandHoldingDollar, FaMoneyBillTrendUp } from "react-icons/fa6";
import TCO from "../assets/icons/tco.svg";
import BYO from "../assets/icons/byo_single.png";
import TCOINV from "../assets/icons/tco_inv.svg";

import cloudCenter from "../assets/icons/cloud_center.png";
import cloudX from "../assets/icons/cloud_x.png";
import cloudY from "../assets/icons/cloud_y.png";
import cloudZ from "../assets/icons/cloud_z.png";
import useIsVisible from "./UseIsVisible";
import "../css/MobileService.css";

const serviceBox = [
    {
        icon: <FaHandHoldingDollar className=" w-8 h-8" />,
        subHeader: "Real-time Spend",
    },
    {
        icon: "",
        subHeader: "Show-back Model",
    },
    {
        icon: <IoSpeedometerOutline className=" w-8 h-8" />,
        subHeader: "Cost spike-monitor",
    },
    {
        icon: <TbTableOptions className=" w-8 h-8" />,
        subHeader: "Single-pane-view",
    },
    {
        icon: <FaMoneyBillTrendUp className=" w-8 h-8" />,
        subHeader: "Savings Potentials",
    },
    {
        icon: <IoMdAnalytics className=" w-8 h-8" />,
        subHeader: "PaaS Analytics",
    },
];

const captionOne = [
    {
        icon: <TbTableOptions className="text-primary_light w-16 h-16" />,
        subHeader: "FinOps Health",
        context: "Offers a summarized dashboard highlighting the different trends in your cloud spend",
    },
    {
        icon: <RxLayers className="text-primary_light w-16 h-16" />,
        subHeader: "Custom Reports",
        context: "Enable users to create customized reports based on the raw data brought in from the cloud",
        subContext: "Allows for extensive data filtering on the UI",
    },
    {
        icon: <RiDashboardLine className="text-primary_light w-16 h-16" />,
        subHeader: "Tag key Auditor",
        context: "Tracks and shows the various tag keys that are in use overall",
        subContext: "Allows for tag cleanups, tracking the percentage of resourcing using each tag",
    },

    {
        icon: <HiOutlineTag className="text-primary_light w-16 h-16" />,
        subHeader: "Monthly Tag Maturity",
        context:
            "Shows a trend of the percentage of tagged resources in each business unit or cloud accounts/subscriptions",
    },
    {
        icon: <HiOutlineBellAlert className="text-primary_light w-16 h-16" />,
        subHeader: "Alert Management",
        context: "Enables creating budget alerts for applications to be notified of cost spikes through the month",
    },
    {
        icon: <IoMdAnalytics className="text-primary_light w-16 h-16" />,
        subHeader: "PaaS Analytics",
        context:
            "Resource sizing & optimization recommendations for PaaS services like Databricks, ARO, EKS, Snowflake ",
    },

    {
        icon: <FaSearchDollar className="text-primary_light w-16 h-16" />,
        subHeader: "Anomaly Detection",
        context:
            "Looks at the past months’ spend across any collection of resources to call out any anomalies or sudden spikes in spend",
    },
    {
        icon: <IoBarChartOutline className="text-primary_light w-16 h-16" />,
        subHeader: "Forecast Engine",
        context: "Takes the past month spend to plot out a forecast for the entire year",
        subContext: "Highly configurable and filter-able      ",
    },
    {
        icon: <AiOutlineCloudServer className="text-primary_light w-16 h-16" />,
        subHeader: "Multi-Cloud Dashboarding",
        context:
            "CloudLens is a single tool to pour in data from a multitude of providers and plan for a cohesive optimization",
    },
];
const Service = ({ normalSrc, hoverSrc, subHeader, icon }) => {
    const elementRef = useRef();
    useIsVisible(elementRef);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const [isHovered, setIsHovered] = useState(false);

    const Box = () => {
        return (
            <div className="flex flex-col justify-center items-center my-36 h-[400px] ">
                <div className="flex flex-wrap justify-center cloud__grp absolute">
                    <div className="flex flex-row" ref={elementRef}>
                        <img src={cloudX} alt="cloudx" className="cloudX " />
                        <img src={cloudZ} alt="cloudz" className="cloudZ " />
                    </div>
                    <div className="flex flex-row">
                        <img src={cloudCenter} alt="cloudCenter" className="cloudCenter " />
                        <img src={cloudY} alt="cloudy" className="cloudY" />
                    </div>
                </div>
            </div>
        );
    };

    const MobileBox = () => {
        return (
            <div className="flex flex-col justify-center items-center md:my-20 h-[150px] md:h-[200px]">
                <div className="flex flex-wrap px-5 justify-center cloud__grp absolute w-[100%] md:w-[90%]">
                    <img src={BYO} alt="BYO" />
                </div>
            </div>
        );
    };
    return (
        <div>
            <div className="web__view__service">
                <div className="bg-primary_theme">
                    <div className=" container mx-auto">
                        <div className=" text-white flex justify-between items-center py-12 gap-12">
                            <div className="w-1/2">
                                <h4 className="font-bold text-[35px] my-2">
                                    Value adds of CloudLens<span className="dot">.</span>
                                </h4>
                                <p>
                                    Having a FinOps tool in place makes your cloud optimization efforts seamless by
                                    taking you away from the hassle of going through large datasets to directly
                                    consuming actionable information.
                                </p>
                            </div>
                            <div className="w-1/2 flex flex-wrap">
                                {serviceBox.map((e, index) => (
                                    <div key={index} className=" w-1/3 text-center">
                                        <div
                                            className="bg-[#b7e6f9] hover:bg-transparent flex flex-col items-center rounded-lg m-3 md:max-lg:m-2 p-3 hover:text-[#b7e6f9] text-primary_theme border cursor-pointer hover:border-secondary_light"
                                            onMouseEnter={() => {
                                                index === 1 && setIsHovered(true);
                                            }}
                                            onMouseLeave={() => {
                                                index === 1 && setIsHovered(false);
                                            }}
                                        >
                                            {e.icon ? (
                                                <span className="">{e.icon}</span>
                                            ) : (
                                                <img src={isHovered ? TCO : TCOINV} className="w-8 h-8" alt="tco" />
                                            )}
                                            <span className="">{e.subHeader}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div id="product">
                    <header className="flex flex-col justify-center items-center">
                        <p className="text-primary text-[35px] py-5 mt-16 leading-10 font-medium">
                            Why not other FinOps Tools<span className="dot">?</span>
                        </p>
                        <div className="mb-10 flex flex-wrap w-full justify-center items-center">
                            <span className="text-[17px] mx-auto text-[#7791A5] font-normal w-[900px] leading-[30px] text-center">
                                While other products speak the language of the cloud, we speak the language of the
                                customer
                            </span>
                        </div>
                    </header>
                    <p className="text-primary text-[20px] text-light container mx-auto">
                        Here is our 3-dimensional approach for your FinOps
                        <span className="dot">:</span>
                    </p>
                    <Box />
                </div>

                <div className="bg-secondary_light mt-10">
                    <div className="container mx-auto">
                        <main className="py-24 flex-col">
                            <header className="flex flex-col justify-center items-center">
                                <p className="text-[#14304A] text-[35px] leading-10 font-medium">
                                    Other key offerings of CloudLens<span className="dot">.</span>
                                </p>
                                <div className="my-10 flex flex-wrap w-full justify-center items-center">
                                    <span className="text-[17px] mx-auto text-[#7791A5] font-normal w-[900px] leading-[30px] text-center">
                                        While many products align with the cloud-centric language, our focus is on
                                        understanding and addressing the needs of the customer, ensuring a
                                        business-centric approach that resonates with cloud providers.
                                    </span>
                                </div>
                            </header>
                            <section>
                                <div className="grid grid-cols-3 gap-5">
                                    {captionOne.map((card, index) => (
                                        <div
                                            className={`items-stretch bg-white service__box rounded-xl duration-500 p-5 transform hover:scale-105 !z-0 ${
                                                hoveredIndex === index ? "z-10" : ""
                                            }`}
                                            key={index}
                                            onMouseEnter={() => setHoveredIndex(index)}
                                            onMouseLeave={() => setHoveredIndex(null)}
                                        >
                                            <div className="flex flex-col justify-center items-center text-center">
                                                <span className="py-7 icon">{card.icon}</span>
                                                <div>
                                                    <p className="mb-[15px] text-[20px] text-primary font-semibold">
                                                        {card.subHeader}
                                                    </p>
                                                    <div className="px-5 text-start">
                                                        <ul className="list-disc">
                                                            <li className="text-[15px] text-secondary font-normal">
                                                                {card.context}
                                                            </li>
                                                            {card.subContext && (
                                                                <li className="text-[15px] text-secondary font-normal">
                                                                    {card.subContext}
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        </main>
                    </div>
                </div>
            </div>
            <div className="mobile__view__service">
                <div className="bg-primary_theme">
                    <div className=" container mx-auto">
                        <div className=" text-white flex flex-col justify-between items-center py-6 gap-12">
                            <div className="p-5">
                                <h4 className="font-bold text-[25px] my-2">
                                    Value adds of CloudLens<span className="dot">.</span>
                                </h4>
                                <p>
                                    Having a FinOps tool in place makes your cloud optimization efforts seamless by
                                    taking you away from the hassle of going through large datasets to directly
                                    consuming actionable information.
                                </p>
                            </div>
                            <div className=" flex flex-wrap">
                                {serviceBox.map((e, index) => (
                                    <div key={index} className=" w-1/3 text-center">
                                        <div
                                            className="bg-[#b7e6f9] hover:bg-transparent flex flex-col items-center rounded-lg m-3 p-3 hover:text-[#b7e6f9] text-primary_theme border cursor-pointer hover:border-secondary_light"
                                            onMouseEnter={() => {
                                                index === 1 && setIsHovered(true);
                                            }}
                                            onMouseLeave={() => {
                                                index === 1 && setIsHovered(false);
                                            }}
                                        >
                                            {e.icon ? (
                                                <span className="">{e.icon}</span>
                                            ) : (
                                                <img src={isHovered ? TCO : TCOINV} className="w-8 h-8" alt="tco" />
                                            )}
                                            <span className="">{e.subHeader}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <header className="flex flex-col justify-center items-center">
                        <p className="text-primary text-[25px] py-3 mt-8 leading-10 font-medium">
                            Why not other FinOps Tools<span className="dot">?</span>
                        </p>
                        <div className="flex flex-wrap w-full justify-center items-center">
                            <span className="text-[17px] mx-auto text-[#7791A5] font-normal p-5 text-center">
                                While other products speak the language of the cloud, we speak the language of the
                                customer
                            </span>
                        </div>
                    </header>
                    <p className="text-primary text-[17px] p-5 text-light container mx-auto">
                        Here is our 3-dimensional approach for your FinOps
                        <span className="dot">:</span>
                    </p>
                    <MobileBox />
                </div>

                <div className="bg-secondary_light mt-10">
                    <div className="container mx-auto">
                        <main className="py-12 px-5 flex-col">
                            <header className="flex flex-col items-center">
                                <p className="text-[#14304A] text-[25px] font-medium">
                                    Other key offerings of CloudLens<span className="dot">.</span>
                                </p>
                                <div className="my-10 flex flex-wrap w-full items-center">
                                    <span className="text-[17px] mx-auto text-[#7791A5] font-normal w-[900px] leading-[30px]">
                                        While many products align with the cloud-centric language, our focus is on
                                        understanding and addressing the needs of the customer, ensuring a
                                        business-centric approach that resonates with cloud providers.
                                    </span>
                                </div>
                            </header>
                            <section>
                                <div className="grid md:grid-cols-3 gap-5">
                                    {captionOne.map((card, index) => (
                                        <div
                                            className={`items-stretch bg-white service__box rounded-xl duration-500 p-5 transform hover:scale-105 !z-0 ${
                                                hoveredIndex === index ? "z-10" : ""
                                            }`}
                                            key={index}
                                            onMouseEnter={() => setHoveredIndex(index)}
                                            onMouseLeave={() => setHoveredIndex(null)}
                                        >
                                            <div className="flex flex-col justify-center items-center text-center">
                                                <span className="py-7 icon">{card.icon}</span>
                                                <div>
                                                    <p className="mb-[15px] text-[20px] text-primary font-semibold">
                                                        {card.subHeader}
                                                    </p>
                                                    <div className="px-5 text-start">
                                                        <ul className="list-disc">
                                                            <li className="text-[15px] text-secondary font-normal">
                                                                {card.context}
                                                            </li>
                                                            {card.subContext && (
                                                                <li className="text-[15px] text-secondary font-normal">
                                                                    {card.subContext}
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;
