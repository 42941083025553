import React, { useRef, useState } from "react";
import Search from "../assets/icons/search.png";
import Cloud from "../assets/icons/cloud.png";
import Icon from "../assets/icons/icon.png";
import YouAreHere from "../assets/icons/you_are_here.svg";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { isValidEmail } from "../helpers/helpers";
import "../css/MobileOverview.css";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
const Overview = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  // Generate a random string for the CAPTCHA
  const generateCaptcha = () => {
    const captchaChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += captchaChars.charAt(
        Math.floor(Math.random() * captchaChars.length)
      );
    }
    setCaptchaText(captcha);
  };

  const openModal = () => {
    if (!email || !isValidEmail(email)) {
      showError("Please enter valid mail !");

      return;
    }
    setIsModalOpen(true);
    generateCaptcha(); // Generate CAPTCHA when modal opens
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUserInput(""); // Clear user input when modal closes
  };

  const verifyCaptcha = () => {
    if (userInput === captchaText) {
      setIsCaptchaVerified(true);
      // alert('CAPTCHA Verified!');
      handleTrial();
      closeModal();
    } else {
      setIsCaptchaVerified(false);
      alert("Incorrect CAPTCHA. Please try again.");
      generateCaptcha(); // Regenerate CAPTCHA on failure
    }
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Request sent successfully!",
      life: 3000,
    });
  };
  const showError = (msg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const handleTrial = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://122.165.79.72:5000/request-trial", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      if (!response.ok) {
        setLoading(false);
        showError("Something went wrong.");

        throw new Error("Failed to submit form");
      } else {
        setLoading(false);
        setEmail("");
        showSuccess();

        console.log(response, "successfull send");
      }
    } catch (error) {
      showError("Something went wrong.");

      // Handle errors here
      console.error("Error submitting email:", error.message);
      // Optionally, you can show an error message to the user
    }
  };

  return (
    <div id="overview">
      <Toast ref={toast} />

      <div className="web__view__overview">
        <div className=" container mx-auto">
          <div className=" flex flex-col items-center">
            <h2 className=" text-center text-primary mt-20 font-semibold font-sans text-3xl leading-normal">
              Cloud adoption has had a trade-off on your
              <br /> spend visibility<span className="dot">!</span> <br />{" "}
              <span className="font-medium text-xl">
                A healthy FinOps practice will ensure you keep your spend in
                check
                <span className="dot">.</span>
              </span>
            </h2>
          </div>
        </div>

        <div className="container mx-auto  ">
          <div className="flex flex-row justify-around card__box columns-3">
            <div
              className="bg-white-500 flex flex-row text-left p-3 mx-5 bg-white overview__grid__box w-1/3"
              style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
              <img
                src={Search}
                alt="search"
                className="w-[180px] h-[90px] m-auto"
              />
              <div className="text-center">
                <h3 className="text-primary py-5 font-bold">When you began</h3>
                <p className="text-secondary pb-5">
                  Initially, with the adoption of the cloud, visibility was
                  given up for efficiency.
                </p>
              </div>
            </div>
            <div
              className="bg-white-500 flex flex-row text-left p-3 mx-5 bg-white overview__grid__box w-1/3"
              style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
              <img
                src={Cloud}
                alt="search"
                className="w-[180px] h-[90px] m-auto"
              />

              <div className="text-center">
                <div className="flex justify-center items-center">
                  <h3 className="text-primary py-5 font-bold">
                    You are here &nbsp;
                  </h3>
                  <img src={YouAreHere} className="w-6 mb-1 h-6" alt="you" />
                </div>
                <p className="text-secondary pb-5">
                  There is a need for a holistic FinOps tool to regain the
                  visibility of your cloud usage.
                </p>
              </div>
            </div>
            <div
              className="bg-white-500 flex flex-row text-left p-3 mx-5 bg-white overview__grid__box w-1/3"
              style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
              <img src={Icon} alt="search" className="w-auto h-[90px] m-auto" />

              <div className="text-center">
                <h3 className="text-primary py-5 font-bold">
                  Your current need​
                </h3>
                <p className="text-secondary pb-5">
                  How regain control of your cloud resources and spend?
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-secondary_light">
          <div className="container mx-auto ">
            <div className="flex flex-row  justify-between mt-10 pt-36 pb-14 card__container">
              <div className="p-10 w-1/2">
                <h2 className="text-primary font-bold text-[35px] ">
                  Why Choose CloudLens as Your FinOps Partner
                  <span className="dot">?</span>
                </h2>
                {/* <p className="text-secondary my-2">
                Lorem ipsum dolor sit amet consectetur adipiscing elitsed do
                eiusmod tempor incididunt utlabore et dolore magna aliqua.
                Utenim ad minim veniam quis nostrud exercitation ullamco laboris
                nisi ut aliquo.
              </p> */}
              </div>
              <div className="p-10 w-1/2">
                <div className="flex flex-row justify-between divide-x divide-secondary">
                  <div className="pr-10 w-1/2">
                    <span className="text-[40px]">
                      <span className=" text-primary font-bold">15% - 20%</span>
                      {/* <span className=" text-primary_light">
                      &nbsp;Mn+​
                    </span> */}
                    </span>
                    <h5 className="font-light text-xl text-primary ">
                      average cloud optimation realized
                    </h5>
                  </div>
                  <div className="pl-10 w-1/2">
                    <span className="text-[40px]">
                      <span className=" text-primary font-bold">3</span>
                      {/* <span className=" text-primary_light">
                      %
                    </span> */}
                    </span>
                    <h5 className="font-light text-xl text-primary ">
                      major CSPs supported
                    </h5>
                  </div>
                </div>
                <br />
                <div className="flex flex-row justify-between divide-x divide-secondary">
                  <div className="pr-10 w-1/2">
                    <span className="text-[40px]">
                      <span className=" text-primary font-bold ">350+</span>
                      {/* <span className=" text-primary_light">
                      +
                    </span> */}
                    </span>
                    <h5 className="font-light text-xl text-primary ">
                      cloud services' finops managed
                    </h5>
                  </div>

                  <div className="pl-10 w-1/2">
                    <span className="text-[40px]">
                      <span className=" text-primary font-bold">50+</span>
                      {/* <span className=" text-primary_light">
                      %
                    </span> */}
                    </span>
                    <h5 className="font-light text-xl text-primary ">
                      person-years of cloud expertise
                    </h5>
                  </div>
                </div>
                {/* <div className="text-secondary">
                Lorem ipsum dolor sit amet consectetur adipiscing elitsed do
                eiusmod tempor incididunt utlabore et dolore magna aliqua.
                Utenim ad minim veniam quis nostrud exercitation ullamco laboris
                nisi ut aliquo.
              </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-primary_theme text-white">
          <div className="container mx-auto">
            <div className="flex flex-row columns-2  justify-between py-20">
              <div className="w-full p-10">
                <div>
                  <h3 className="font-medium font-sans text-4xl">
                    Get hands-on with a 15-day trial, absolutely free.{" "}
                    <span className="font-light">
                      Sign Up now for a smarter financial future!
                    </span>
                    <span className="dot">.</span>
                  </h3>
                  {/* <p className="my-2">Lorem ipsum dolor sit amet no sea takimata sanctus est Lorem ipsum dolor sit amete sare nostrud exercitation ullamco sea takiquis.</p> */}
                </div>
              </div>
              <div className="w-full xl:p-10 lg:ml-10">
                <div>
                  <span>Type your email</span>
                  <div>
                    <input
                      type="email"
                      value={email || ""}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={loading}
                      className="w-72 px-2 py-1 outline-none text-primary"
                      required
                    />
                    <button className="btn-grad-blue" onClick={openModal}>
                      {loading ? (
                        <ProgressSpinner className="my-spinner h-4" />
                      ) : (
                        "Request Trial"
                      )}
                    </button>
                  </div>
                  {/* <div className="flex flex-start">
                  <input type="checkbox" />
                  <span className="text-xs ml-3">
                    You accept the terms of service and the privacy policy.
                  </span>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile__view__overview">
        <div className=" container mx-auto">
          <div className=" flex flex-col items-center">
            <h2 className=" text-center text-primary mt-20 px-5 font-semibold font-sans text-2xl leading-normal">
              Cloud adoption has had a trade-off on your spend visibility
              <span className="dot">!</span> <br />{" "}
              <span className="font-medium text-lg">
                A healthy FinOps practice will ensure you keep your spend in
                check
                <span className="dot">.</span>
              </span>
            </h2>
          </div>
        </div>

        <div className="container mx-auto  ">
          <div className="flex flex-col justify-around card__box ">
            <div
              className="bg-white-500 flex flex-col text-left p-3 m-7 bg-white mobile__overview__grid__box"
              style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
              <img
                src={Search}
                alt="search"
                className="w-auto h-[90px] m-auto"
              />
              <div className="text-center">
                <h3 className="text-primary py-5 font-bold">When you began</h3>
                <p className="text-secondary pb-5">
                  Initially, with the adoption of the cloud, visibility was
                  given up for efficiency.
                </p>
              </div>
            </div>
            <div
              className="bg-white-500 flex flex-col text-left p-3 m-7 bg-white mobile__overview__grid__box"
              style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
              <img
                src={Cloud}
                alt="search"
                className="w-auto h-[90px] m-auto"
              />

              <div className="text-center">
                <div className="flex justify-center items-center">
                  <h3 className="text-primary py-5 font-bold">
                    You are here &nbsp;
                  </h3>
                  <img src={YouAreHere} className="w-6 mb-1 h-6" alt="you" />
                </div>
                <p className="text-secondary pb-5">
                  There is a need for a holistic FinOps tool to regain the
                  visibility of your cloud usage.
                </p>
              </div>
            </div>
            <div
              className="bg-white-500 flex flex-col text-left p-3 m-7 bg-white mobile__overview__grid__box"
              style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
            >
              <img src={Icon} alt="search" className="w-auto h-[90px] m-auto" />

              <div className="text-center">
                <h3 className="text-primary py-5 font-bold">
                  Your current need​
                </h3>
                <p className="text-secondary pb-5">
                  How regain control of your cloud resources and spend?
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-secondary_light">
          <div className="container mx-auto ">
            <div className="flex justify-between mt-10 pt-10 pb-14 card__container mobile-why-choose">
              <div className="p-5">
                <h2 className="text-primary font-bold text-[25px] ">
                  Why Choose CloudLens as Your FinOps Partner
                  <span className="dot">?</span>
                </h2>
                {/* <p className="text-secondary my-2">
                Lorem ipsum dolor sit amet consectetur adipiscing elitsed do
                eiusmod tempor incididunt utlabore et dolore magna aliqua.
                Utenim ad minim veniam quis nostrud exercitation ullamco laboris
                nisi ut aliquo.
              </p> */}
              </div>
              <div className="p-5 ">
                <div className="flex flex-row justify-between divide-x divide-secondary">
                  <div className="pr-5 w-1/2">
                    <span className="text-[30px]">
                      <span className=" text-primary font-bold">15% - 20%</span>
                      {/* <span className=" text-primary_light">
                      &nbsp;Mn+​
                    </span> */}
                    </span>
                    <h5 className="font-light text-xl text-primary ">
                      average cloud optimation realized
                    </h5>
                  </div>
                  <div className="pl-5 w-1/2">
                    <span className="text-[30px]">
                      <span className=" text-primary font-bold">3</span>
                      {/* <span className=" text-primary_light">
                      %
                    </span> */}
                    </span>
                    <h5 className="font-light text-xl text-primary ">
                      major CSPs supported
                    </h5>
                  </div>
                </div>
                <br />
                <div className="flex flex-row justify-between divide-x divide-secondary">
                  <div className="pr-5 w-1/2">
                    <span className="text-[30px]">
                      <span className=" text-primary font-bold ">350+</span>
                      {/* <span className=" text-primary_light">
                      +
                    </span> */}
                    </span>
                    <h5 className="font-light text-xl text-primary ">
                      cloud services' finops managed
                    </h5>
                  </div>

                  <div className="pl-5 w-1/2">
                    <span className="text-[30px]">
                      <span className=" text-primary font-bold">50+</span>
                      {/* <span className=" text-primary_light">
                      %
                    </span> */}
                    </span>
                    <h5 className="font-light text-xl text-primary ">
                      person-years of cloud expertise
                    </h5>
                  </div>
                </div>
                {/* <div className="text-secondary">
                Lorem ipsum dolor sit amet consectetur adipiscing elitsed do
                eiusmod tempor incididunt utlabore et dolore magna aliqua.
                Utenim ad minim veniam quis nostrud exercitation ullamco laboris
                nisi ut aliquo.
              </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-primary_theme text-white">
          <div className="container mx-auto">
            <div className="flex columns-2 justify-between py-5 mobile-trial-sign-up">
              <div className="w-full p-5">
                <div>
                  <h3 className="font-medium font-sans text-2xl">
                    Get hands-on with a 15-day trial, absolutely free.{" "}
                    <span className="font-light">
                      Sign Up now for a smarter financial future!
                    </span>
                    <span className="dot">.</span>
                  </h3>
                  {/* <p className="my-2">Lorem ipsum dolor sit amet no sea takimata sanctus est Lorem ipsum dolor sit amete sare nostrud exercitation ullamco sea takiquis.</p> */}
                </div>
              </div>
              <div className="w-full p-5">
                <div>
                  <span>Type your email</span>
                  <div className="my-3">
                    <input
                      type="email"
                      value={email || ""}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={loading}
                      className="w-56 px-2 py-1 outline-none text-primary"
                      required
                    />
                    <button className="btn-grad-blue" onClick={openModal}>
                      {loading ? (
                        <ProgressSpinner className="my-spinner h-4" />
                      ) : (
                        "Request Trial"
                      )}
                    </button>
                  </div>
                  {/* <div className="flex flex-start">
                  <input type="checkbox" />
                  <span className="text-xs ml-3">
                    You accept the terms of service and the privacy policy.
                  </span>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Dialog
          header="Please enter the characters shown below:"
          visible={isModalOpen}
          style={{ width: "500px" }}
          onHide={closeModal}
          resizable={false}
          draggable={false}
          dismissableMask
        >
          <div className="text-center">
            <div className="p-field my-1 flex justify-center items-center">
              {captchaText && (
                <div className="flex justify-center items-center bg-[#003971] h-[40px] w-[100px] text-white rounded-sm">
                  {" "}
                  {captchaText}
                </div>
              )}
              &nbsp;
              <InputText
                type="text"
                className="border"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Enter CAPTCHA"
              />
            </div>
            <button className="btn-grad-blue" onClick={verifyCaptcha}>
              Verify
            </button>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default Overview;
